import React from 'react';
import { styled, keyframes } from '@mui/material/styles';
import { IconButton } from '@mui/material';

const appear = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const EyeContainer = styled('div')({
  width: '24px',
  height: '24px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const EyeSvg = styled('svg')<{ isOpen: boolean }>(({ theme }) => ({
  width: '100%',
  height: '100%',
  fill: theme.palette.primary.main,
}));

const EyeBackground = styled('path')(({ theme }) => ({
  fill: theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
}));

const EyeHighlight = styled('circle')(({ theme }) => ({
  fill: theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
}));

const Pupil = styled('circle')<{ isOpen: boolean }>(({ isOpen, theme }) => ({
  fill: theme.palette.primary.main,
  animation: isOpen ? `${appear} 0.3s ease forwards` : 'none',
  opacity: isOpen ? 1 : 0,
  transformOrigin: 'center',
}));

interface AnimatedEyeProps {
  isOpen: boolean;
  onClick: () => void;
}

const AnimatedEye: React.FC<AnimatedEyeProps> = ({ isOpen, onClick }) => {
  return (
    <IconButton 
      onClick={onClick}
      sx={{ 
        color: 'primary.main',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <EyeContainer>
        <EyeSvg
          isOpen={isOpen}
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Основной треугольник */}
          <path d="M50,10 L90,85 L10,85 Z" />

          {/* Закрашенный верхний угол */}
          <path 
            d="M50,10 
               L60,35 
               L40,35 
               Z" 
            fill="currentColor"
          />

          {/* Основная часть глаза */}
          <EyeBackground
            d="M30,45 
               C38,35 62,35 70,45
               C75,52 75,65 70,72
               C62,82 38,82 30,72
               C25,65 25,52 30,45
               Z" 
          />

          {/* Детали вокруг глаза */}
          <EyeBackground
            d="M25,55 
               C23,48 28,42 32,45
               L35,48
               C32,45 27,50 30,57
               Z" 
          />
          <EyeBackground
            d="M75,55 
               C77,48 72,42 68,45
               L65,48
               C68,45 73,50 70,57
               Z" 
          />

          {/* Зрачок */}
          <Pupil
            isOpen={isOpen}
            cx="50"
            cy="58"
            r="12"
          />

          {/* Блик в глазу */}
          <EyeHighlight
            cx="45"
            cy="52"
            r="4"
            style={{ opacity: isOpen ? 1 : 0 }}
          />
        </EyeSvg>
      </EyeContainer>
    </IconButton>
  );
};

export default AnimatedEye; 
import api from './api';

interface AccessRequestResponse {
  message: string;
  request: {
    id: string;
    status: string;
    createdAt: string;
  };
}

interface AccessStatusResponse {
  accessStatus: {
    hasAccess: boolean;
    requestStatus: string | null;
    teamId: string | null;
    createdAt: string;
    updatedAt: string;
  };
}

export const accessRequestService = {
  // Создание запроса на доступ
  createRequest: async (): Promise<AccessRequestResponse> => {
    const response = await api.post<AccessRequestResponse>('/access-request');
    return response.data;
  },

  // Получение статуса запроса
  getStatus: async (): Promise<AccessRequestResponse> => {
    const response = await api.get<AccessRequestResponse>('/access-request/status');
    return response.data;
  },

  // Проверка статуса доступа
  checkAccessStatus: async (): Promise<AccessStatusResponse> => {
    const response = await api.get<AccessStatusResponse>('/access-request/access-status');
    return response.data;
  }
}; 
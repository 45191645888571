import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useFilters } from '../../context/FiltersContext';
import callAnalyticsService, { 
  CallStatistics, 
  CallEvaluation,
  ChecklistConfig 
} from '../../../../../services/callAnalyticsService';
import ManagersBarChart from './Statistics/ManagersBarChart';
import ManagersRadarChart from './Statistics/ManagersRadarChart';
import ManagersTable from './Statistics/ManagersTable';
import ManagersTabSkeleton from './Statistics/ManagersTabSkeleton';

const ManagersTab: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState<CallStatistics[]>([]);
  const [evaluations, setEvaluations] = useState<CallEvaluation[]>([]);
  const [checklistConfig, setChecklistConfig] = useState<ChecklistConfig | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { startDate, endDate, managers, departments } = useFilters();
  const [selectedManagers, setSelectedManagers] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!startDate || !endDate) return;

      try {
        setLoading(true);
        setError(null);

        const [statsData, evalsData, configData] = await Promise.all([
          callAnalyticsService.getCallStatistics(
            'day',
            startDate.format('YYYY-MM-DD'),
            endDate.format('YYYY-MM-DD')
          ),
          callAnalyticsService.getCallEvaluations(
            startDate.format('YYYY-MM-DD'),
            endDate.format('YYYY-MM-DD'),
            managers.includes('all') ? undefined : managers[0]
          ),
          callAnalyticsService.getChecklistConfig()
        ]);

        setStatistics(statsData);
        setEvaluations(evalsData);
        setChecklistConfig(configData);
      } catch (err) {
        console.error('Error fetching managers data:', err);
        setError(err instanceof Error ? err.message : 'Произошла ошибка при загрузке данных');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, managers, departments]);

  if (loading) {
    return <ManagersTabSkeleton />;
  }

  if (error) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '300px',
        color: 'error.main'
      }}>
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography 
          variant="h5" 
          sx={{ 
            mb: 1,
            fontWeight: 600,
            color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
          }}
        >
          Статистика по менеджерам
        </Typography>
      </Grid>

      {/* График с горизонтальными барами */}
      <Grid item xs={12} md={6}>
        <ManagersBarChart 
          evaluations={evaluations?.length ? evaluations : undefined} 
        />
      </Grid>

      {/* Радарная диаграмма */}
      <Grid item xs={12} md={6}>
        <ManagersRadarChart 
          evaluations={evaluations?.length ? evaluations : undefined}
          checklistConfig={checklistConfig}
          selectedManagers={selectedManagers}
          onManagersChange={setSelectedManagers}
        />
      </Grid>

      {/* Таблица с детальной статистикой */}
      <Grid item xs={12}>
        <ManagersTable 
          evaluations={evaluations?.length ? evaluations : undefined}
          checklistConfig={checklistConfig}
        />
      </Grid>
    </Grid>
  );
};

export default ManagersTab; 
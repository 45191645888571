import React, { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { StatBox } from '../../styled';
import { CallStatistics, ChecklistConfig, CallEvaluation } from '../../../../../../services/callAnalyticsService';
import { useTranslation } from 'react-i18next';

export interface ChecklistAverageProps {
  statistics?: CallEvaluation[];
  checklistConfig?: ChecklistConfig | null;
}

interface StatisticsWithScores {
  scores: {
    [key: string]: number;
  };
}

interface ChecklistItem {
  id: string;
  name: string;
}

interface ChecklistBlock {
  id: string;
  name: string;
  items: Array<ChecklistItem & { order: number; weight: number }>;
  order: number;
}

interface ChecklistItemWithBlock {
  id: string;
  blockId: string;
  name: string;
}

interface ChecklistItemWithScore extends ChecklistItemWithBlock {
  score: number;
}

const ChecklistAverage: React.FC<ChecklistAverageProps> = ({ statistics, checklistConfig }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const checklistItems = useMemo(() => {
    if (!statistics?.length || !checklistConfig?.blocks) return [];

    // Собираем статистику по каждому пункту
    const itemStats: Record<string, { sum: number; count: number }> = {};
    
    // Проходим по всем оценкам и собираем суммы и количество
    statistics.forEach(evaluation => {
      const evalWithScores = evaluation as unknown as StatisticsWithScores;
      if (!evalWithScores.scores) return;
      
      Object.entries(evalWithScores.scores).forEach(([itemId, score]) => {
        // Преобразуем формат ID из "1_01" в "1.01"
        const normalizedId = itemId.replace('_', '.');
        
        if (!itemStats[normalizedId]) {
          itemStats[normalizedId] = { sum: 0, count: 0 };
        }
        itemStats[normalizedId].sum += score;
        itemStats[normalizedId].count += 1;
      });
    });

    // Получаем массив блоков из конфигурации
    const blocks = ((checklistConfig.blocks as any).blocks || []) as ChecklistBlock[];

    // Собираем все пункты из блоков
    const items = blocks.reduce<Array<ChecklistItemWithBlock>>((acc, block) => {
      if (!block.items) return acc;

      const blockItems = block.items.map(item => ({
        id: item.id,
        blockId: block.id,
        name: item.name,
      }));

      return [...acc, ...blockItems];
    }, []);

    // Преобразуем в массив и вычисляем средние значения
    return items.map(item => {
      const stats = itemStats[item.id] || { sum: 0, count: 0 };
      return {
        id: item.id,
        blockId: item.blockId,
        name: item.name,
        score: stats.count > 0 ? Math.round((stats.sum / stats.count) * 100) : 0
      };
    }).sort((a: ChecklistItemWithScore, b: ChecklistItemWithScore) => {
      const blockCompare = a.blockId.localeCompare(b.blockId);
      if (blockCompare !== 0) return blockCompare;
      return a.id.localeCompare(b.id);
    });
  }, [statistics, checklistConfig]);

  // Функция для определения цвета по ID блока
  const getColorById = (blockId: string, mode: 'light' | 'dark' = 'light') => {
    if (mode === 'dark') {
      switch (blockId) {
        case '01': return '#DCFB01'; // фирменный неоново-желтый
        case '02': return '#36F1CD'; // бирюзовый неон
        case '03': return '#3DD5FF'; // электрический голубой
        case '04': return '#A2FC7C'; // весенний зеленый
        case '05': return '#FFD53D'; // золотистый
        case '06': return '#96F7B3'; // мятный
        case '07': return '#59FFC1'; // аквамариновый
        case '08': return '#00E2B0'; // изумрудный неон
        default: return '#DCFB01';
      }
    } else {
      switch (blockId) {
        case '01': return '#793EEE'; // фирменный фиолетовый
        case '02': return '#2A6B9F'; // глубокий синий
        case '03': return '#00A7B5'; // морской волны
        case '04': return '#348553'; // малахитовый
        case '05': return '#0B877D'; // изумрудный
        case '06': return '#B86E00'; // янтарный
        case '07': return '#236C70'; // бирюзовый
        case '08': return '#1E754C'; // нефритовый
        default: return '#793EEE';
      }
    }
  };

  return (
    <StatBox sx={{ height: '100%', minHeight: '240px' }}>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        {t('callAnalytics.checklistAverage.title')}
      </Typography>
      
      {checklistItems.length > 0 ? (
        <Box sx={{ 
          flex: 1,
          minHeight: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 2,
          overflowY: 'auto'
        }}>
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}>
            {checklistItems.map((item: ChecklistItemWithScore) => (
              <Box
                key={item.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography
                    sx={{
                      color: getColorById(item.blockId, theme.palette.mode),
                      fontWeight: 600,
                      minWidth: '45px'
                    }}
                  >
                    {item.id}
                  </Typography>
                  <Typography color="text.primary">
                    {item.name}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box
                    sx={{
                      width: 160,
                      height: 8,
                      bgcolor: theme => theme.palette.mode === 'dark' 
                        ? 'rgba(255, 255, 255, 0.1)' 
                        : 'rgba(0, 0, 0, 0.1)',
                      borderRadius: 4,
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        width: `${item.score}%`,
                        height: '100%',
                        bgcolor: getColorById(item.blockId, theme.palette.mode),
                        borderRadius: 4,
                        transition: 'width 0.3s ease',
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      minWidth: 45,
                      textAlign: 'right',
                      fontWeight: 600,
                    }}
                  >
                    {item.score}%
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box sx={{ 
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Typography color="text.secondary">
            {t('callAnalytics.checklistAverage.noData')}
          </Typography>
        </Box>
      )}
    </StatBox>
  );
};

export default ChecklistAverage;
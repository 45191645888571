import React from 'react';
import {
  AppBar,
  Box,
  IconButton,
  Typography,
  Toolbar,
  Avatar,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Brightness4,
  Brightness7,
  NotificationsOutlined,
  Settings,
  ExitToApp,
  Language,
  Person,
} from '@mui/icons-material';
import { useTheme } from '../../context/ThemeContext';
import { useAuth } from '../../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomTypeOptions } from 'i18next';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.mode === 'dark' 
    ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.7) 0%, rgba(18, 18, 18, 0.7) 100%)'
    : 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(20px)',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 12px rgba(0, 0, 0, 0.1)'
    : '0 4px 24px rgba(0, 0, 0, 0.05)',
  border: 'none',
  borderRadius: '24px',
  margin: '8px 16px 0 auto',
  width: '30%',
  minWidth: '420px',
  maxWidth: '600px',
  position: 'sticky',
  top: '8px',
  zIndex: theme.zIndex.drawer + 1,
  transition: 'transform 0.3s ease, top 0.3s ease',
  '& .MuiToolbar-root': {
    border: 'none',
    borderBottom: 'none',
    backgroundColor: 'transparent',
    borderRadius: 'inherit',
    minHeight: '56px',
    padding: '0 16px',
  }
}));

const Header: React.FC = () => {
  const { themeMode, toggleTheme } = useTheme();
  const { logout, user } = useAuth();
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [langAnchorEl, setLangAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLangMenu = (event: React.MouseEvent<HTMLElement>) => {
    setLangAnchorEl(event.currentTarget);
  };

  const handleLangClose = () => {
    setLangAnchorEl(null);
  };

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    handleLangClose();
  };

  const getHeaderTitle = () => {
    const path = location.pathname;
    
    if (path === '/products/0a4fd722-9ff1-4e27-8e1a-122bb09e258b') {
      return 'AI Calls Analytic';
    }
    
    const basePath = path.split('/')[1] || 'dashboard';
    return t(`header.${basePath as keyof CustomTypeOptions['resources']['translation']['header']}`, {
      defaultValue: t('header.dashboard')
    });
  };

  return (
    <StyledAppBar elevation={0}>
      <Toolbar>
        <Typography
          variant="h6"
          sx={{
            flexGrow: 1,
            fontWeight: 700,
            color: 'text.primary',
          }}
        >
          {getHeaderTitle()}
        </Typography>

        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 2,
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1 
          }}>
            <IconButton
              color="primary"
              onClick={toggleTheme}
              size="small"
            >
              {themeMode === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </IconButton>

            <IconButton
              color="primary"
              size="small"
              onClick={handleLangMenu}
            >
              <Language />
            </IconButton>

            <IconButton
              color="primary"
              size="small"
              disabled
              sx={{
                '&.Mui-disabled': {
                  '& .MuiSvgIcon-root': {
                    color: theme => theme.palette.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.3)'
                      : 'rgba(0, 0, 0, 0.3)',
                  },
                },
              }}
            >
              <NotificationsOutlined />
            </IconButton>
          </Box>

          <Menu
            anchorEl={langAnchorEl}
            open={Boolean(langAnchorEl)}
            onClose={handleLangClose}
            disableScrollLock={true}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                mt: 1.5,
                background: theme => theme.palette.mode === 'dark'
                  ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.85) 0%, rgba(18, 18, 18, 0.85) 100%)'
                  : 'rgba(255, 255, 255, 0.85)',
                backdropFilter: 'blur(20px)',
                border: 'none',
                borderRadius: '12px',
                minWidth: '120px',
                boxShadow: theme => theme.palette.mode === 'dark'
                  ? '0 4px 24px rgba(0, 0, 0, 0.4)'
                  : '0 4px 24px rgba(0, 0, 0, 0.05)',
                '& .MuiList-root': {
                  padding: '4px 0',
                },
                '& .MuiMenuItem-root': {
                  px: 2,
                  py: 0.75,
                  mx: 1,
                  my: 0.25,
                  fontSize: '0.875rem',
                  borderRadius: '8px',
                  color: 'text.primary',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    transform: 'translateX(4px)',
                    '& .MuiSvgIcon-root': {
                      transform: 'scale(1.1)',
                    },
                  },
                  '& .MuiSvgIcon-root': {
                    color: theme => theme.palette.primary.main,
                    transition: 'all 0.3s ease',
                    marginRight: 2,
                    fontSize: '20px',
                  },
                },
              },
            }}
            sx={{
              '& .MuiBackdrop-root': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <MenuItem onClick={() => handleLanguageChange('en')}>
              English
            </MenuItem>
            <MenuItem onClick={() => handleLanguageChange('ru')}>
              Русский
            </MenuItem>
          </Menu>

          <IconButton 
            onClick={handleMenu}
            size="small"
          >
            <Avatar
              sx={{
                width: 28,
                height: 28,
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                fontSize: '0.875rem'
              }}
            >
              {user?.firstName?.[0] || 'U'}
            </Avatar>
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            disableScrollLock={true}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                mt: 1.5,
                background: theme => theme.palette.mode === 'dark'
                  ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.85) 0%, rgba(18, 18, 18, 0.85) 100%)'
                  : 'rgba(255, 255, 255, 0.85)',
                backdropFilter: 'blur(20px)',
                border: 'none',
                borderRadius: '12px',
                minWidth: '180px',
                boxShadow: theme => theme.palette.mode === 'dark'
                  ? '0 4px 24px rgba(0, 0, 0, 0.4)'
                  : '0 4px 24px rgba(0, 0, 0, 0.05)',
                '& .MuiList-root': {
                  padding: '8px 0',
                },
                '& .MuiMenuItem-root': {
                  px: 2,
                  py: 1,
                  mx: 1,
                  my: 0.5,
                  fontSize: '0.875rem',
                  borderRadius: '8px',
                  color: 'text.primary',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    transform: 'translateX(4px)',
                    '& .MuiSvgIcon-root': {
                      transform: 'scale(1.1)',
                    },
                  },
                  '& .MuiSvgIcon-root': {
                    color: theme => theme.palette.primary.main,
                    transition: 'all 0.3s ease',
                    marginRight: 2,
                    fontSize: '20px',
                  },
                },
              },
            }}
            sx={{
              '& .MuiBackdrop-root': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <MenuItem onClick={() => {
              navigate('/profile');
              handleClose();
            }}>
              <Person sx={{ mr: 1 }} /> {t('header.profile')}
            </MenuItem>
            <MenuItem onClick={() => {
              navigate('/settings');
              handleClose();
            }}>
              <Settings sx={{ mr: 1 }} /> {t('header.settings')}
            </MenuItem>
            <MenuItem onClick={logout}>
              <ExitToApp sx={{ mr: 1 }} /> {t('header.logout')}
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header; 
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Typography, 
  useTheme, 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  useMediaQuery,
  TextField,
  InputAdornment,
  Grid
} from '@mui/material';
import { StatBox } from '../../../components/styled';
import { CallEvaluation } from '../../../../../../services/callAnalyticsService';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { styled, Theme } from '@mui/material/styles';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { useFilters } from '../../../context/FiltersContext';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

// Стилизованный Dialog как в Profile
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(4),
    background: theme.palette.mode === 'dark'
      ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)'
      : 'rgba(255, 255, 255, 0.85)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '24px',
    position: 'relative',
    zIndex: 1,
    boxShadow: theme.palette.mode === 'dark'
      ? '0 4px 24px rgba(0, 0, 0, 0.4)'
      : '0 4px 24px rgba(0, 0, 0, 0.05)',
    '& .MuiDialogTitle-root': {
      padding: 0,
      paddingBottom: theme.spacing(3),
      color: theme.palette.text.primary,
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      paddingTop: theme.spacing(2),
    },
    transition: 'box-shadow 0.3s ease',
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(10px)',
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(0, 0, 0, 0.8)'
      : 'rgba(255, 255, 255, 0.8)',
  },
}));

// Стили для поля поиска
const SearchTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.03)' 
      : 'rgba(0, 0, 0, 0.02)',
    '& input': {
      padding: '12px 16px',
    },
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.1)',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.2)'
        : 'rgba(0, 0, 0, 0.2)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

// Добавляем стили для скроллбара
const scrollbarStyles = (theme: Theme) => ({
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    '&:hover': {
      background: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.2)' 
        : 'rgba(0, 0, 0, 0.2)',
    },
  },
  '&::-webkit-scrollbar-corner': {
    background: 'transparent',
  },
});

// Обновляем стили для контента модального окна
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  height: '60vh',
  maxHeight: '600px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2, 0),
  '& .search-field': {
    padding: theme.spacing(0, 2),
  },
  '& .manager-list': {
    flex: 1,
    overflow: 'auto',
    paddingRight: theme.spacing(2),
    marginRight: -theme.spacing(2),
    paddingLeft: theme.spacing(2),
    ...scrollbarStyles(theme),
  },
  '& .empty-message': {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  }
}));

// Обновляем стили для бейджа
const CallsBadge = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  fontSize: '0.75rem',
  color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(220, 251, 1, 0.1)' 
    : 'rgba(121, 62, 238, 0.1)',
  padding: theme.spacing(0.25, 0.75),
  borderRadius: theme.spacing(3),
  minWidth: 'fit-content',
  height: '20px',
  '& .MuiSvgIcon-root': {
    fontSize: '14px',
  },
  '& .count': {
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: 1,
  },
  '& .text': {
    fontSize: '0.75rem',
    opacity: 0.7,
    marginLeft: '2px',
  },
  whiteSpace: 'nowrap',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(220, 251, 1, 0.15)' 
      : 'rgba(121, 62, 238, 0.15)',
    transform: 'translateY(-1px)',
  }
}));

// Обновляем компонент для статистического блока
const StatBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.03)' 
    : 'rgba(0, 0, 0, 0.02)',
  borderRadius: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  '& .icon': {
    color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
    fontSize: '28px',
  },
  '& .value': {
    fontSize: '1.5rem',
    fontWeight: 600,
    color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
  },
  '& .label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  }
}));

// Добавим компонент для отображения изменения
const ChangeIndicator = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  fontSize: '0.75rem',
  fontWeight: 500,
  '& .MuiSvgIcon-root': {
    fontSize: '1rem'
  }
}));

// Обновим стили для кнопки закрытия
const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: theme.spacing(1),
  color: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.7)' 
    : 'rgba(0, 0, 0, 0.7)',
  '&:hover': {
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    backgroundColor: 'transparent',
  },
}));

// Обновим стилизованную кнопку
const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: theme.spacing(1, 2),
  textTransform: 'none',
  fontWeight: 500,
  color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
  border: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    transform: 'translateY(-1px)',
  },
  transition: 'all 0.2s ease',
}));

// Добавим стилизованный компонент для номера
const ManagerNumber = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
  fontWeight: 600,
  minWidth: '30px',
  textAlign: 'center'
}));

interface Props {
  evaluations?: CallEvaluation[];
}

interface ManagerScore {
  id: string;
  score: number;
  callsCount: number;
}

const INITIAL_VISIBLE_COUNT = 5;

const ManagersBarChart: React.FC<Props> = ({ evaluations }) => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [visibleCount, setVisibleCount] = useState(INITIAL_VISIBLE_COUNT);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const itemHeight = 56; // Примерная высота одного элемента списка
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { period } = useFilters();

  const managerScores = useMemo(() => {
    if (!evaluations?.length) return [];

    // Группируем оценки по менеджерам и блокам
    const scoresByManager = evaluations.reduce((acc, evaluation) => {
      const managerId = evaluation.manager_id;
      if (!acc[managerId]) {
        acc[managerId] = {
          blocks: {},
          callsCount: 0
        };
      }

      // Увеличиваем счетчик звонков
      acc[managerId].callsCount += 1;

      // Группируем оценки по блокам
      Object.entries(evaluation.scores || {}).forEach(([itemId, score]) => {
        const blockId = itemId.split('_')[0];
        if (!acc[managerId].blocks[blockId]) {
          acc[managerId].blocks[blockId] = {
            sum: 0,
            count: 0
          };
        }
        acc[managerId].blocks[blockId].sum += score;
        acc[managerId].blocks[blockId].count += 1;
      });

      return acc;
    }, {} as Record<string, { 
      blocks: Record<string, { sum: number; count: number }>,
      callsCount: number 
    }>);

    // Вычисляем средние значения по менеджерам
    return Object.entries(scoresByManager)
      .map(([id, data]): ManagerScore => {
        // Считаем среднее по всем блокам
        const blockScores = Object.values(data.blocks).map(
          block => block.count > 0 ? (block.sum / block.count) * 100 : 0
        );
        
        return {
          id,
          score: Math.round(
            blockScores.reduce((sum, score) => sum + score, 0) / 
            (blockScores.length || 1)
          ),
          callsCount: data.callsCount
        };
      })
      .sort((a, b) => b.score - a.score);
  }, [evaluations]);

  const filteredManagers = useMemo(() => {
    if (!searchQuery) return managerScores;
    
    return managerScores.filter(manager => 
      manager.id.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [managerScores, searchQuery]);

  // Функция для расчета количества видимых элементов
  const calculateVisibleCount = () => {
    if (!listContainerRef.current) return;

    const containerHeight = listContainerRef.current.clientHeight;
    const possibleItems = Math.floor(containerHeight / itemHeight);
    // Устанавливаем минимум 5 элементов
    const newVisibleCount = Math.max(5, possibleItems);
    
    // Ограничиваем максимальным количеством доступных менеджеров
    if (managerScores.length < newVisibleCount) {
      setVisibleCount(managerScores.length);
    } else {
      setVisibleCount(newVisibleCount);
    }
  };

  // Наблюдаем за изменением размера контейнера
  useEffect(() => {
    if (!listContainerRef.current) return;

    const resizeObserver = new ResizeObserver(calculateVisibleCount);
    resizeObserver.observe(listContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // Пересчитываем при изменении данных
  useEffect(() => {
    calculateVisibleCount();
  }, [managerScores]);

  // Функция для форматирования числа звонков
  const formatCallsCount = (count: number): string => {
    if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}k`;
    }
    return count.toString();
  };

  // Функция для склонения слова "звонок"
  const getCallsText = (count: number): string => {
    const lastDigit = count % 10;
    const lastTwoDigits = count % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return 'звонков';
    }

    if (lastDigit === 1) {
      return 'звонок';
    }
    if (lastDigit >= 2 && lastDigit <= 4) {
      return 'звонка';
    }
    return 'звонков';
  };

  const getPeriodText = (days: number): string => {
    if (days <= 7) return 'прошлую неделю';
    if (days <= 31) return 'прошлый месяц';
    if (days <= 93) return 'прошлый квартал';
    if (days <= 366) return 'прошлый год';
    return 'прошлый период';
  };

  const { totalAverage, totalCalls, averageCallsPerManager, previousPeriodChange, periodType } = useMemo(() => {
    if (!evaluations?.length) return { 
      totalAverage: 0, 
      totalCalls: 0,
      averageCallsPerManager: 0,
      previousPeriodChange: 0,
      periodType: ''
    };

    // Сортируем оценки по дате
    const sortedEvaluations = [...evaluations].sort((a, b) => 
      new Date(a.evaluated_at).getTime() - new Date(b.evaluated_at).getTime()
    );

    const firstDate = new Date(sortedEvaluations[0].evaluated_at);
    const lastDate = new Date(sortedEvaluations[sortedEvaluations.length - 1].evaluated_at);
    const totalDays = Math.ceil((lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24));

    // Группируем данные по дням для более точного расчета
    const dailyData = sortedEvaluations.reduce((acc, evaluation) => {
      const date = evaluation.evaluated_at.split('T')[0];
      if (!acc[date]) {
        acc[date] = {
          scores: {},
          count: 0
        };
      }

      // Собираем все оценки за день
      Object.entries(evaluation.scores || {}).forEach(([itemId, score]) => {
        const blockId = itemId.split('_')[0];
        if (!acc[date].scores[blockId]) {
          acc[date].scores[blockId] = [];
        }
        acc[date].scores[blockId].push(score);
      });
      acc[date].count++;

      return acc;
    }, {} as Record<string, { scores: Record<string, number[]>, count: number }>);

    // Вычисляем средние значения по дням
    const dailyAverages = Object.entries(dailyData).map(([date, data]) => {
      const blockAverages = Object.values(data.scores).map(scores => 
        scores.reduce((sum, score) => sum + score, 0) / scores.length
      );
      return {
        date: new Date(date),
        average: blockAverages.length 
          ? Math.round(blockAverages.reduce((sum, avg) => sum + avg, 0) / blockAverages.length * 100)
          : 0
      };
    }).sort((a, b) => a.date.getTime() - b.date.getTime());

    // Разделяем на два периода
    const midPoint = Math.floor(dailyAverages.length / 2);
    const currentPeriod = dailyAverages.slice(midPoint);
    const previousPeriod = dailyAverages.slice(0, midPoint);

    // Считаем средние значения для периодов
    const calculatePeriodAverage = (data: typeof dailyAverages) => {
      if (!data.length) return 0;
      return Math.round(
        data.reduce((sum, day) => sum + day.average, 0) / data.length
      );
    };

    const currentAverage = calculatePeriodAverage(currentPeriod);
    const previousAverage = calculatePeriodAverage(previousPeriod);

    // Считаем общую статистику
    const total = managerScores.reduce((acc, manager) => ({
      score: acc.score + manager.score,
      calls: acc.calls + manager.callsCount
    }), { score: 0, calls: 0 });

    // Определяем тип периода
    const periodText = period === 'custom' 
      ? 'прошлый период'
      : getPeriodText(totalDays);

    return {
      totalAverage: Math.round(total.score / managerScores.length),
      totalCalls: total.calls,
      averageCallsPerManager: Math.round(total.calls / managerScores.length),
      previousPeriodChange: previousAverage 
        ? Math.round(((currentAverage - previousAverage) / previousAverage) * 100)
        : 0,
      periodType: periodText
    };
  }, [evaluations, managerScores, period]);

  return (
    <>
      <StatBox sx={{ p: 3, height: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StatBlock>
                <AssessmentIcon className="icon" />
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                    <Typography className="value">{totalAverage}%</Typography>
                    <ChangeIndicator
                      sx={{ 
                        color: previousPeriodChange > 0 
                          ? 'success.main'
                          : previousPeriodChange < 0 
                            ? 'error.main'
                            : 'text.secondary',
                        mt: 0.5
                      }}
                    >
                      {previousPeriodChange !== 0 ? (
                        <>
                          {previousPeriodChange > 0 ? (
                            <TrendingUpIcon />
                          ) : (
                            <TrendingDownIcon />
                          )}
                          {previousPeriodChange > 0 ? '+' : ''}{Math.abs(previousPeriodChange)}% vs {periodType}
                        </>
                      ) : (
                        'без изменений'
                      )}
                    </ChangeIndicator>
                  </Box>
                  <Typography className="label">Среднее</Typography>
                </Box>
              </StatBlock>
            </Grid>
            <Grid item xs={6}>
              <StatBlock>
                <PhoneInTalkIcon className="icon" />
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                    <Typography className="value">{totalCalls}</Typography>
                    <ChangeIndicator
                      sx={{ 
                        color: 'text.secondary',
                        mt: 0.5
                      }}
                    >
                      ~{averageCallsPerManager} на менеджера
                    </ChangeIndicator>
                  </Box>
                  <Typography className="label">Звонков</Typography>
                </Box>
              </StatBlock>
            </Grid>
          </Grid>

          {/* Список менеджеров */}
          <Box sx={{ 
            flex: 1, 
            display: 'flex',
            flexDirection: 'column',
            minHeight: 0 // Важно для правильной работы flex
          }}>
            <Typography 
              variant="subtitle2" 
              color="text.secondary" 
              sx={{ mb: 2 }}
            >
              Топ {visibleCount} менеджеров
            </Typography>
            <Box 
              ref={listContainerRef}
              sx={{ 
                flex: 1,
                display: 'flex', 
                flexDirection: 'column', 
                gap: 2,
                overflow: 'hidden',
                minHeight: 0 // Важно для правильной работы flex
              }}
            >
              {managerScores.slice(0, visibleCount).map((manager, index) => (
                <Box key={manager.id}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2,
                    mb: 1
                  }}>
                    <Typography
                      sx={{
                        color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                        fontWeight: 600,
                        minWidth: '30px',
                        textAlign: 'center'
                      }}
                    >
                      #{index + 1}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{
                        minWidth: '300px',
                        maxWidth: '400px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {manager.id}
                    </Typography>
                    <Box sx={{ flex: 1 }}>
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        mb: 0.5 
                      }}>
                        <Typography color="text.secondary">
                          {manager.callsCount} {getCallsText(manager.callsCount)}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            minWidth: '60px',
                            textAlign: 'right'
                          }}
                        >
                          {manager.score}%
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: '100%',
                          height: 8,
                          bgcolor: theme => theme.palette.mode === 'dark' 
                            ? 'rgba(255, 255, 255, 0.1)' 
                            : 'rgba(0, 0, 0, 0.1)',
                          borderRadius: 4,
                          overflow: 'hidden',
                        }}
                      >
                        <Box
                          sx={{
                            width: `${manager.score}%`,
                            height: '100%',
                            bgcolor: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                            borderRadius: 4,
                            transition: 'width 0.3s ease',
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            
            {managerScores.length > visibleCount && (
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <StyledButton
                  onClick={() => setIsModalOpen(true)}
                  startIcon={<PeopleAltIcon />}
                  disableRipple
                >
                  Показать всех ({managerScores.length})
                </StyledButton>
              </Box>
            )}
          </Box>
        </Box>
      </StatBox>

      <StyledDialog
        fullScreen={fullScreen}
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSearchQuery('');
        }}
        maxWidth="md"
        fullWidth
        disableScrollLock
        PaperProps={{
          sx: {
            margin: '16px',
            width: 'calc(100% - 32px)',
            maxWidth: '900px',
          }
        }}
      >
        <CloseButton
          onClick={() => {
            setIsModalOpen(false);
            setSearchQuery('');
          }}
        >
          <CloseIcon />
        </CloseButton>
        <DialogTitle sx={{ 
          m: 0, 
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          {/* Блок с общей статистикой */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StatBlock>
                <AssessmentIcon className="icon" />
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                    <Typography className="value">{totalAverage}%</Typography>
                    <ChangeIndicator
                      sx={{ 
                        color: previousPeriodChange > 0 
                          ? 'success.main'
                          : previousPeriodChange < 0 
                            ? 'error.main'
                            : 'text.secondary',
                        mt: 0.5
                      }}
                    >
                      {previousPeriodChange !== 0 ? (
                        <>
                          {previousPeriodChange > 0 ? (
                            <TrendingUpIcon />
                          ) : (
                            <TrendingDownIcon />
                          )}
                          {previousPeriodChange > 0 ? '+' : ''}{Math.abs(previousPeriodChange)}% vs {periodType}
                        </>
                      ) : (
                        'без изменений'
                      )}
                    </ChangeIndicator>
                  </Box>
                  <Typography className="label">Среднее</Typography>
                </Box>
              </StatBlock>
            </Grid>
            <Grid item xs={6}>
              <StatBlock>
                <PhoneInTalkIcon className="icon" />
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                    <Typography className="value">{totalCalls}</Typography>
                    <ChangeIndicator
                      sx={{ 
                        color: 'text.secondary',
                        mt: 0.5
                      }}
                    >
                      ~{averageCallsPerManager} на менеджера
                    </ChangeIndicator>
                  </Box>
                  <Typography className="label">Звонков</Typography>
                </Box>
              </StatBlock>
            </Grid>
          </Grid>

          {/* Заголовок и поиск */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2
          }}>
            <Typography variant="h6" sx={{ flex: 1 }}>
              Все менеджеры ({filteredManagers.length})
            </Typography>
          </Box>
          <SearchTextField
            fullWidth
            placeholder="Поиск менеджера..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'text.secondary' }} />
                </InputAdornment>
              ),
            }}
          />
        </DialogTitle>
        <StyledDialogContent>
          {filteredManagers.length === 0 ? (
            <Box className="empty-message">
              <Typography color="text.secondary">
                Менеджеры не найдены
              </Typography>
            </Box>
          ) : (
            <Box className="manager-list">
              {/* Добавляем заголовки столбцов */}
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 2,
                  mb: 2,
                  px: 1
                }}
              >
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '0.75rem',
                    minWidth: '30px',
                    textAlign: 'center'
                  }}
                >
                  №
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  flex: 1,
                  minWidth: 0
                }}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: '0.75rem',
                      flex: 1,
                      minWidth: 0
                    }}
                  >
                    ID менеджера
                  </Typography>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    minWidth: '200px',
                    justifyContent: 'flex-end'
                  }}>
                    <Typography
                      sx={{
                        color: 'text.secondary',
                        fontSize: '0.75rem',
                        flex: 1,
                        textAlign: 'right'
                      }}
                    >
                      Звонков
                    </Typography>
                    <Typography
                      sx={{
                        color: 'text.secondary',
                        fontSize: '0.75rem',
                        minWidth: '60px',
                        textAlign: 'right'
                      }}
                    >
                      Средняя
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {filteredManagers.map((manager, index) => (
                  <Box key={manager.id}>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 2,
                      mb: 1
                    }}>
                      <Box sx={{ minWidth: '30px', textAlign: 'center' }}>
                        {!searchQuery && (
                          <ManagerNumber>
                            #{index + 1}
                          </ManagerNumber>
                        )}
                      </Box>
                      <Typography
                        color="text.secondary"
                        sx={{
                          minWidth: '300px',
                          maxWidth: '400px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {manager.id}
                      </Typography>
                      <Box sx={{ flex: 1 }}>
                        <Box sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between',
                          mb: 0.5 
                        }}>
                          <Typography color="text.secondary">
                            {manager.callsCount} {getCallsText(manager.callsCount)}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              minWidth: '60px',
                              textAlign: 'right'
                            }}
                          >
                            {manager.score}%
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: '100%',
                            height: 8,
                            bgcolor: theme => theme.palette.mode === 'dark' 
                              ? 'rgba(255, 255, 255, 0.1)' 
                              : 'rgba(0, 0, 0, 0.1)',
                            borderRadius: 4,
                            overflow: 'hidden',
                          }}
                        >
                          <Box
                            sx={{
                              width: `${manager.score}%`,
                              height: '100%',
                              bgcolor: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                              borderRadius: 4,
                              transition: 'width 0.3s ease',
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </StyledDialogContent>
      </StyledDialog>
    </>
  );
};

export default ManagersBarChart; 
import React, { createContext, useContext, useState, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';

interface FiltersContextType {
  period: string;
  setPeriod: (period: string) => void;
  startDate: Dayjs | null;
  setStartDate: (date: Dayjs | null) => void;
  endDate: Dayjs | null;
  setEndDate: (date: Dayjs | null) => void;
  managers: string[];
  setManagers: (managers: string[]) => void;
  departments: string[];
  setDepartments: (departments: string[]) => void;
  updateDatesByPeriod: (newPeriod: string) => void;
}

const FiltersContext = createContext<FiltersContextType | undefined>(undefined);

export const FiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [period, setPeriod] = useState('month');
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs().endOf('month'));
  const [managers, setManagers] = useState<string[]>([]);
  const [departments, setDepartments] = useState<string[]>([]);

  const updateDatesByPeriod = useCallback((newPeriod: string) => {
    const now = dayjs();
    switch (newPeriod) {
      case 'today':
        setStartDate(now.startOf('day'));
        setEndDate(now.endOf('day'));
        break;
      case 'week':
        setStartDate(now.subtract(6, 'day').startOf('day'));
        setEndDate(now.endOf('day'));
        break;
      case 'month':
        setStartDate(now.startOf('month'));
        setEndDate(now.endOf('month'));
        break;
      case 'quarter': {
        // Вычисляем начало и конец квартала вручную
        const currentMonth = now.month();
        const quarterStartMonth = Math.floor(currentMonth / 3) * 3;
        setStartDate(now.month(quarterStartMonth).startOf('month'));
        setEndDate(now.month(quarterStartMonth + 2).endOf('month'));
        break;
      }
      case 'year':
        setStartDate(now.startOf('year'));
        setEndDate(now.endOf('year'));
        break;
      default:
        // Для custom периода оставляем текущие даты
        break;
    }
    setPeriod(newPeriod);
  }, []);

  return (
    <FiltersContext.Provider
      value={{
        period,
        setPeriod,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        managers,
        setManagers,
        departments,
        setDepartments,
        updateDatesByPeriod,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => {
  const context = useContext(FiltersContext);
  if (context === undefined) {
    throw new Error('useFilters must be used within a FiltersProvider');
  }
  return context;
}; 
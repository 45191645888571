import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
  styled,
  Theme,
  Grid,
  Button,
  CircularProgress,
  Fade,
} from '@mui/material';
import { StatBox, StyledActionButton } from '../../../components/styled';
import { CallEvaluation, ChecklistConfig } from '../../../../../../services/callAnalyticsService';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';
import { useFilters } from '../../../context/FiltersContext';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Dialog as MuiDialog } from '@mui/material'; // переименуем импорт для избежания конфликтов
import PauseIcon from '@mui/icons-material/Pause';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface Props {
  evaluations?: CallEvaluation[];
  checklistConfig?: ChecklistConfig | null;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(2),
    background: theme.palette.mode === 'dark'
      ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)'
      : 'rgba(255, 255, 255, 0.85)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '24px',
    position: 'relative',
    zIndex: 1,
    boxShadow: theme.palette.mode === 'dark'
      ? '0 4px 24px rgba(0, 0, 0, 0.4)'
      : '0 4px 24px rgba(0, 0, 0, 0.05)',
    '& .MuiDialogTitle-root': {
      padding: 0,
      paddingBottom: theme.spacing(2),
      color: theme.palette.text.primary,
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      paddingTop: theme.spacing(2),
    },
    transition: 'box-shadow 0.3s ease',
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(10px)',
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(0, 0, 0, 0.8)'
      : 'rgba(255, 255, 255, 0.8)',
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  padding: theme.spacing(1),
  color: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.7)' 
    : 'rgba(0, 0, 0, 0.7)',
  '&:hover': {
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    backgroundColor: 'transparent',
  },
}));

const ScoreBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.03)' 
    : 'rgba(0, 0, 0, 0.02)',
  borderRadius: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  '& .block-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  '& .item-row': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0),
    borderBottom: `1px solid ${theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.1)'}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  '& .item-info': {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    flex: 1,
    minWidth: 0,
  },
  '& .item-id': {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    minWidth: '45px',
  },
  '& .item-name': {
    fontSize: '0.875rem',
    flex: 1,
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .item-status': {
    fontSize: '0.875rem',
    fontWeight: 500,
    minWidth: '100px',
    textAlign: 'right',
  },
}));

// Добавляем стили для скроллбара
const scrollbarStyles = (theme: Theme) => ({
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    '&:hover': {
      background: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.2)' 
        : 'rgba(0, 0, 0, 0.2)',
    },
  },
  '&::-webkit-scrollbar-corner': {
    background: 'transparent',
  },
});

// Обновляем стили для DialogContent
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  height: '75vh',
  maxHeight: '800px',
  overflow: 'auto',
  ...scrollbarStyles(theme),
  padding: theme.spacing(2, 0),
  marginRight: -theme.spacing(2),
  paddingRight: theme.spacing(2),
  '& .MuiGrid-container': {
    paddingRight: theme.spacing(2),
    width: 'auto',
    margin: 0,
  }
}));

// Добавим функцию для получения текста статуса
const getScoreStatusText = (score: number): string => {
  return score > 0 ? 'Выполнено' : 'Не выполнено';
};

// Добавим функцию для получения цвета статуса
const getScoreStatusColor = (score: number, theme: Theme): string => {
  if (score > 0) {
    return theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE';
  }
  return theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.5)' 
    : 'rgba(0, 0, 0, 0.5)';
};

// Добавим функцию форматирования даты и времени
const formatDateTime = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleString('ru-RU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
};

// Обновляем стилизованную кнопку для аудио
const AudioButton = styled('button')(({ theme }) => ({
  minWidth: 'auto',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: '16px',
  textTransform: 'none',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(220, 251, 1, 0.1)' 
    : 'rgba(121, 62, 238, 0.1)',
  color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
  border: 'none',
  textDecoration: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  fontSize: '0.875rem',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(220, 251, 1, 0.2)' 
      : 'rgba(121, 62, 238, 0.2)',
    textDecoration: 'none',
  },
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5),
    fontSize: '1.25rem',
  },
}));

// Добавим стилизованную кнопку для деталей
const CombinedButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: '16px',
  textTransform: 'none',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(220, 251, 1, 0.1)' 
    : 'rgba(121, 62, 238, 0.1)',
  color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
  border: 'none',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(220, 251, 1, 0.2)' 
      : 'rgba(121, 62, 238, 0.2)',
  },
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(0.5),
  },
  '& .MuiButton-endIcon': {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(-0.5),
  },
}));

// Добавим функцию для получения стилей ячейки
const getCellStyle = (isLastInBlock: boolean = false) => ({
  borderRight: (theme: Theme) => {
    if (isLastInBlock) {
      return `2px solid ${theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.2)' 
        : 'rgba(0, 0, 0, 0.2)'}`;
    }
    return 'none';
  },
  borderBottom: (theme: Theme) => `1px solid ${theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.1)' 
    : 'rgba(0, 0, 0, 0.1)'}`,
  '&:first-of-type': {
    borderLeft: (theme: Theme) => `1px solid ${theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.1)'}`,
  }
});

// Обновим стили для StyledTableContainer
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  '& .MuiTableCell-root': {
    padding: '8px 16px',
  },
  '& .MuiTableHead-root': {
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  '& .MuiTableRow-root:first-of-type .MuiTableCell-root': {
    borderTop: (theme: Theme) => `1px solid ${theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.1)'}`,
  },
  '& .MuiTableHead-root .MuiTableRow-root': {
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.03)'
      : 'rgba(0, 0, 0, 0.02)',
    '& .MuiTableCell-root': {
      borderBottom: `2px solid ${theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.2)'
        : 'rgba(0, 0, 0, 0.2)'}`,
      '&:first-of-type': {
        borderTopLeftRadius: '8px',
        borderLeft: `1px solid ${theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.1)'}`,
      },
      '&:last-of-type': {
        borderTopRightRadius: '8px',
        borderRight: `1px solid ${theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.1)'}`,
      },
    }
  },
  '& .MuiTableBody-root .MuiTableRow-root': {
    '& .MuiTableCell-root': {
      borderBottom: `1px solid ${theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.1)'}`,
      '&:first-of-type': {
        borderLeft: `1px solid ${theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.1)'}`,
      },
      '&:last-of-type': {
        borderRight: `1px solid ${theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.1)'}`,
      },
    },
    '&:last-child .MuiTableCell-root': {
      borderBottom: `1px solid ${theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.1)'}`,
      '&:first-of-type': {
        borderBottomLeftRadius: '8px',
      },
      '&:last-of-type': {
        borderBottomRightRadius: '8px',
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : 'rgba(0, 0, 0, 0.03)',
    }
  }
}));

// Добавим интерфейс для аудио плеера
interface AudioPlayerDialogProps {
  open: boolean;
  onClose: () => void;
  audioUrl: string;
  callInfo: {
    date: string;
    managerId: string;
    clientId: string;
    callId: string;
  };
}

// Создадим компонент аудио плеера
const AudioPlayerDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(2),
    background: theme.palette.mode === 'dark'
      ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)'
      : 'rgba(255, 255, 255, 0.85)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '24px',
    maxWidth: '500px',
    width: '100%',
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(10px)',
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(0, 0, 0, 0.8)'
      : 'rgba(255, 255, 255, 0.8)',
  },
}));

const AudioPlayer: React.FC<AudioPlayerDialogProps> = ({ open, onClose, audioUrl, callInfo }) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [error, setError] = React.useState(false);
  const audioRef = React.useRef<HTMLAudioElement>(null);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch(() => {
          setError(true);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  React.useEffect(() => {
    if (!open) {
      setIsPlaying(false);
      setError(false);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [open]);

  return (
    <AudioPlayerDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      disableScrollLock
    >
      <Box sx={{ position: 'relative', p: 2 }}>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Аудиозапись звонка
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography color="text.secondary" variant="body2">
                Дата
              </Typography>
              <Typography>{callInfo.date}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="text.secondary" variant="body2">
                ID Менеджера
              </Typography>
              <Typography>{callInfo.managerId}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="text.secondary" variant="body2">
                ID Клиента
              </Typography>
              <Typography>{callInfo.clientId}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="text.secondary" variant="body2">
                ID Звонка
              </Typography>
              <Typography>{callInfo.callId}</Typography>
            </Grid>
          </Grid>
        </Box>

        {error ? (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            py: 4,
            color: 'text.secondary'
          }}>
            <ErrorOutlineIcon sx={{ fontSize: 48 }} />
            <Typography>
              Аудиофайл недоступен
            </Typography>
          </Box>
        ) : (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2 
          }}>
            <IconButton
              onClick={togglePlay}
              sx={{
                width: 56,
                height: 56,
                backgroundColor: theme => theme.palette.mode === 'dark' 
                  ? 'rgba(220, 251, 1, 0.1)' 
                  : 'rgba(121, 62, 238, 0.1)',
                color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                '&:hover': {
                  backgroundColor: theme => theme.palette.mode === 'dark' 
                    ? 'rgba(220, 251, 1, 0.2)' 
                    : 'rgba(121, 62, 238, 0.2)',
                }
              }}
            >
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>

            <audio
              ref={audioRef}
              src={audioUrl}
              onEnded={() => setIsPlaying(false)}
              onError={() => setError(true)}
              style={{ width: '100%', marginTop: '16px' }}
              controls
            />
          </Box>
        )}
      </Box>
    </AudioPlayerDialog>
  );
};

// Обновим стиль для объединенной кнопки
const AudioPlayerBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.03)' 
    : 'rgba(0, 0, 0, 0.02)',
  borderRadius: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  minHeight: '160px',
  position: 'relative', // Добавляем для абсолютного позиционирования дочерних элементов
}));

const CallsTable: React.FC<Props> = ({ evaluations, checklistConfig }) => {
  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState('');
  const itemsPerPage = 20;
  const { period, startDate, endDate } = useFilters();
  const [selectedCall, setSelectedCall] = useState<CallEvaluation | null>(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [audioError, setAudioError] = React.useState(false);
  const audioRef = React.useRef<HTMLAudioElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getPeriodString = () => {
    if (!startDate || !endDate) return '';
    const formattedStartDate = startDate.format('DD.MM.YY');
    const formattedEndDate = endDate.format('DD.MM.YY');
    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const filteredAndPaginatedCalls = React.useMemo(() => {
    if (!evaluations?.length) return [];

    const filtered = searchQuery
      ? evaluations.filter(call => 
          call.manager_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
          call.client_id.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : evaluations;

    const startIndex = (page - 1) * itemsPerPage;
    return filtered.slice(startIndex, startIndex + itemsPerPage);
  }, [evaluations, page, searchQuery]);

  const totalPages = React.useMemo(() => {
    const filtered = searchQuery
      ? evaluations?.filter(call => 
          call.manager_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
          call.client_id.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : evaluations;
    return Math.ceil((filtered?.length || 0) / itemsPerPage);
  }, [evaluations, searchQuery]);

  const calculateTotalScore = (evaluation: CallEvaluation): number => {
    if (!evaluation.scores || !checklistConfig?.blocks) return 0;

    const configBlocks = ((checklistConfig.blocks as any).blocks || []) as {
      id: string;
      items: Array<{ id: string; weight: number }>;
    }[];

    let totalScore = 0;
    let totalWeight = 0;

    configBlocks.forEach(block => {
      block.items.forEach(item => {
        const scoreKey = item.id.replace('.', '_');
        const score = evaluation.scores?.[scoreKey];
        
        if (typeof score === 'number') {
          totalScore += score * item.weight;
          totalWeight += item.weight;
        }
      });
    });

    return totalWeight > 0 ? Math.round((totalScore / totalWeight) * 100) : 0;
  };

  const calculateBlockScore = (evaluation: CallEvaluation, blockId: string): number => {
    if (!evaluation.scores || !checklistConfig?.blocks) return 0;

    const block = ((checklistConfig.blocks as any).blocks || []).find(
      (b: any) => b.id === blockId
    );

    if (!block) return 0;

    let blockScore = 0;
    let totalWeight = 0;

    block.items.forEach((item: { id: string; weight: number }) => {
      const scoreKey = item.id.replace('.', '_');
      const score = evaluation.scores?.[scoreKey];
      
      if (typeof score === 'number') {
        blockScore += score * item.weight;
        totalWeight += item.weight;
      }
    });

    return totalWeight > 0 ? Math.round((blockScore / totalWeight) * 100) : 0;
  };

  const handleExportToExcel = () => {
    if (!evaluations?.length) return;

    const data = evaluations.map(call => ({
      'Дата': formatDateTime(call.evaluated_at),
      'ID Менеджера': call.manager_id,
      'ID Клиента': call.client_id,
      'ID Звонка': call.call_id,
      'Оценка': `${calculateTotalScore(call)}%`,
      'Аудио': call.audio_file_url
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Звонки');

    // Устанавливаем ширину колонок
    const colWidths = [
      { wch: 12 }, // Дата
      { wch: 15 }, // ID Менеджера
      { wch: 15 }, // ID Клиента
      { wch: 15 }, // ID Звонка
      { wch: 10 }, // Оценка
      { wch: 50 }, // Аудио
    ];
    ws['!cols'] = colWidths;

    XLSX.writeFile(wb, `calls-${getPeriodString()}.xlsx`);
  };

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch(() => {
          setAudioError(true);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Функция для проверки доступности аудио файла
  const checkAudioAvailability = async (url: string) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch {
      return false;
    }
  };

  // Обработчик открытия модального окна
  useEffect(() => {
    if (selectedCall) {
      setIsLoading(true);
      setAudioError(false);
      
      checkAudioAvailability(selectedCall.audio_file_url)
        .then(isAvailable => {
          if (!isAvailable) {
            setAudioError(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedCall]);

  const handleAudioClick = (call: CallEvaluation) => {
    setSelectedCall(call);
  };

  React.useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  if (!evaluations?.length) return null;

  return (
    <StatBox>
      <Box sx={{ 
        mb: 3,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
            <TextField
              size="small"
              placeholder="Поиск по ID менеджера или клиента"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              autoComplete="off"
              sx={{
                width: '300px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: theme => theme.palette.mode === 'dark' 
                    ? 'rgba(255, 255, 255, 0.03)' 
                    : 'rgba(0, 0, 0, 0.02)',
                  height: '36px',
                  '& fieldset': {
                    border: 'none'
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'text.secondary' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ 
              height: '36px',
              display: 'flex',
              alignItems: 'center',
              px: 2,
              borderRadius: '12px',
              backgroundColor: theme => theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.03)' 
                : 'rgba(0, 0, 0, 0.02)',
            }}>
              <Typography variant="body2" color="text.secondary">
                Данные за период: {getPeriodString()}
              </Typography>
            </Box>
          </Box>
          
          <StyledActionButton
            startIcon={<FileDownloadIcon />}
            onClick={handleExportToExcel}
            disableRipple
          >
            Скачать Excel
          </StyledActionButton>
        </Box>
      </Box>
      
      <StyledTableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ 
                fontWeight: 600, 
                color: 'text.secondary',
                ...getCellStyle()
              }}>
                Дата
              </TableCell>
              <TableCell sx={{ 
                fontWeight: 600, 
                color: 'text.secondary',
                ...getCellStyle()
              }}>
                ID Менеджера
              </TableCell>
              <TableCell sx={{ 
                fontWeight: 600, 
                color: 'text.secondary',
                ...getCellStyle()
              }}>
                ID Клиента
              </TableCell>
              <TableCell sx={{ 
                fontWeight: 600, 
                color: 'text.secondary',
                ...getCellStyle()
              }}>
                ID Звонка
              </TableCell>
              <TableCell sx={{ 
                fontWeight: 600, 
                color: 'text.secondary',
                textAlign: 'center',
                ...getCellStyle()
              }}>
                Оценка
              </TableCell>
              <TableCell sx={{ 
                fontWeight: 600, 
                color: 'text.secondary',
                textAlign: 'right',
                ...getCellStyle(true)
              }}>
                Действия
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndPaginatedCalls.map((call, index) => (
              <TableRow key={index}>
                <TableCell>{formatDateTime(call.evaluated_at)}</TableCell>
                <TableCell>{call.manager_id}</TableCell>
                <TableCell>{call.client_id}</TableCell>
                <TableCell>{call.call_id}</TableCell>
                <TableCell align="center">
                  <Typography 
                    component="span"
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minWidth: '48px',
                      padding: '4px 8px',
                      borderRadius: '12px',
                      fontWeight: 600,
                      fontSize: '0.875rem',
                      backgroundColor: theme => theme.palette.mode === 'dark' 
                        ? 'rgba(220, 251, 1, 0.1)' 
                        : 'rgba(121, 62, 238, 0.1)',
                      color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                    }}
                  >
                    {`${calculateTotalScore(call)}%`}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <CombinedButton
                    onClick={() => setSelectedCall(call)}
                    startIcon={<InfoOutlinedIcon />}
                    size="small"
                  >
                    Подробнее
                  </CombinedButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      {totalPages > 1 && (
        <Box sx={{ 
          mt: 2, 
          display: 'flex', 
          justifyContent: 'center',
          '& .MuiPaginationItem-root': {
            color: theme => theme.palette.mode === 'dark' 
              ? 'rgba(255, 255, 255, 0.7)' 
              : 'rgba(0, 0, 0, 0.7)',
            '&.Mui-selected': {
              backgroundColor: theme => theme.palette.mode === 'dark' 
                ? 'rgba(220, 251, 1, 0.2)' 
                : 'rgba(121, 62, 238, 0.2)',
              color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
            }
          }
        }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(_, newPage) => setPage(newPage)}
            color="primary"
            shape="rounded"
            size="small"
          />
        </Box>
      )}

      <StyledDialog
        fullScreen={fullScreen}
        open={Boolean(selectedCall)}
        onClose={() => setSelectedCall(null)}
        maxWidth="md"
        fullWidth
        disableScrollLock
        PaperProps={{
          sx: {
            margin: '16px',
            width: 'calc(100% - 32px)',
            maxWidth: '900px',
            maxHeight: '85vh',
          }
        }}
      >
        {selectedCall && (
          <>
            <CloseButton onClick={() => setSelectedCall(null)}>
              <CloseIcon />
            </CloseButton>

            <DialogTitle>
              Детали звонка
            </DialogTitle>

            <StyledDialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box sx={{ 
                    display: 'flex', 
                    gap: 4, 
                    flexWrap: 'wrap',
                    mb: 3
                  }}>
                    <Box>
                      <Typography color="text.secondary" variant="body2">
                        Дата
                      </Typography>
                      <Typography>
                        {formatDateTime(selectedCall.evaluated_at)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary" variant="body2">
                        ID Менеджера
                      </Typography>
                      <Typography>
                        {selectedCall.manager_id}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary" variant="body2">
                        ID Клиента
                      </Typography>
                      <Typography>
                        {selectedCall.client_id}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary" variant="body2">
                        ID Звонка
                      </Typography>
                      <Typography>
                        {selectedCall.call_id}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <AudioPlayerBox>
                    <Fade in={isLoading} timeout={200}>
                      <Box sx={{ 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        color: 'text.secondary'
                      }}>
                        <CircularProgress
                          size={48}
                          sx={{
                            color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                          }}
                        />
                        <Typography>
                          Загрузка аудио...
                        </Typography>
                      </Box>
                    </Fade>

                    <Fade 
                      in={!isLoading && audioError} 
                      timeout={200}
                    >
                      <Box sx={{ 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        color: 'text.secondary'
                      }}>
                        <ErrorOutlineIcon sx={{ fontSize: 48 }} />
                        <Typography>
                          Аудиофайл недоступен
                        </Typography>
                      </Box>
                    </Fade>

                    <Fade 
                      in={!isLoading && !audioError} 
                      timeout={200}
                    >
                      <Box sx={{ 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                      }}>
                        <IconButton
                          onClick={togglePlay}
                          sx={{
                            width: 56,
                            height: 56,
                            backgroundColor: theme => theme.palette.mode === 'dark' 
                              ? 'rgba(220, 251, 1, 0.1)' 
                              : 'rgba(121, 62, 238, 0.1)',
                            color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                            '&:hover': {
                              backgroundColor: theme => theme.palette.mode === 'dark' 
                                ? 'rgba(220, 251, 1, 0.2)' 
                                : 'rgba(121, 62, 238, 0.2)',
                            }
                          }}
                        >
                          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                        </IconButton>

                        <audio
                          ref={audioRef}
                          src={selectedCall.audio_file_url}
                          onEnded={() => setIsPlaying(false)}
                          onError={() => setAudioError(true)}
                          style={{ width: '100%' }}
                          controls
                        />
                      </Box>
                    </Fade>
                  </AudioPlayerBox>
                </Grid>

                <Grid item xs={12}>
                  <ScoreBox>
                    <Typography variant="subtitle2" color="text.secondary">
                      Общая оценка
                    </Typography>
                    <Typography 
                      variant="h4" 
                      sx={{ 
                        color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                        fontWeight: 600 
                      }}
                    >
                      {calculateTotalScore(selectedCall)}%
                    </Typography>
                  </ScoreBox>
                </Grid>

                {checklistConfig && ((checklistConfig.blocks as any).blocks || []).map((block: any) => (
                  <Grid item xs={12} key={block.id}>
                    <ScoreBox>
                      <div className="block-header">
                        <Typography variant="subtitle2" color="text.secondary">
                          {block.name}
                        </Typography>
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                            fontWeight: 600 
                          }}
                        >
                          {calculateBlockScore(selectedCall, block.id)}%
                        </Typography>
                      </div>
                      {block.items.map((item: any) => {
                        const scoreKey = item.id.replace('.', '_');
                        const score = selectedCall.scores?.[scoreKey] || 0;
                        return (
                          <div className="item-row" key={item.id}>
                            <div className="item-info">
                              <Typography className="item-id">
                                {item.id}
                              </Typography>
                              <Typography className="item-name">
                                {item.name}
                              </Typography>
                            </div>
                            <Typography 
                              className="item-status"
                              sx={{ 
                                color: theme => getScoreStatusColor(score, theme)
                              }}
                            >
                              {getScoreStatusText(score)}
                            </Typography>
                          </div>
                        );
                      })}
                    </ScoreBox>
                  </Grid>
                ))}
              </Grid>
            </StyledDialogContent>
          </>
        )}
      </StyledDialog>
    </StatBox>
  );
};

export default CallsTable;
import React, { useMemo, useState } from 'react';
import { 
  Box, 
  Typography, 
  useTheme, 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  IconButton,
  useMediaQuery,
  TextField,
  InputAdornment
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { StatBox } from '../../../components/styled';
import { ResponsiveRadar } from '@nivo/radar';
import { CallEvaluation, ChecklistConfig } from '../../../../../../services/callAnalyticsService';
import { RadarSliceTooltipDatum } from '@nivo/radar';
import { styled, Theme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { StyledActionButton } from '../../../components/styled';

interface Props {
  evaluations?: CallEvaluation[];
  checklistConfig?: ChecklistConfig | null;
  selectedManagers: string[];
  onManagersChange: (managers: string[]) => void;
}

interface Block {
  id: string;
  name: string;
  items: Array<{
    id: string;
    name: string;
    order: number;
    weight: number;
  }>;
}

interface RadarDataPoint {
  subject: string;
  [key: string]: number | string;
}

interface CustomTooltipProps {
  data: RadarSliceTooltipDatum[];
  index: string | number;
}

interface ManagerWithScore {
  id: string;
  score: number;
}

const CustomShape = (props: any) => {
  const { points, fill, stroke, strokeWidth, fillOpacity } = props;

  const path = points.reduce((pathString: string, point: any, index: number) => {
    const { x, y } = point;
    
    if (index === 0) {
      return `M ${x} ${y}`;
    }

    const prevPoint = points[index - 1];
    const nextPoint = points[index + 1] || points[0];
    
    const smoothing = 0.15;
    
    const dx = x - prevPoint.x;
    const dy = y - prevPoint.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    
    const controlLength = distance * smoothing;
    
    const cp1x = prevPoint.x + dx * (1 - smoothing);
    const cp1y = prevPoint.y + dy * (1 - smoothing);
    
    const cp2x = x - dx * smoothing;
    const cp2y = y - dy * smoothing;

    return `${pathString} C ${cp1x} ${cp1y}, ${cp2x} ${cp2y}, ${x} ${y}`;
  }, '');

  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];
  
  const dx = firstPoint.x - lastPoint.x;
  const dy = firstPoint.y - lastPoint.y;
  const distance = Math.sqrt(dx * dx + dy * dy);
  const smoothing = 0.15;
  
  const cp1x = lastPoint.x + dx * (1 - smoothing);
  const cp1y = lastPoint.y + dy * (1 - smoothing);
  const cp2x = firstPoint.x - dx * smoothing;
  const cp2y = firstPoint.y - dy * smoothing;

  return (
    <path
      d={`${path} C ${cp1x} ${cp1y}, ${cp2x} ${cp2y}, ${firstPoint.x} ${firstPoint.y} Z`}
      stroke={stroke}
      fill={fill}
      fillOpacity={fillOpacity}
      strokeWidth={strokeWidth}
    />
  );
};

const CustomTooltip = ({ data, index }: CustomTooltipProps) => {
  const theme = useTheme();
  
  const getUniqueKey = (value: number, color: string) => `${value}-${color}`;
  
  return (
    <Box
      sx={{
        background: theme.palette.mode === 'dark' ? '#1E1E1E' : '#FFF',
        padding: '12px 16px',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        border: theme => `1px solid ${theme.palette.mode === 'dark' 
          ? 'rgba(255, 255, 255, 0.1)' 
          : 'rgba(0, 0, 0, 0.1)'}`,
        minWidth: '120px',
      }}
    >
      <Typography 
        variant="subtitle2" 
        sx={{ 
          mb: 1,
          color: theme.palette.mode === 'dark' 
            ? 'rgba(255, 255, 255, 0.9)' 
            : 'rgba(0, 0, 0, 0.9)'
        }}
      >
        {index}
      </Typography>
      {data.map(({ value, color }) => (
        <Box 
          key={getUniqueKey(value, color)}
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mt: 0.5 
          }}
        >
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: color
            }}
          />
          <Typography
            sx={{
              fontSize: '0.875rem',
              color: theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.7)' 
                : 'rgba(0, 0, 0, 0.7)',
            }}
          >
            {value}%
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(4),
    background: theme.palette.mode === 'dark'
      ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)'
      : 'rgba(255, 255, 255, 0.85)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '24px',
    position: 'relative',
    zIndex: 1,
    boxShadow: theme.palette.mode === 'dark'
      ? '0 4px 24px rgba(0, 0, 0, 0.4)'
      : '0 4px 24px rgba(0, 0, 0, 0.05)',
    '& .MuiDialogTitle-root': {
      padding: 0,
      paddingBottom: theme.spacing(3),
      color: theme.palette.text.primary,
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      paddingTop: theme.spacing(2),
    },
    transition: 'box-shadow 0.3s ease',
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(10px)',
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(0, 0, 0, 0.8)'
      : 'rgba(255, 255, 255, 0.8)',
  },
}));

const SearchTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.03)' 
      : 'rgba(0, 0, 0, 0.02)',
    '& input': {
      padding: '12px 16px',
    },
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.1)',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.2)'
        : 'rgba(0, 0, 0, 0.2)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: theme.spacing(1),
  color: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.7)' 
    : 'rgba(0, 0, 0, 0.7)',
  '&:hover': {
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    backgroundColor: 'transparent',
  },
}));

const scrollbarStyles = (theme: Theme) => ({
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    '&:hover': {
      background: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.2)' 
        : 'rgba(0, 0, 0, 0.2)',
    },
  },
  '&::-webkit-scrollbar-corner': {
    background: 'transparent',
  },
});

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  height: '60vh',
  maxHeight: '600px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2, 0),
  '& .search-field': {
    padding: theme.spacing(0, 2),
  },
  '& .manager-list': {
    flex: 1,
    overflow: 'auto',
    paddingRight: theme.spacing(2),
    marginRight: -theme.spacing(2),
    paddingLeft: theme.spacing(2),
    ...scrollbarStyles(theme),
  },
  '& .empty-message': {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  }
}));

// Добавим стилизованную кнопку сброса
const ResetButton = styled(Button)(({ theme }) => ({
  color: theme.palette.error.main,
  '&.Mui-disabled': {
    color: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.3)' 
      : 'rgba(0, 0, 0, 0.3)',
  }
}));

const getManagerColor = (index: number, mode: 'light' | 'dark' = 'light') => {
  if (mode === 'dark') {
    const colors = [
      '#DCFB01', // фирменный неоново-желтый
      '#36F1CD', // бирюзовый неон
      '#3DD5FF', // электрический голубой
      '#A2FC7C', // весенний зеленый
      '#96F7B3', // мятный
      '#FFD53D', // золотистый
      '#59FFC1', // аквамариновый
      '#00E2B0', // изумрудный неон
    ];
    return colors[index % colors.length];
  } else {
    const colors = [
      '#793EEE', // фирменный фиолетовый
      '#2A6B9F', // глубокий синий
      '#00A7B5', // морской волны
      '#348553', // малахитовый
      '#0B877D', // изумрудный
      '#B86E00', // янтарный
      '#236C70', // бирюзовый
      '#1E754C', // нефритовый
    ];
    return colors[index % colors.length];
  }
};

// Функция для получения основного цвета темы
const getMainThemeColor = (mode: 'light' | 'dark') => {
  return mode === 'dark' ? '#DCFB01' : '#793EEE';
};

const ManagersRadarChart: React.FC<Props> = ({ 
  evaluations, 
  checklistConfig, 
  selectedManagers,
  onManagersChange 
}) => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedManagersInModal, setSelectedManagersInModal] = useState<string[]>(selectedManagers);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Получаем список всех уникальных менеджеров
  const allManagers = useMemo(() => {
    if (!evaluations?.length) return [];
    return Array.from(new Set(evaluations.map(e => e.manager_id)));
  }, [evaluations]);

  // Получаем менеджеров с их средними оценками
  const managersWithScores = useMemo(() => {
    if (!evaluations?.length) return [];

    const scoresByManager = evaluations.reduce((acc, evaluation) => {
      const managerId = evaluation.manager_id;
      if (!acc[managerId]) {
        acc[managerId] = {
          scores: [],
        };
      }

      // Собираем все оценки
      Object.values(evaluation.scores || {}).forEach(score => {
        acc[managerId].scores.push(score);
      });

      return acc;
    }, {} as Record<string, { scores: number[] }>);

    // Вычисляем средние значения
    return Object.entries(scoresByManager)
      .map(([id, data]): ManagerWithScore => ({
        id,
        score: Math.round(
          data.scores.reduce((sum, score) => sum + score, 0) / 
          (data.scores.length || 1) * 100
        )
      }))
      .sort((a, b) => b.score - a.score); // Сортируем по убыванию
  }, [evaluations]);

  // Фильтруем менеджеров по поисковому запросу
  const filteredManagers = useMemo(() => {
    if (!searchQuery) return managersWithScores;
    return managersWithScores.filter(manager => 
      manager.id.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [managersWithScores, searchQuery]);

  const handleManagerSelect = (managerId: string) => {
    setSelectedManagersInModal(prev => {
      if (prev.includes(managerId)) {
        return prev.filter(id => id !== managerId);
      }
      if (prev.length < 4) {
        return [...prev, managerId];
      }
      return prev;
    });
  };

  const handleSave = () => {
    onManagersChange(selectedManagersInModal);
    setIsModalOpen(false);
  };

  const getRandomManagers = useMemo(() => {
    if (!evaluations?.length) return [];
    
    // Если есть выбранные менеджеры, возвращаем их
    if (selectedManagers.length > 0) return selectedManagers;

    // Получаем уникальных менеджеров
    const uniqueManagers = Array.from(new Set(evaluations.map(e => e.manager_id)));
    
    // Если менеджеров меньше или равно 2, возвращаем всех
    if (uniqueManagers.length <= 2) return uniqueManagers;
    
    // Перемешиваем массив случайным образом
    const shuffled = [...uniqueManagers].sort(() => Math.random() - 0.5);
    
    // Берем первых двух
    return shuffled.slice(0, 2);
  }, [evaluations, selectedManagers]);

  const { data } = useMemo(() => {
    if (!evaluations?.length || !checklistConfig?.blocks) {
      return { data: [] };
    }

    const configBlocks = ((checklistConfig.blocks as any).blocks || []) as Block[];
    const sortedBlocks = configBlocks.sort((a, b) => a.id.localeCompare(b.id));
    const managersToShow = getRandomManagers;

    const radarData = sortedBlocks.map(block => {
      const blockData: any = { key: block.name };
      
      managersToShow.forEach(managerId => {
        const managerEvals = evaluations.filter(e => e.manager_id === managerId);
        const blockScores = managerEvals.map(evaluation => {
          const itemScores = block.items.map(item => {
            const scoreKey = item.id.replace('.', '_');
            return evaluation.scores?.[scoreKey] || 0;
          });
          return itemScores.length > 0
            ? itemScores.reduce((sum, score) => sum + score, 0) / itemScores.length
            : 0;
        });

        blockData[managerId] = blockScores.length > 0
          ? Math.round(blockScores.reduce((sum, score) => sum + score, 0) / blockScores.length * 100)
          : 0;
      });

      return blockData;
    });

    return { data: radarData };
  }, [evaluations, checklistConfig, getRandomManagers]);

  // Добавим функцию сброса
  const handleReset = () => {
    setSelectedManagersInModal([]);
  };

  if (!data.length) {
    return (
      <StatBox sx={{ p: 3, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography color="text.secondary">
          Нет данных для отображения
        </Typography>
      </StatBox>
    );
  }

  return (
    <StatBox sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 1 
      }}>
        <Typography 
          variant="subtitle2" 
          color="text.secondary"
        >
          Сравнение по блокам
        </Typography>
        <StyledActionButton
          startIcon={<CompareArrowsIcon />}
          onClick={() => setIsModalOpen(true)}
          disableRipple
        >
          Сравнить менеджеров
        </StyledActionButton>
      </Box>

      <StyledDialog
        fullScreen={fullScreen}
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSearchQuery('');
        }}
        maxWidth="sm"
        fullWidth
        disableScrollLock
        PaperProps={{
          sx: {
            margin: '16px',
            width: 'calc(100% - 32px)',
            maxWidth: '600px',
          }
        }}
      >
        <CloseButton
          onClick={() => {
            setIsModalOpen(false);
            setSearchQuery('');
          }}
        >
          <CloseIcon />
        </CloseButton>

        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 2
          }}>
            <Typography variant="h6">
              Выберите менеджеров для сравнения (до 4)
            </Typography>
            <SearchTextField
              fullWidth
              placeholder="Поиск менеджера..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'text.secondary' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </DialogTitle>

        <StyledDialogContent>
          {filteredManagers.length === 0 ? (
            <Box className="empty-message">
              <Typography color="text.secondary">
                Менеджеры не найдены
              </Typography>
            </Box>
          ) : (
            <Box className="manager-list">
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 2,
                  mb: 2,
                  px: 1
                }}
              >
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '0.75rem',
                    minWidth: '30px',
                    textAlign: 'center'
                  }}
                >
                  №
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  flex: 1,
                  minWidth: 0,
                  pl: 4
                }}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: '0.75rem',
                      flex: 1,
                      minWidth: 0
                    }}
                  >
                    ID менеджера
                  </Typography>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: '0.75rem',
                      minWidth: '60px',
                      textAlign: 'right'
                    }}
                  >
                    Средняя
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {filteredManagers.map((manager, index) => (
                  <Box
                    key={manager.id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      py: 0.5
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme => getMainThemeColor(theme.palette.mode),
                        fontWeight: 600,
                        minWidth: '30px',
                        textAlign: 'center'
                      }}
                    >
                      #{index + 1}
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedManagersInModal.includes(manager.id)}
                          onChange={() => handleManagerSelect(manager.id)}
                          disabled={
                            !selectedManagersInModal.includes(manager.id) && 
                            selectedManagersInModal.length >= 4
                          }
                        />
                      }
                      label={
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: 2,
                          flex: 1,
                          minWidth: 0
                        }}>
                          <Typography
                            color="text.secondary"
                            sx={{
                              flex: 1,
                              minWidth: 0,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {manager.id}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              minWidth: '60px',
                              textAlign: 'right',
                              flexShrink: 0
                            }}
                          >
                            {manager.score}%
                          </Typography>
                        </Box>
                      }
                      sx={{
                        mx: 0,
                        flex: 1,
                        minWidth: 0,
                        '& .MuiFormControlLabel-label': {
                          flex: 1,
                          minWidth: 0,
                          color: theme.palette.text.secondary
                        }
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </StyledDialogContent>

        <DialogActions sx={{ 
          p: 2, 
          pt: 3,
          display: 'flex',
          justifyContent: 'space-between' // Изменяем выравнивание
        }}>
          <ResetButton 
            onClick={handleReset}
            disabled={selectedManagersInModal.length === 0}
          >
            Сбросить все
          </ResetButton>
          <Box>
            <Button 
              onClick={() => {
                setIsModalOpen(false);
                setSearchQuery('');
              }}
              color="inherit"
              sx={{ mr: 1 }}
            >
              Отмена
            </Button>
            <Button 
              onClick={handleSave}
              variant="contained"
              disabled={selectedManagersInModal.length === 0}
            >
              Применить
            </Button>
          </Box>
        </DialogActions>
      </StyledDialog>

      <Box sx={{ flex: 1, height: 450, minHeight: 450, maxHeight: 450, position: 'relative' }}>
        <ResponsiveRadar
          data={data}
          keys={getRandomManagers}
          indexBy="key"
          maxValue={100}
          valueFormat=">-.0f"
          margin={{ top: 80, right: 100, bottom: 50, left: 100 }}
          borderWidth={2}
          borderColor={{ from: 'color' }}
          gridLabelOffset={15}
          enableDots={false}
          curve="catmullRomClosed"
          theme={{
            background: 'transparent',
            text: {
              fontSize: 12,
              fill: theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.7)' 
                : 'rgba(0, 0, 0, 0.6)',
            },
            grid: {
              line: {
                stroke: theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.1)',
              }
            }
          }}
          colors={getRandomManagers.map((_, index) => getManagerColor(index, theme.palette.mode))}
          fillOpacity={0.15}
          blendMode="normal"
          animate={true}
          motionConfig="gentle"
          gridShape="circular"
          gridLevels={5}
          sliceTooltip={CustomTooltip}
        />
      </Box>

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
        mt: 3,
        mb: 1,
        px: 2,
        flexWrap: 'wrap',
        width: '100%'
      }}>
        {getRandomManagers.map((managerId, index) => (
          <Box
            key={managerId}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1.5,
              minWidth: 'fit-content',
              maxWidth: '45%',
              flexShrink: 0
            }}
          >
            <Box
              sx={{
                width: 10,
                height: 10,
                borderRadius: '50%',
                flexShrink: 0,
                backgroundColor: getManagerColor(index, theme.palette.mode)
              }}
            />
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: theme => theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.7)' 
                  : 'rgba(0, 0, 0, 0.7)',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                wordBreak: 'break-all',
                whiteSpace: 'normal',
                lineHeight: '1.2',
                textAlign: 'left'
              }}
            >
              {managerId}
            </Typography>
          </Box>
        ))}
      </Box>
    </StatBox>
  );
};

export default ManagersRadarChart; 
import axios from 'axios';
import { API_URL } from '../config/index';

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  companyIndustry: string;
  role: string;
  teamId: string | null;
}

export interface LoginResponse {
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
  user: User;
}

interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

interface RegisterData {
  firstName: string;
  lastName: string;
  companyName: string;
  employeesCount: string;
  industry: string;
  phone: string;
  email: string;
  password: string;
}

interface RegisterResponse {
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
  user: User;
}

const api = axios.create({
  baseURL: `${API_URL}/client`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Добавляем интерцептор для добавления токена
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

interface UpdateProfileData {
  firstName?: string;
  lastName?: string;
  email?: string;
}

interface ChangePasswordData {
  currentPassword: string;
  newPassword: string;
}

interface ActivityLog {
  id: string;
  activityType: string;
  ipAddress: string;
  userAgent: string;
  deviceInfo: string;
  createdAt: string;
}

// Добавляем новые интерфейсы
interface UserActivity {
  lastLogin: string | null;
  accountCreated: string;
  status: 'active' | 'inactive';
}

export const authService = {
  async login(email: string, password: string, captchaToken: string): Promise<LoginResponse> {
    const response = await api.post<LoginResponse>('/auth/login', {
      email,
      password,
      captchaToken
    });
    
    if (response.data.user.teamId) {
      localStorage.setItem('teamId', response.data.user.teamId);
    } else {
      localStorage.removeItem('teamId');
    }
    
    return response.data;
  },

  async logout(): Promise<void> {
    await api.post('/auth/logout');
    // Удаляем teamId при выходе
    localStorage.removeItem('teamId');
  },

  async refreshToken(refreshToken: string): Promise<RefreshTokenResponse> {
    console.log('🔄 Attempting to refresh tokens');
    const response = await api.post<RefreshTokenResponse>('/auth/refresh', {
      refreshToken,
    });
    console.log('✅ Tokens refreshed');
    return response.data;
  },

  async register(data: RegisterData, captchaToken: string): Promise<RegisterResponse> {
    const response = await api.post<RegisterResponse>('/auth/register', {
      ...data,
      captchaToken
    });
    return response.data;
  },

  async updateProfile(data: UpdateProfileData): Promise<User> {
    const response = await api.put<User>('/auth/profile', data);
    return response.data;
  },

  async changePassword(data: ChangePasswordData): Promise<void> {
    await api.put('/auth/password', data);
  },

  getActivityLogs: async (): Promise<{ data: ActivityLog[] }> => {
    const response = await api.get<ActivityLog[]>('/auth/activity-logs');
    return { data: response.data };
  },

  // Добавляем новый метод для получения активности пользователя
  getUserActivity: async (): Promise<UserActivity> => {
    const response = await api.get<UserActivity>('/auth/activity');
    return response.data;
  },

  async validateToken(): Promise<void> {
    await api.get('/auth/validate');
  },
}; 
import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import { StatBox } from '../../../components/styled';

const CallsTabSkeleton: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Skeleton 
          variant="text" 
          width={200} 
          height={32} 
          sx={{ 
            mb: 1,
            borderRadius: 1 
          }} 
        />
      </Grid>

      <Grid item xs={12}>
        <StatBox>
          {/* Верхняя панель с поиском и периодом */}
          <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
            <Skeleton 
              variant="rounded" 
              width={300} 
              height={36} 
              sx={{ borderRadius: '12px' }}
            />
            <Skeleton 
              variant="rounded" 
              width={200} 
              height={36} 
              sx={{ borderRadius: '12px' }}
            />
            <Box sx={{ flex: 1 }} />
            <Skeleton 
              variant="rounded" 
              width={150} 
              height={36} 
              sx={{ borderRadius: '12px' }}
            />
          </Box>

          {/* Таблица */}
          <Box>
            {/* Заголовок таблицы */}
            <Skeleton 
              variant="rectangular" 
              width="100%" 
              height={48} 
              sx={{ 
                mb: 1,
                borderRadius: '12px'
              }} 
            />
            
            {/* Строки таблицы */}
            {[...Array(8)].map((_, index) => (
              <Skeleton 
                key={index}
                variant="rectangular" 
                width="100%" 
                height={52} 
                sx={{ 
                  mb: 1,
                  opacity: 1 - (index * 0.1),
                  borderRadius: '12px'
                }} 
              />
            ))}
          </Box>

          {/* Пагинация */}
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <Skeleton 
              variant="rounded" 
              width={300} 
              height={32} 
              sx={{ borderRadius: '12px' }}
            />
          </Box>
        </StatBox>
      </Grid>
    </Grid>
  );
};

export default CallsTabSkeleton; 
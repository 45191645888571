import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requireTeam?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ 
  children, 
  requireTeam = true 
}) => {
  const { token, teamId } = useAuth();

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (requireTeam && !teamId) {
    return <Navigate to="/request-access" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute; 
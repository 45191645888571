import React from 'react';
import { 
  Box, 
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Theme,
  Pagination,
  TextField,
  InputAdornment,
} from '@mui/material';
import { StatBox, StyledActionButton } from '../../../components/styled';
import { CallEvaluation } from '../../../../../../services/callAnalyticsService';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';
import { useFilters } from '../../../context/FiltersContext';

interface Props {
  evaluations?: CallEvaluation[];
}

interface ClientData {
  id: string;
  totalCalls: number;
  avgScore: number;
  managers: Set<string>;
  lastCall: Date;
}

interface ProcessedClientData {
  id: string;
  totalCalls: number;
  avgScore: number;
  managersCount: number;
  lastCall: Date;
}

// Обновляем функцию для получения стилей ячейки
const getCellStyle = (isLastInBlock: boolean = false) => ({
  borderRight: (theme: Theme) => {
    if (isLastInBlock) {
      return `2px solid ${theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.2)' 
        : 'rgba(0, 0, 0, 0.2)'}`;
    }
    return 'none';
  },
  borderBottom: (theme: Theme) => `1px solid ${theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.1)' 
    : 'rgba(0, 0, 0, 0.1)'}`,
  '&:first-of-type': {
    borderLeft: (theme: Theme) => `1px solid ${theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.1)'}`,
  }
});

// Добавляем функцию для определения цвета фона
const getScoreBackgroundColor = (score: number, theme: Theme) => {
  if (score >= 90) {
    return theme.palette.mode === 'dark' 
      ? 'rgba(0, 200, 83, 0.15)'
      : 'rgba(0, 200, 83, 0.1)';
  } else if (score >= 70) {
    return theme.palette.mode === 'dark'
      ? 'rgba(0, 200, 83, 0.1)'
      : 'rgba(0, 200, 83, 0.05)';
  } else if (score >= 50) {
    return theme.palette.mode === 'dark'
      ? 'rgba(255, 152, 0, 0.15)'
      : 'rgba(255, 152, 0, 0.1)';
  } else {
    return theme.palette.mode === 'dark'
      ? 'rgba(244, 67, 54, 0.15)'
      : 'rgba(244, 67, 54, 0.1)';
  }
};

const ClientsTable: React.FC<Props> = ({ evaluations }) => {
  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState('');
  const itemsPerPage = 20;

  const { period, startDate, endDate } = useFilters();

  const getPeriodString = () => {
    if (!startDate || !endDate) return '';
    return `${startDate.format('DD.MM.YY')} - ${endDate.format('DD.MM.YY')}`;
  };

  const clients = React.useMemo(() => {
    if (!evaluations?.length) return [];

    const clientsMap = new Map<string, ClientData>();

    evaluations.forEach(evaluation => {
      const clientId = evaluation.client_id;
      if (!clientsMap.has(clientId)) {
        clientsMap.set(clientId, {
          id: clientId,
          totalCalls: 0,
          avgScore: 0,
          managers: new Set(),
          lastCall: new Date(evaluation.evaluated_at)
        });
      }

      const clientData = clientsMap.get(clientId)!;
      clientData.totalCalls += 1;
      clientData.avgScore += evaluation.total_score * 100;
      clientData.managers.add(evaluation.manager_id);
      
      const callDate = new Date(evaluation.evaluated_at);
      if (callDate > clientData.lastCall) {
        clientData.lastCall = callDate;
      }
    });

    return Array.from(clientsMap.values()).map(client => ({
      id: client.id,
      totalCalls: client.totalCalls,
      avgScore: Math.round(client.avgScore / client.totalCalls),
      managersCount: client.managers.size,
      lastCall: client.lastCall
    })).sort((a, b) => b.avgScore - a.avgScore);
  }, [evaluations]);

  const filteredAndPaginatedClients = React.useMemo(() => {
    if (!clients.length) return [];

    const filtered = searchQuery
      ? clients.filter(client => 
          client.id.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : clients;

    const startIndex = (page - 1) * itemsPerPage;
    return filtered.slice(startIndex, startIndex + itemsPerPage);
  }, [clients, page, searchQuery]);

  const totalPages = React.useMemo(() => {
    const filtered = searchQuery
      ? clients.filter(client => 
          client.id.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : clients;
    return Math.ceil(filtered.length / itemsPerPage);
  }, [clients, searchQuery]);

  React.useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  const handleExportToExcel = () => {
    if (!clients.length) return;

    const data = clients.map(client => ({
      'ID клиента': client.id,
      'Средний балл': `${client.avgScore}%`,
      'Количество звонков': client.totalCalls,
      'Количество менеджеров': client.managersCount,
      'Последний звонок': client.lastCall.toLocaleDateString('ru-RU')
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();

    // Стили для заголовков
    const headerStyle = {
      font: { bold: true, color: { rgb: "666666" } },
      fill: { fgColor: { rgb: "F9F9F9" } },
      alignment: { horizontal: 'center' }
    };

    // Применяем стили к заголовкам
    const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');
    for (let C = range.s.c; C <= range.e.c; C++) {
      const headerCell = XLSX.utils.encode_cell({ r: 0, c: C });
      ws[headerCell].s = headerStyle;
    }

    // Устанавливаем ширину колонок
    ws['!cols'] = [
      { wch: 30 }, // ID клиента
      { wch: 15 }, // Средний балл
      { wch: 15 }, // Количество звонков
      { wch: 15 }, // Количество менеджеров
      { wch: 15 }, // Последний звонок
    ];

    const periodString = getPeriodString();
    XLSX.utils.book_append_sheet(wb, ws, 'Клиенты');
    XLSX.writeFile(wb, `clients-statistics-${periodString}.xlsx`);
  };

  if (!clients.length) return null;

  return (
    <StatBox>
      <Box sx={{ 
        mb: 3,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Typography 
          variant="subtitle2" 
          color="text.secondary"
          sx={{ mb: 2 }}
        >
          Статистика по клиентам:
        </Typography>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
            <TextField
              size="small"
              placeholder="Поиск по ID"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              autoComplete="off"
              sx={{
                width: '300px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: theme => theme.palette.mode === 'dark' 
                    ? 'rgba(255, 255, 255, 0.03)' 
                    : 'rgba(0, 0, 0, 0.02)',
                  height: '36px',
                  '& fieldset': {
                    border: 'none'
                  },
                  '&:hover fieldset': {
                    border: 'none'
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none'
                  }
                },
                '& .MuiInputBase-input': {
                  padding: '0 14px',
                  height: '36px',
                  lineHeight: '36px'
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'text.secondary' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ 
              height: '36px',
              display: 'flex',
              alignItems: 'center',
              px: 2,
              borderRadius: '12px',
              backgroundColor: theme => theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.03)' 
                : 'rgba(0, 0, 0, 0.02)',
            }}>
              <Typography variant="body2" color="text.secondary">
                Данные за период: {getPeriodString()}
              </Typography>
            </Box>
          </Box>
          
          <StyledActionButton
            startIcon={<FileDownloadIcon />}
            onClick={handleExportToExcel}
            disableRipple
          >
            Скачать Excel
          </StyledActionButton>
        </Box>
      </Box>
      
      <TableContainer>
        <Table size="small" sx={{
          borderCollapse: 'separate',
          borderSpacing: 0,
          '& .MuiTableCell-root': {
            padding: '8px 16px',
          },
          '& .MuiTableHead-root': {
            position: 'sticky',
            top: 0,
            zIndex: 2,
          },
          '& .MuiTableRow-root:first-of-type .MuiTableCell-root': {
            borderTop: theme => `1px solid ${theme.palette.mode === 'dark' 
              ? 'rgba(255, 255, 255, 0.1)' 
              : 'rgba(0, 0, 0, 0.1)'}`,
          },
          '& .MuiTableHead-root .MuiTableRow-root': {
            backgroundColor: theme => theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.03)'
              : 'rgba(0, 0, 0, 0.02)',
            '& .MuiTableCell-root': {
              borderBottom: theme => `2px solid ${theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.2)'
                : 'rgba(0, 0, 0, 0.2)'}`,
              '&:first-of-type': {
                borderTopLeftRadius: '8px',
                borderLeft: theme => `1px solid ${theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.1)'}`,
              },
              '&:last-of-type': {
                borderTopRightRadius: '8px',
                borderRight: theme => `1px solid ${theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.1)'}`,
              },
            }
          },
          '& .MuiTableBody-root .MuiTableRow-root': {
            '& .MuiTableCell-root': {
              borderBottom: theme => `1px solid ${theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, 0.1)'}`,
              '&:first-of-type': {
                borderLeft: theme => `1px solid ${theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.1)'}`,
              },
              '&:last-of-type': {
                borderRight: theme => `1px solid ${theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.1)'}`,
              },
            },
            '&:last-child .MuiTableCell-root': {
              borderBottom: theme => `1px solid ${theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, 0.1)'}`,
              '&:first-of-type': {
                borderBottomLeftRadius: '8px',
              },
              '&:last-of-type': {
                borderBottomRightRadius: '8px',
              },
            },
            '&:hover': {
              backgroundColor: theme => theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.05)'
                : 'rgba(0, 0, 0, 0.03)',
            }
          }
        }}>
          <TableHead sx={{
            '& .MuiTableRow-root': {
              backgroundColor: theme => theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.03)'
                : 'rgba(0, 0, 0, 0.02)',
            },
            '& .MuiTableCell-root': {
              borderBottom: theme => `2px solid ${theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.2)'
                : 'rgba(0, 0, 0, 0.2)'}`,
            }
          }}>
            <TableRow>
              <TableCell sx={{ 
                fontWeight: 600, 
                color: 'text.secondary',
                ...getCellStyle()
              }}>
                ID клиента
              </TableCell>
              <TableCell sx={{ 
                fontWeight: 600, 
                color: 'text.secondary',
                textAlign: 'center',
                ...getCellStyle()
              }}>
                Средний балл
              </TableCell>
              <TableCell sx={{ 
                fontWeight: 600, 
                color: 'text.secondary',
                textAlign: 'center',
                ...getCellStyle()
              }}>
                Количество звонков
              </TableCell>
              <TableCell sx={{ 
                fontWeight: 600, 
                color: 'text.secondary',
                textAlign: 'center',
                ...getCellStyle()
              }}>
                Количество менеджеров
              </TableCell>
              <TableCell sx={{ 
                fontWeight: 600, 
                color: 'text.secondary',
                textAlign: 'center',
                ...getCellStyle(true)
              }}>
                Последний звонок
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndPaginatedClients.map(client => (
              <TableRow key={client.id}>
                <TableCell sx={getCellStyle()}>
                  {client.id}
                </TableCell>
                <TableCell 
                  sx={{ 
                    textAlign: 'center',
                    color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                    fontWeight: 600,
                    ...getCellStyle(),
                    backgroundColor: theme => getScoreBackgroundColor(client.avgScore, theme)
                  }}
                >
                  {client.avgScore}%
                </TableCell>
                <TableCell sx={{ ...getCellStyle(), textAlign: 'center' }}>
                  {client.totalCalls}
                </TableCell>
                <TableCell sx={{ ...getCellStyle(), textAlign: 'center' }}>
                  {client.managersCount}
                </TableCell>
                <TableCell sx={{ ...getCellStyle(true), textAlign: 'center' }}>
                  {client.lastCall.toLocaleDateString('ru-RU')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {totalPages > 1 && (
        <Box sx={{ 
          mt: 2, 
          display: 'flex', 
          justifyContent: 'center',
          '& .MuiPaginationItem-root': {
            color: theme => theme.palette.mode === 'dark' 
              ? 'rgba(255, 255, 255, 0.7)' 
              : 'rgba(0, 0, 0, 0.7)',
            '&.Mui-selected': {
              backgroundColor: theme => theme.palette.mode === 'dark' 
                ? 'rgba(220, 251, 1, 0.2)' 
                : 'rgba(121, 62, 238, 0.2)',
              color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
            }
          }
        }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(_, newPage) => setPage(newPage)}
            color="primary"
            shape="rounded"
            size="small"
          />
        </Box>
      )}
    </StatBox>
  );
};

export default ClientsTable; 
import React from 'react';
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  SelectChangeEvent,
  styled,
  Button,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { FilterList } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFilters } from '../context/FiltersContext';
import { SxProps } from '@mui/system';
import { PickerPopperProps } from '@mui/x-date-pickers/internals';
import { useTranslation } from 'react-i18next';

// Стилизованные компоненты
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  position: 'relative',
  '& .MuiInputLabel-root': {
    '&.MuiInputLabel-shrink': {
      background: theme.palette.mode === 'dark' 
        ? 'linear-gradient(180deg, rgba(30, 30, 30, 0) 0%, rgba(30, 30, 30, 0.95) 45%, rgba(30, 30, 30, 0.95) 55%, rgba(30, 30, 30, 0) 100%)'
        : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.95) 45%, rgba(255, 255, 255, 0.95) 55%, rgba(255, 255, 255, 0) 100%)',
      padding: '0 8px',
      marginLeft: -4,
      borderRadius: '12px',
    }
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
    borderRadius: '12px',
    transition: 'border-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
    },
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
    },
  },
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  width: '100%',
  '& .MuiFormLabel-root': {
    '&.MuiFormLabel-filled, &.Mui-focused': {
      background: theme.palette.mode === 'dark' 
        ? 'linear-gradient(180deg, rgba(30, 30, 30, 0) 0%, rgba(30, 30, 30, 0.95) 45%, rgba(30, 30, 30, 0.95) 55%, rgba(30, 30, 30, 0) 100%)'
        : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.95) 45%, rgba(255, 255, 255, 0.95) 55%, rgba(255, 255, 255, 0) 100%)',
      padding: '0 8px',
      marginLeft: -4,
      borderRadius: '12px',
    }
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
    borderRadius: '12px',
    transition: 'border-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
    },
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
      '& fieldset': {
        borderColor: 'transparent',
      },
    },
    padding: '8px 14px',
    '& input': {
      padding: 0,
    },
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
  borderRadius: '12px',
  padding: '8px',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
  borderRadius: '12px',
  padding: '8px 16px',
  height: '40px',
  color: theme.palette.text.secondary,
  textTransform: 'none',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
    color: theme.palette.text.disabled,
  }
}));

const menuProps = {
  PaperProps: {
    sx: {
      mt: 1.5,
      background: (theme: Theme) => theme.palette.mode === 'dark'
        ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.85) 0%, rgba(18, 18, 18, 0.85) 100%)'
        : 'rgba(255, 255, 255, 0.85)',
      backdropFilter: 'blur(20px)',
      border: 'none',
      borderRadius: '12px',
      boxShadow: (theme: Theme) => theme.palette.mode === 'dark'
        ? '0 4px 24px rgba(0, 0, 0, 0.4)'
        : '0 4px 24px rgba(0, 0, 0, 0.05)',
      '& .MuiList-root': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        px: 2,
        py: 0.75,
        mx: 1,
        my: 0.25,
        fontSize: '0.875rem',
        borderRadius: '8px',
        color: 'text.primary',
        transition: 'all 0.3s ease',
        '&:hover': {
          backgroundColor: 'transparent',
          transform: 'translateX(4px)',
        },
        '&.Mui-selected': {
          backgroundColor: (theme: Theme) => theme.palette.mode === 'dark' 
            ? 'rgba(220, 251, 1, 0.15)' 
            : 'rgba(121, 62, 238, 0.15)',
          color: (theme: Theme) => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
          '&:hover': {
            backgroundColor: (theme: Theme) => theme.palette.mode === 'dark'
              ? 'rgba(220, 251, 1, 0.25)'
              : 'rgba(121, 62, 238, 0.25)',
          },
        },
      },
    },
  },
  disableScrollLock: true,
  MenuListProps: {
    sx: {
      '& .MuiMenuItem-root': {
        fontSize: '0.875rem',
      },
    },
  },
};

const calendarStyles = {
  sx: {
    '& .MuiPaper-root': {
      mt: 1,
      width: 'auto',
      minWidth: '280px',
      maxWidth: '320px',
      height: 'auto',
      background: (theme: Theme) => theme.palette.mode === 'dark'
        ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.85) 0%, rgba(18, 18, 18, 0.85) 100%)'
        : 'rgba(255, 255, 255, 0.85)',
      backdropFilter: 'blur(20px)',
      border: 'none',
      borderRadius: '12px',
      boxShadow: (theme: Theme) => theme.palette.mode === 'dark'
        ? '0 4px 24px rgba(0, 0, 0, 0.4)'
        : '0 4px 24px rgba(0, 0, 0, 0.05)',
      transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out',
      
      '& .MuiCalendarOrClockPicker-root': {
        width: '100%',
        margin: 0,
      },
      
      '& .MuiCalendarPicker-root': {
        width: '100%',
        margin: 0,
      },
      
      '& .MuiPickersCalendarHeader-root': {
        padding: '16px 16px 8px 16px',
        color: (theme: Theme) => theme.palette.text.primary,
        '& .MuiPickersArrowSwitcher-button': {
          color: (theme: Theme) => theme.palette.text.secondary,
          padding: '4px',
          '&:hover': {
            backgroundColor: (theme: Theme) => theme.palette.mode === 'dark'
              ? 'rgba(220, 251, 1, 0.15)'
              : 'rgba(121, 62, 238, 0.15)',
          }
        },
        '& .MuiPickersCalendarHeader-label': {
          fontSize: '1rem',
          fontWeight: 500,
        }
      },
      
      '& .MuiDayPicker-header': {
        '& .MuiDayCalendar-weekDayLabel': {
          color: (theme: Theme) => theme.palette.text.secondary,
          width: '36px',
          height: '36px',
          margin: '2px',
        }
      },
      
      '& .MuiPickersDay-root': {
        width: '36px',
        height: '36px',
        margin: '2px',
        borderRadius: '8px',
        fontSize: '0.875rem',
        '&:hover': {
          backgroundColor: (theme: Theme) => theme.palette.mode === 'dark'
            ? 'rgba(220, 251, 1, 0.15)'
            : 'rgba(121, 62, 238, 0.15)',
        },
        '&.Mui-selected': {
          backgroundColor: (theme: Theme) => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
          color: (theme: Theme) => theme.palette.mode === 'dark' ? '#000' : '#fff',
          '&:hover': {
            backgroundColor: (theme: Theme) => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
          }
        },
        '&.MuiPickersDay-today': {
          borderColor: (theme: Theme) => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
        }
      },
      
      '& .MuiPickersYear-yearButton, & .MuiPickersMonth-root': {
        borderRadius: '8px',
        margin: '4px',
        '&:hover': {
          backgroundColor: (theme: Theme) => theme.palette.mode === 'dark'
            ? 'rgba(220, 251, 1, 0.15)'
            : 'rgba(121, 62, 238, 0.15)',
        },
        '&.Mui-selected': {
          backgroundColor: (theme: Theme) => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
          color: (theme: Theme) => theme.palette.mode === 'dark' ? '#000' : '#fff',
          '&:hover': {
            backgroundColor: (theme: Theme) => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
          }
        }
      }
    }
  }
};

const tooltipStyles: TooltipProps['componentsProps'] = {
  tooltip: {
    sx: {
      bgcolor: theme => theme.palette.mode === 'dark' 
        ? 'rgba(30, 30, 30, 0.95)' 
        : 'rgba(255, 255, 255, 0.95)',
      backdropFilter: 'blur(20px)',
      borderRadius: '12px',
      p: 1.5,
      boxShadow: theme => theme.palette.mode === 'dark'
        ? '0 4px 24px rgba(0, 0, 0, 0.4)'
        : '0 4px 24px rgba(0, 0, 0, 0.05)',
      color: 'text.primary',
      fontSize: '0.875rem',
      maxWidth: 'none',
    }
  },
  arrow: {
    sx: {
      color: theme => theme.palette.mode === 'dark'
        ? 'rgba(30, 30, 30, 0.95)'
        : 'rgba(255, 255, 255, 0.95)',
      '&::before': {
        border: 'none'
      }
    }
  }
};

const Filters: React.FC = () => {
  const {
    period,
    startDate,
    endDate,
    updateDatesByPeriod,
    setStartDate,
    setEndDate,
  } = useFilters();
  const { t } = useTranslation();

  const handlePeriodChange = (event: SelectChangeEvent) => {
    updateDatesByPeriod(event.target.value);
  };

  return (
    <Box sx={{ 
      mb: 3, 
      p: 2, 
      borderRadius: 3, 
      bgcolor: theme => theme.palette.mode === 'dark' ? 'rgba(30, 30, 30, 0.85)' : 'rgba(255, 255, 255, 0.85)',
      backdropFilter: 'blur(20px)',
    }}>
      <Grid container spacing={2} alignItems="center">
        {/* Период */}
        <Grid item xs={12} md={2}>
          <StyledFormControl fullWidth size="small">
            <InputLabel>{t('filters.period')}</InputLabel>
            <Select 
              value={period} 
              label={t('filters.period')}
              onChange={handlePeriodChange}
              MenuProps={menuProps}
            >
              <MenuItem value="today">{t('filters.periods.today')}</MenuItem>
              <MenuItem value="week">{t('filters.periods.week')}</MenuItem>
              <MenuItem value="month">{t('filters.periods.month')}</MenuItem>
              <MenuItem value="quarter">{t('filters.periods.quarter')}</MenuItem>
              <MenuItem value="year">{t('filters.periods.year')}</MenuItem>
              <MenuItem value="custom">{t('filters.periods.custom')}</MenuItem>
            </Select>
          </StyledFormControl>
        </Grid>

        {/* Даты */}
        <Grid item xs={12} md={4}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Tooltip 
              title={period !== 'custom' ? t('filters.dateTooltip') : ""}
              placement="top"
              arrow
              enterDelay={200}
              enterNextDelay={200}
              componentsProps={tooltipStyles}
            >
              <Box sx={{ width: '100%' }}>
                <StyledDatePicker
                  label={t('filters.dateFrom')}
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  disabled={period !== 'custom'}
                  slotProps={{
                    popper: calendarStyles,
                    textField: {
                      size: 'small'
                    }
                  }}
                />
              </Box>
            </Tooltip>
            <Tooltip 
              title={period !== 'custom' ? t('filters.dateTooltip') : ""}
              placement="top"
              arrow
              enterDelay={200}
              enterNextDelay={200}
              componentsProps={tooltipStyles}
            >
              <Box sx={{ width: '100%' }}>
                <StyledDatePicker
                  label={t('filters.dateTo')}
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  disabled={period !== 'custom'}
                  slotProps={{
                    popper: calendarStyles,
                    textField: {
                      size: 'small'
                    }
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        </Grid>

        {/* Кастомный фильтр */}
        <Grid item xs={12} md={3}>
          <StyledButton
            disabled
            endIcon={<FilterList />}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              minWidth: '250px',
              width: 'auto',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {t('filters.additionalFilters')}
              <Box
                sx={{
                  px: 1,
                  py: 0.5,
                  bgcolor: theme => theme.palette.mode === 'dark'
                    ? 'rgba(220, 251, 1, 0.1)'
                    : 'rgba(121, 62, 238, 0.1)',
                  borderRadius: '8px',
                  fontSize: '0.75rem',
                  color: theme => theme.palette.mode === 'dark'
                    ? 'primary.main'
                    : '#793EEE',
                  fontWeight: 500,
                }}
              >
                {t('filters.soon')}
              </Box>
            </Box>
          </StyledButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filters; 
import React from 'react';
import { Grid, Skeleton, Box, Tabs, Tab } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { StatBox } from '../../styled';

const StatisticsSkeleton: React.FC = () => {
  return (
    <>
      {/* Заголовок "Общая статистика" */}
      <Grid item xs={12}>
        <Skeleton 
          variant="text" 
          width={200} 
          height={32} 
          sx={{ 
            mb: 1,
            bgcolor: theme => theme.palette.mode === 'dark' 
              ? 'rgba(220, 251, 1, 0.1)' 
              : 'rgba(121, 62, 238, 0.1)'
          }}
        />
      </Grid>

      {/* Первый ряд статистики */}
      <Grid item xs={12} md={3}>
        <StatBox sx={{ height: '300px', display: 'flex', flexDirection: 'column' }}>
          <Skeleton variant="text" width={120} height={24} />
          <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1, mb: 3 }}>
            <Skeleton variant="text" width={100} height={80} />
            <Skeleton variant="text" width={30} height={30} />
          </Box>
          <Box sx={{ flex: 1, mt: 'auto' }}>
            <Skeleton 
              variant="rectangular" 
              height="100%" 
              sx={{ borderRadius: 2 }}
            />
          </Box>
        </StatBox>
      </Grid>
      
      <Grid item xs={12} md={4}>
        <StatBox sx={{ height: '300px', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Box>
              <Skeleton variant="text" width={140} height={24} sx={{ mb: 1 }} />
              <Skeleton variant="text" width={100} height={40} />
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Skeleton variant="text" width={80} height={24} />
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Skeleton 
              variant="rectangular" 
              height="100%" 
              sx={{ borderRadius: 2 }}
            />
          </Box>
        </StatBox>
      </Grid>
      
      <Grid item xs={12} md={5}>
        <StatBox sx={{ height: '300px', display: 'flex', flexDirection: 'column' }}>
          <Skeleton variant="text" width={160} height={24} sx={{ mb: 3 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
            <Box sx={{ width: '50%', position: 'relative' }}>
              <Skeleton 
                variant="circular" 
                width={200} 
                height={200} 
                sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
              />
            </Box>
            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 2, py: 2 }}>
              {[...Array(5)].map((_, i) => (
                <Box key={i} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Skeleton variant="circular" width={12} height={12} />
                  <Box sx={{ flex: 1 }}>
                    <Skeleton variant="text" width="80%" />
                  </Box>
                  <Skeleton variant="text" width={40} />
                </Box>
              ))}
            </Box>
          </Box>
        </StatBox>
      </Grid>

      {/* Второй ряд */}
      <Grid container item xs={12} spacing={3} sx={{ mt: -3 }}>
        <Grid item xs={12} md={6}>
          <StatBox sx={{ height: '405px', display: 'flex', flexDirection: 'column' }}>
            <Skeleton variant="text" width={200} height={24} sx={{ mb: 3 }} />
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
              {[...Array(8)].map((_, i) => (
                <Box key={i} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Skeleton 
                    variant="text" 
                    width={40} 
                    sx={{ 
                      mr: 2, 
                      color: theme => theme.palette.mode === 'dark' 
                        ? 'rgba(255, 255, 255, 0.05)' 
                        : 'rgba(0, 0, 0, 0.05)',
                      opacity: 0.7 
                    }} 
                  />
                  <Box sx={{ flex: 1 }}>
                    <Skeleton variant="text" width="60%" />
                  </Box>
                  <Box sx={{ width: 160, mx: 2 }}>
                    <Skeleton 
                      variant="rectangular" 
                      height={8} 
                      sx={{ 
                        borderRadius: 4,
                        bgcolor: theme => theme.palette.mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.05)' 
                          : 'rgba(0, 0, 0, 0.05)'
                      }}
                    />
                  </Box>
                  <Skeleton variant="text" width={40} />
                </Box>
              ))}
            </Box>
          </StatBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <StatBox sx={{ height: '405px', display: 'flex', flexDirection: 'column' }}>
            <Skeleton variant="text" width={180} height={24} sx={{ mb: 3 }} />
            <Box sx={{ flex: 1 }}>
              <Skeleton 
                variant="rectangular" 
                height="100%" 
                sx={{ 
                  borderRadius: 2,
                  bgcolor: theme => theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.03)'
                    : 'rgba(0, 0, 0, 0.03)'
                }}
              />
            </Box>
          </StatBox>
        </Grid>
      </Grid>

      {/* Заголовок "Блоки" */}
      <Grid item xs={12} sx={{ mt: 4 }}>
        <Skeleton 
          variant="text" 
          width={100} 
          height={32} 
          sx={{ 
            mb: 2,
            bgcolor: theme => theme.palette.mode === 'dark' 
              ? 'rgba(220, 251, 1, 0.1)' 
              : 'rgba(121, 62, 238, 0.1)'
          }}
        />
      </Grid>

      {/* Блоки */}
      <Grid container item xs={12} sx={{ mt: 0 }}>
        <Grid item xs={12}>
          {/* Табы блоков */}
          <Box sx={{ mb: 3 }}>
            <Tabs 
              value={0}
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                  height: '3px',
                  borderRadius: '4px 4px 0 0',
                }
              }}
            >
              {[...Array(5)].map((_, i) => (
                <Tab
                  key={i}
                  label={
                    <Skeleton 
                      variant="text" 
                      width={120} 
                      height={20}
                      sx={{ 
                        bgcolor: theme => theme.palette.mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.05)' 
                          : 'rgba(0, 0, 0, 0.05)'
                      }}
                    />
                  }
                  sx={{ 
                    minWidth: 'auto',
                    padding: '6px 16px',
                  }}
                />
              ))}
            </Tabs>
          </Box>

          {/* Контент блока */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* Первый ряд: Средний балл и Пункты блока */}
            <Box sx={{ display: 'flex', gap: 3 }}>
              {/* Средний балл по блоку */}
              <StatBox sx={{ width: '30%', p: 3 }}>
                <Skeleton variant="text" width={140} height={20} sx={{ mb: 1 }} />
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                  <Skeleton variant="text" width={80} height={60} />
                  <Skeleton variant="text" width={30} height={30} />
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 1 }}>
                    <Skeleton variant="text" width={60} height={20} />
                    <Skeleton variant="text" width={100} height={20} />
                  </Box>
                </Box>
              </StatBox>

              {/* Пункты блока */}
              <StatBox sx={{ flex: 1, p: 3 }}>
                <Skeleton variant="text" width={100} height={20} sx={{ mb: 2 }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {[...Array(2)].map((_, i) => (
                    <Box key={i} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Skeleton variant="text" width={40} />
                      <Box sx={{ flex: 1 }}>
                        <Skeleton variant="text" width="80%" />
                      </Box>
                      <Box sx={{ width: 200 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Box sx={{ flex: 1 }}>
                            <Skeleton 
                              variant="rectangular" 
                              height={8} 
                              sx={{ 
                                borderRadius: 4,
                                bgcolor: theme => theme.palette.mode === 'dark' 
                                  ? 'rgba(255, 255, 255, 0.05)' 
                                  : 'rgba(0, 0, 0, 0.05)'
                              }} 
                            />
                          </Box>
                          <Skeleton variant="text" width={40} />
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </StatBox>
            </Box>

            {/* График */}
            <StatBox sx={{ p: 3 }}>
              <Skeleton variant="text" width={180} height={20} sx={{ mb: 2 }} />
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
                {[...Array(2)].map((_, i) => (
                  <Box key={i} sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: '120px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Skeleton variant="rectangular" width={24} height={2} sx={{ borderRadius: 1 }} />
                      <Skeleton variant="text" width={30} />
                    </Box>
                    <Skeleton variant="text" width={80} />
                  </Box>
                ))}
              </Box>
              <Skeleton 
                variant="rectangular" 
                height={300} 
                sx={{ 
                  borderRadius: 2,
                  bgcolor: theme => theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.03)'
                    : 'rgba(0, 0, 0, 0.03)'
                }}
              />
            </StatBox>

            {/* Нижний блок с показателями */}
            <Box sx={{ display: 'flex', gap: 3 }}>
              {/* Лучшие показатели */}
              <StatBox sx={{ flex: 1, p: 3 }}>
                <Skeleton variant="text" width={140} height={20} sx={{ mb: 2 }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {[...Array(5)].map((_, i) => (
                    <Box key={i} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Skeleton variant="text" width={30} />
                      <Skeleton variant="text" width={200} sx={{ flex: 1 }} />
                      <Box sx={{ width: 200 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Box sx={{ flex: 1 }}>
                            <Skeleton variant="rectangular" height={8} sx={{ borderRadius: 4 }} />
                          </Box>
                          <Skeleton variant="text" width={40} />
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </StatBox>

              {/* Требуют внимания */}
              <StatBox sx={{ flex: 1, p: 3 }}>
                <Skeleton variant="text" width={140} height={20} sx={{ mb: 2 }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {[...Array(5)].map((_, i) => (
                    <Box key={i} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Skeleton variant="text" width={30} />
                      <Skeleton variant="text" width={200} sx={{ flex: 1 }} />
                      <Box sx={{ width: 200 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Box sx={{ flex: 1 }}>
                            <Skeleton variant="rectangular" height={8} sx={{ borderRadius: 4 }} />
                          </Box>
                          <Skeleton variant="text" width={40} />
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </StatBox>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

// Обновляем функцию для получения цвета скелетона
const getSkeletonColor = (theme: Theme) => 
  theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.05)' 
    : 'rgba(0, 0, 0, 0.05)';

export default StatisticsSkeleton; 
import React, { useState, useMemo, useCallback } from 'react';
import { Box, Typography, Tabs, Tab, useTheme, LinearProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { StatBox } from '../../styled';
import { CallEvaluation, ChecklistConfig } from '../../../../../../services/callAnalyticsService';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useFilters } from '../../../context/FiltersContext';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { useTranslation } from 'react-i18next';

interface Props {
  evaluations?: CallEvaluation[];
  checklistConfig?: ChecklistConfig | null;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ChecklistItem {
  id: string;
  name: string;
  order: number;
  weight: number;
}

interface ChecklistBlock {
  id: string;
  name: string;
  order: number;
  items: ChecklistItem[];
}

interface ScoredItem extends ChecklistItem {
  score: number;
}

interface ScoredBlock extends ChecklistBlock {
  score: number;
  items: ScoredItem[];
}

interface StatisticsWithScores {
  evaluated_at: string;
  scores: {
    [key: string]: number;
  };
}

interface TooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
    dataKey: string;
    color: string;
    payload: {
      date: string;
      isWeeklyData?: boolean;
      [key: string]: any;
    };
  }>;
  block: ScoredBlock;
}

interface ChartDataPoint {
  date: string;
  fullDate: Date;
  isWeeklyData?: boolean;
  [key: string]: any;
}

interface TooltipEntry {
  value: number;
  dataKey: string;
  color: string;
}

interface ManagerScore {
  id: string;
  score: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const getBlockColor = (blockId: string, mode: 'light' | 'dark' = 'light') => {
  if (mode === 'dark') {
    switch (blockId) {
      case '01': return '#DCFB01'; // фирменный неоново-желтый
      case '02': return '#36F1CD'; // бирюзовый неон
      case '03': return '#3DD5FF'; // электрический голубой
      case '04': return '#A2FC7C'; // весенний зеленый
      case '05': return '#FFD53D'; // золотистый
      case '06': return '#96F7B3'; // мятный
      case '07': return '#59FFC1'; // аквамариновый
      case '08': return '#00E2B0'; // изумрудный неон
      default: return '#DCFB01';
    }
  } else {
    switch (blockId) {
      case '01': return '#793EEE'; // фирменный фиолетовый
      case '02': return '#2A6B9F'; // глубокий синий
      case '03': return '#00A7B5'; // морской волны
      case '04': return '#348553'; // малахитовый
      case '05': return '#0B877D'; // изумрудный
      case '06': return '#B86E00'; // янтарный
      case '07': return '#236C70'; // бирюзовый
      case '08': return '#1E754C'; // нефритовый
      default: return '#793EEE';
    }
  }
};

const BlocksTabs: React.FC<Props> = ({ evaluations, checklistConfig }) => {
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const { period } = useFilters();
  const { t, i18n } = useTranslation();

  const chartDataByBlock = useMemo(() => {
    if (!evaluations?.length || !checklistConfig?.blocks) return new Map();

    const dataMap = new Map<string, ChartDataPoint[]>();
    const blocksArray = ((checklistConfig?.blocks as any)?.blocks || []) as ChecklistBlock[];
    
    blocksArray.forEach(block => {
      // Группируем данные по датам
      const dailyData = new Map<string, { 
        date: string;
        fullDate: Date;
        scores: Record<string, number[]>;
      }>();

      // Собираем все оценки по датам
      evaluations.forEach(evaluation => {
        const date = new Date(evaluation.evaluated_at);
        const dateKey = date.toISOString().split('T')[0];

        if (!dailyData.has(dateKey)) {
          dailyData.set(dateKey, {
            date: date.toLocaleDateString(i18n.language, { 
              day: '2-digit', 
              month: 'short' 
            }),
            fullDate: date,
            scores: {}
          });
        }

        const dayData = dailyData.get(dateKey)!;
        
        // Собираем оценки для каждого пункта
        block.items?.forEach(item => {
          const itemKey = `item_${item.id.replace('.', '_')}`;
          const score = evaluation.scores?.[item.id.replace('.', '_')] || 0;
          
          if (!dayData.scores[itemKey]) {
            dayData.scores[itemKey] = [];
          }
          dayData.scores[itemKey].push(score);
        });
      });

      // Если данных много, группируем по неделям
      let processedData;
      if (dailyData.size > 180) {
        const weeklyData = new Map<string, {
          date: string;
          fullDate: Date;
          scores: Record<string, number[]>;
        }>();

        // Группируем дневные данные по неделям
        dailyData.forEach((dayData, dateKey) => {
          const date = new Date(dateKey);
          const weekStart = new Date(date);
          weekStart.setDate(date.getDate() - date.getDay() + 1);
          const weekKey = weekStart.toISOString().split('T')[0];

          if (!weeklyData.has(weekKey)) {
            weeklyData.set(weekKey, {
              date: weekStart.toLocaleDateString(i18n.language, { 
                day: '2-digit', 
                month: 'short' 
              }),
              fullDate: weekStart,
              scores: {}
            });
          }

          const weekData = weeklyData.get(weekKey)!;
          
          // Объединяем оценки за неделю
          Object.entries(dayData.scores).forEach(([itemKey, scores]) => {
            if (!weekData.scores[itemKey]) {
              weekData.scores[itemKey] = [];
            }
            weekData.scores[itemKey].push(...scores);
          });
        });

        processedData = Array.from(weeklyData.values());
      } else {
        processedData = Array.from(dailyData.values());
      }

      // Преобразуем в формат для графика
      const chartData = processedData
        .map(({ date, fullDate, scores }) => {
          const result: ChartDataPoint = {
            date,
            fullDate,
            isWeeklyData: dailyData.size > 180,
          };

          // Вычисляем средние значения
          Object.entries(scores).forEach(([itemKey, itemScores]) => {
            result[itemKey] = Math.round(
              itemScores.reduce((sum, score) => sum + score, 0) / itemScores.length * 100
            );
          });

          return result;
        })
        .sort((a, b) => a.fullDate.getTime() - b.fullDate.getTime());

      dataMap.set(block.id, chartData);
    });

    return dataMap;
  }, [evaluations, checklistConfig, i18n.language]);

  const calculateYAxisDomain = useCallback((data: ChartDataPoint[]) => {
    if (!data?.length) return [0, 100];

    const allValues = data.flatMap(item => 
      Object.entries(item)
        .filter(([key]) => key.startsWith('item_'))
        .map(([, value]) => value as number)
    );

    const min = Math.min(...allValues);
    const max = Math.max(...allValues);
    const range = max - min;

    // Для очень высоких значений (95-100%)
    if (min >= 95) return [93, 100];
    
    // Для высоких значений (90-95%)
    if (min >= 90) return [88, 100];
    
    // Для значений 85-90%
    if (min >= 85) return [82, 95];
    
    // Для значений 80-85%
    if (min >= 80) return [75, 90];

    // Для малого разброса значений (до 8%)
    if (range <= 8) {
      const middle = (max + min) / 2;
      return [
        Math.max(0, Math.floor((middle - 6) / 2) * 2), // Округляем до четных чисел
        Math.min(100, Math.ceil((middle + 6) / 2) * 2)
      ];
    }

    // Для среднего разброса значений (8-15%)
    if (range <= 15) {
      const middle = (max + min) / 2;
      return [
        Math.max(0, Math.floor((middle - 8) / 5) * 5),
        Math.min(100, Math.ceil((middle + 8) / 5) * 5)
      ];
    }

    // Для остальных случаев
    return [
      Math.max(0, Math.floor(min - range * 0.1)),  // Отступ 10% от диапазона
      Math.min(100, Math.ceil(max + range * 0.1))
    ];
  }, []);

  const renderTooltip = useCallback(({ active, payload, block }: TooltipProps) => {
    if (!active || !payload?.length) return null;

    const date = payload[0].payload.fullDate as Date;
    const isWeeklyData = payload[0].payload.isWeeklyData;
    
    const dateText = isWeeklyData
      ? `${date.toLocaleDateString(i18n.language, {
          day: '2-digit',
          month: 'short'
        })} - ${new Date(date.getTime() + 6 * 24 * 60 * 60 * 1000).toLocaleDateString(i18n.language, {
          day: '2-digit',
          month: 'short'
        })}`
      : payload[0].payload.date;

    return (
      <Box
        sx={{
          backgroundColor: theme => theme.palette.mode === 'dark' ? '#1E1E1E' : '#FFF',
          border: theme => `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
          p: 1.5,
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
          backdropFilter: 'blur(4px)',
        }}
      >
        <Typography variant="body2" sx={{ mb: 1, fontWeight: 600 }}>
          {dateText}
        </Typography>
        {(payload as TooltipEntry[])
          .sort((a, b) => {
            const aIndex = block.items.findIndex(item => 
              `item_${item.id.replace('.', '_')}` === a.dataKey
            );
            const bIndex = block.items.findIndex(item => 
              `item_${item.id.replace('.', '_')}` === b.dataKey
            );
            return aIndex - bIndex;
          })
          .map((entry, index) => (
            <Box 
              key={entry.dataKey} 
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                mb: 0.5,
                py: 0.5,
                px: 1,
                borderRadius: 1,
                '&:hover': {
                  backgroundColor: theme => theme.palette.mode === 'dark' 
                    ? 'rgba(255, 255, 255, 0.05)' 
                    : 'rgba(0, 0, 0, 0.02)'
                }
              }}
            >
              <Box
                sx={{ 
                  width: 24,
                  height: 2,
                  backgroundColor: entry.color,
                  ...(getLegendLineStyle(index) ? {
                    backgroundImage: `repeating-linear-gradient(to right,
                      ${entry.color} 0,
                      ${entry.color} 6px,
                      transparent 6px,
                      transparent ${getLegendLineStyle(index)?.split(' ')[0]}px)`,
                    backgroundColor: 'transparent'
                  } : {})
                }}
              />
              <Typography 
                variant="body2" 
                sx={{ 
                  fontWeight: 600,
                  color: entry.color,
                }}
              >
                {Math.round(entry.value)}%
              </Typography>
            </Box>
          ))}
      </Box>
    );
  }, [theme.palette.mode, i18n.language]);

  const blocksWithScores = useMemo(() => {
    if (!evaluations?.length || !checklistConfig?.blocks) return [];

    // Собираем статистику по каждому пункту
    const itemStats: Record<string, { sum: number; count: number }> = {};
    
    // Проходим по всем оценкам и собираем суммы и количество
    evaluations.forEach(evaluation => {
      if (!evaluation.scores) return;
      
      Object.entries(evaluation.scores).forEach(([itemId, score]) => {
        // Преобразуем формат ID из "1_01" в "1.01"
        const normalizedId = itemId.replace('_', '.');
        
        if (!itemStats[normalizedId]) {
          itemStats[normalizedId] = { sum: 0, count: 0 };
        }
        itemStats[normalizedId].sum += score;
        itemStats[normalizedId].count += 1;
      });
    });

    const blocksArray = ((checklistConfig?.blocks as any)?.blocks || []) as ChecklistBlock[];
    
    return blocksArray
      .sort((a, b) => a.order - b.order)
      .map((block: ChecklistBlock) => {
        // Считаем баллы по каждому пункту с использованием собранной статистики
        const itemsWithScores = block.items?.map((item: ChecklistItem) => {
          const stats = itemStats[item.id] || { sum: 0, count: 0 };
          const itemAverage = stats.count > 0
            ? Math.round((stats.sum / stats.count) * 100) // Умножаем на 100 для процентов
            : 0;

          return {
            ...item,
            score: itemAverage
          };
        }) || [];

        // Считаем средний балл по блоку на основе оценок пунктов
        const blockAverage = itemsWithScores.length > 0
          ? Math.round(
              itemsWithScores.reduce((sum, item) => sum + item.score, 0) / 
              itemsWithScores.length
            )
          : 0;

        return {
          ...block,
          score: blockAverage,
          items: itemsWithScores
        };
      });
  }, [evaluations, checklistConfig]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // Добавим функцию для расчета изменения
  const calculateChange = useCallback((block: ScoredBlock) => {
    if (!evaluations?.length) return null;

    // Разделим оценки на текущий и предыдущий периоды
    const sortedEvaluations = [...evaluations].sort((a, b) => 
      new Date(b.evaluated_at).getTime() - new Date(a.evaluated_at).getTime()
    );

    const midPoint = Math.floor(sortedEvaluations.length / 2);
    const currentPeriod = sortedEvaluations.slice(0, midPoint);
    const previousPeriod = sortedEvaluations.slice(midPoint);

    // Функция для расчета среднего балла по блоку
    const calculateAverage = (evaluations: CallEvaluation[]) => {
      const scores = evaluations.map(evaluation => {
        const blockScores = block.items.map(item => 
          evaluation.scores?.[item.id.replace('.', '_')] || 0
        );
        return blockScores.reduce((sum, score) => sum + score, 0) / blockScores.length;
      });

      return scores.length 
        ? Math.round((scores.reduce((sum, score) => sum + score, 0) / scores.length) * 100)
        : 0;
    };

    const currentAvg = calculateAverage(currentPeriod);
    const previousAvg = calculateAverage(previousPeriod);

    return {
      value: currentAvg - previousAvg,
      percentage: previousAvg ? Math.round(((currentAvg - previousAvg) / previousAvg) * 100) : 0
    };
  }, [evaluations]);

  // Обновим функцию getColorByStatus
  const getColorByStatus = (change: number, theme: Theme) => {
    if (change === 0) return theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';
    return change > 0 
      ? theme.palette.success?.main || '#4caf50'
      : theme.palette.error?.main || '#f44336';
  };

  // Обновим функцию для подсчета среднего балла менеджера по конкретному блоку
  const calculateManagerBlockScore = (evaluations: CallEvaluation[], managerId: string, block: ScoredBlock) => {
    const managerEvaluations = evaluations.filter(e => e.manager_id === managerId);
    if (!managerEvaluations.length) return 0;

    const totalScores = managerEvaluations.reduce((sum, evaluation) => {
      if (!evaluation.scores) return sum;
      
      // Считаем только оценки для пунктов текущего блока
      const blockScores = block.items.map(item => 
        evaluation.scores?.[item.id.replace('.', '_')] || 0
      );
      
      return sum + (blockScores.reduce((acc, score) => acc + score, 0) / blockScores.length);
    }, 0);

    return Math.round((totalScores / managerEvaluations.length) * 100);
  };

  // Обновим расчет данных для менеджеров, добавив зависимость от блока
  const getManagerScores = (block: ScoredBlock) => {
    if (!evaluations?.length) return [];

    const uniqueManagerIds = Array.from(new Set(evaluations.map(e => e.manager_id)));
    
    return uniqueManagerIds
      .map(managerId => ({
        id: managerId,
        score: calculateManagerBlockScore(evaluations, managerId, block)
      }))
      .sort((a, b) => b.score - a.score);
  };

  // Стили для контейнера блока менеджеров
  const managerBlockStyles = {
    display: 'flex', 
    flexDirection: 'column',
    gap: 2,
    minHeight: '220px', // Фиксированная минимальная высота для обоих блоков
  } as const;

  // Стили для карточки менеджера
  const managerCardStyles = (theme: Theme) => ({
    display: 'flex', 
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2,
    borderRadius: '12px',
    bgcolor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.03)' 
      : 'rgba(0, 0, 0, 0.02)',
    height: '56px', // Фиксированная высота для каждой карточки
  });

  // Стили для сообщения "Отлично!"
  const excellentMessageStyles = (theme: Theme) => ({
    ...managerCardStyles(theme),
    height: '168px', // Высота трех карточек
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  });

  // Добавим функцию для получения стиля линии в зависимости от индекса
  const getLineStyle = (index: number) => {
    switch (index) {
      case 0:
        return undefined; // Сплошная линия для первого пункта
      case 1:
        return '5 5'; // Пунктирная линия
      case 2:
        return '10 5'; // Длинный пунктир
      case 3:
        return '2 5'; // Короткий пунктир
      default:
        return '5 5 2 5'; // Комбинированная линия для остальных
    }
  };

  // Обновляем стили для линий в легенде
  const getLegendLineStyle = (index: number) => {
    switch (index) {
      case 0:
        return undefined; // Сплошная линия для первого пункта
      case 1:
        return '16'; // Увеличенный пунктир
      case 2:
        return '24'; // Увеличенный длинный пунктир
      case 3:
        return '12'; // Увеличенный короткий пунктир
      default:
        return '16 12'; // Увеличенная комбинированная линия
    }
  };

  // Обновим стили для общего контейнера
  const managerListContainerStyles = (theme: Theme) => ({
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.03)' 
      : 'rgba(0, 0, 0, 0.02)',
    borderRadius: '12px',
    padding: 2,
    height: '168px', // Фиксированная высота для обоих контейнеров
  });

  // Обновим стили для строки менеджера
  const managerRowStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '48px', // Высота строки
    px: 1, // Небольшой отступ слева и справа
    '&:hover': {
      backgroundColor: (theme: Theme) => theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.03)' 
        : 'rgba(0, 0, 0, 0.02)',
      borderRadius: '8px'
    }
  };

  // Стили для контейнера списка менеджеров
  const managerListStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    minHeight: '168px', // Сохраняем фиксированную высоту
  };

  // Добавим функцию для получения текста периода
  const getPeriodText = () => {
    switch (period) {
      case 'today':
        return t('callAnalytics.callsCount.periods.yesterday');
      case 'week':
        return t('callAnalytics.callsCount.periods.lastWeek');
      case 'month':
        return t('callAnalytics.callsCount.periods.lastMonth');
      case 'quarter':
        return t('callAnalytics.callsCount.periods.lastQuarter');
      case 'year':
        return t('callAnalytics.callsCount.periods.lastYear');
      case 'custom':
        return t('callAnalytics.callsCount.periods.lastPeriod');
      default:
        return t('callAnalytics.callsCount.periods.lastPeriod');
    }
  };

  // Обновляем функцию для получения топ менеджеров
  const getTopManagers = (block: ScoredBlock): ManagerScore[] => {
    const managerScores = getManagerScores(block);
    return managerScores
      .slice(0, 5); // Берем только топ-5 менеджеров
  };

  // Обновляем функцию для получения менеджеров, требующих внимания
  const getManagersNeedingAttention = (block: ScoredBlock): ManagerScore[] => {
    const managerScores = getManagerScores(block);
    return managerScores
      .slice(-5) // Берем 5 худших результатов
      .reverse(); // Разворачиваем массив, чтобы показать от худшего к лучшему
  };

  if (!blocksWithScores.length) return null;

  return (
    <>
      {/* Табы без контейнера */}
      <Box sx={{ mb: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          sx={{
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 500,
              minWidth: 'auto',
              padding: '6px 16px',
              color: 'text.secondary',
              '&:hover': {
                color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
              },
              '&.Mui-selected': {
                color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
              },
            },
            '& .MuiTabs-indicator': {
              backgroundColor: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
              height: '3px',
              borderRadius: '4px 4px 0 0',
            }
          }}
        >
          {blocksWithScores.map((block: ScoredBlock, index: number) => (
            <Tab 
              key={block.id}
              label={`${index + 1}. ${block.name}`}
              disableRipple
            />
          ))}
        </Tabs>
      </Box>

      {blocksWithScores.map((block: ScoredBlock, index: number) => (
        <Box
          key={block.id}
          sx={{
            display: activeTab === index ? 'flex' : 'none',
            flexDirection: 'column',
            gap: 3
          }}
        >
          {/* Первый ряд: два отдельных контейнера */}
          <Box sx={{ display: 'flex', gap: 3 }}>
            {/* Средний балл по блоку в отдельном контейнере */}
            <StatBox sx={{ width: '30%', p: 3 }}>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                {t('callAnalytics.blocksTabs.blockScore')}
              </Typography>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                  <Typography 
                    variant="h2" 
                    sx={{ 
                      fontWeight: 700,
                      lineHeight: 1,
                      color: theme => theme.palette.mode === 'dark' 
                        ? '#fff' 
                        : '#000'
                    }}
                  >
                    {block.score}
                  </Typography>
                  <Typography 
                    sx={{ 
                      color: theme => theme.palette.mode === 'dark' 
                        ? 'rgba(255, 255, 255, 0.7)' 
                        : 'rgba(0, 0, 0, 0.6)',
                      fontWeight: 500,
                      fontSize: '1.25rem'
                    }}
                  >
                    %
                  </Typography>
                  {(() => {
                    const change = calculateChange(block);
                    if (!change) return null;

                    return (
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: 0.5,
                          ml: 1,
                          mt: 0.5,
                        }}
                      >
                        {change.value === 0 ? (
                          <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary' }}
                          >
                            {t('callAnalytics.blocksTabs.noChange')}
                          </Typography>
                        ) : (
                          <>
                            <Box sx={{ 
                              display: 'flex',
                              alignItems: 'center',
                              color: theme => getColorByStatus(change.value, theme),
                            }}>
                              {change.value > 0 ? (
                                <TrendingUpIcon sx={{ fontSize: 16 }} />
                              ) : (
                                <TrendingDownIcon sx={{ fontSize: 16 }} />
                              )}
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 500 }}
                              >
                                {Math.abs(change.value)}%
                              </Typography>
                            </Box>
                            <Typography
                              variant="body1"
                              sx={{ color: 'text.secondary' }}
                            >
                              vs {getPeriodText()}
                            </Typography>
                          </>
                        )}
                      </Box>
                    );
                  })()}
                </Box>
              </Box>
            </StatBox>

            {/* Пункты блока в отдельном контейнере */}
            <StatBox sx={{ flex: 1, p: 3 }}>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                {t('callAnalytics.blocksTabs.blockItems')}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {block.items?.map((item: ScoredItem, index) => (
                  <Box 
                    key={item.id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        color: getBlockColor(block.id, theme.palette.mode),
                        fontWeight: 600,
                        minWidth: '45px'
                      }}
                    >
                      {item.id}
                    </Typography>

                    <Typography sx={{ flex: 1, color: 'text.primary' }}>
                      {item.name}
                    </Typography>

                    <Box sx={{ flex: 1, maxWidth: '200px' }}>
                      <LinearProgress
                        variant="determinate"
                        value={item.score}
                        sx={{
                          height: 8,
                          borderRadius: 4,
                          backgroundColor: theme => theme.palette.mode === 'dark'
                            ? 'rgba(255, 255, 255, 0.1)'
                            : 'rgba(0, 0, 0, 0.1)',
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: getBlockColor(block.id, theme.palette.mode),
                            borderRadius: 4,
                          }
                        }}
                      />
                    </Box>

                    <Typography
                      sx={{
                        fontWeight: 600,
                        // Меняем цвет с цветного на обычный текст
                        color: theme => theme.palette.mode === 'dark' 
                          ? '#fff' 
                          : '#000',
                        minWidth: '45px',
                        textAlign: 'right'
                      }}
                    >
                      {item.score}%
                    </Typography>
                  </Box>
                ))}
              </Box>
            </StatBox>
          </Box>

          {/* Второй ряд: График в отдельном контейнере */}
          <StatBox sx={{ p: 3 }}>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              {t('callAnalytics.blocksTabs.blockDynamics')}
            </Typography>
            {/* Легенда */}
            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap',
              gap: 2,
              mt: 2
            }}>
              {block.items.map((item, index) => (
                <Box 
                  key={item.id}
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 1,
                    minWidth: '120px'
                  }}
                >
                  <Box 
                    component="span"
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    <Box
                      sx={{ 
                        width: 24,
                        height: 2,
                        ...(getLegendLineStyle(index) ? {
                          backgroundImage: `repeating-linear-gradient(to right,
                            ${getBlockColor(block.id, theme.palette.mode)} 0,
                            ${getBlockColor(block.id, theme.palette.mode)} 6px,
                            transparent 6px,
                            transparent ${getLegendLineStyle(index)?.split(' ')[0]}px)`,
                          backgroundColor: 'transparent'
                        } : {
                          backgroundColor: getBlockColor(block.id, theme.palette.mode)
                        }),
                        borderRadius: 1
                      }}
                    />
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        color: getBlockColor(block.id, theme.palette.mode),
                        fontWeight: 500
                      }}
                    >
                      {item.id}
                    </Typography>
                  </Box>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      color: theme.palette.mode === 'dark' 
                        ? 'rgba(255, 255, 255, 0.7)' 
                        : 'rgba(0, 0, 0, 0.6)',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              ))}
            </Box>
            {/* График */}
            <Box sx={{ height: '300px', mt: 2 }}>
              {period === 'today' ? (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ textAlign: 'center' }}
                >
                  {t('callAnalytics.blocksTabs.noChart')}
                </Typography>
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart 
                    data={chartDataByBlock.get(block.id)}
                    margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
                  >
                    <defs>
                      {block.items.map((item) => (
                        <linearGradient key={item.id} id={`color${item.id}`} x1="0" y1="0" x2="0" y2="1">
                          <stop 
                            offset="5%" 
                            stopColor={getBlockColor(block.id, theme.palette.mode)} 
                            stopOpacity={0.1}
                          />
                          <stop 
                            offset="95%" 
                            stopColor={getBlockColor(block.id, theme.palette.mode)} 
                            stopOpacity={0}
                          />
                        </linearGradient>
                      ))}
                    </defs>
                    <CartesianGrid 
                      strokeDasharray="3 3" 
                      vertical={false}
                      stroke={theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}
                    />
                    <XAxis 
                      dataKey="date"
                      stroke={theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'}
                      tick={{ fontSize: 12 }}
                      axisLine={false}
                      tickLine={false}
                      dy={8}
                    />
                    <YAxis 
                      domain={calculateYAxisDomain(chartDataByBlock.get(block.id) || [])}
                      stroke={theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'}
                      tick={{ fontSize: 12 }}
                      tickFormatter={(value) => `${value}%`}
                      axisLine={false}
                      tickLine={false}
                      dx={-8}
                    />
                    <Tooltip 
                      content={(props: any) => renderTooltip({ 
                        active: props.active,
                        payload: props.payload,
                        block 
                      })} 
                    />
                    {block.items.map((item, index) => (
                      <Area
                        key={item.id}
                        type="monotone"
                        dataKey={`item_${item.id.replace('.', '_')}`}
                        name={item.name}
                        stroke={getBlockColor(block.id, theme.palette.mode)}
                        fill={`url(#color${item.id})`}
                        strokeWidth={2}
                        strokeDasharray={getLineStyle(index)}
                        dot={false}
                        activeDot={{
                          stroke: getBlockColor(block.id, theme.palette.mode),
                          strokeWidth: 2,
                          r: 4,
                          fill: theme.palette.mode === 'dark' ? '#1E1E1E' : '#FFF'
                        }}
                      />
                    ))}
                  </AreaChart>
                </ResponsiveContainer>
              )}
            </Box>
          </StatBox>

          {/* Нижний блок с показателями */}
          <Box sx={{ display: 'flex', gap: 3 }}>
            {/* Лучшие показатели */}
            <StatBox sx={{ flex: 1, p: 3 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 3 }}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('callAnalytics.blocksTabs.bestResults')}
                </Typography>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {(() => {
                    const topManagers = getTopManagers(block);
                    return topManagers.length > 0 ? (
                      topManagers.map((manager: ManagerScore, index: number) => (
                        <Box key={manager.id}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography
                              sx={{
                                color: getBlockColor(block.id, theme.palette.mode),
                                fontWeight: 600,
                                minWidth: '30px',
                                textAlign: 'center'
                              }}
                            >
                              #{index + 1}
                            </Typography>
                            <Typography
                              color="text.secondary"
                              sx={{
                                flex: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {manager.id}
                            </Typography>
                            <Box sx={{ width: '200px' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Box sx={{ flex: 1 }}>
                                  <Box
                                    sx={{
                                      width: '100%',
                                      height: 8,
                                      bgcolor: theme => theme.palette.mode === 'dark' 
                                        ? 'rgba(255, 255, 255, 0.1)' 
                                        : 'rgba(0, 0, 0, 0.1)',
                                      borderRadius: 4,
                                      overflow: 'hidden',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: `${manager.score}%`,
                                        height: '100%',
                                        bgcolor: getBlockColor(block.id, theme.palette.mode),
                                        borderRadius: 4,
                                        transition: 'width 0.3s ease',
                                      }}
                                    />
                                  </Box>
                                </Box>
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    minWidth: '45px',
                                    textAlign: 'right',
                                    color: getBlockColor(block.id, theme.palette.mode),
                                  }}
                                >
                                  {manager.score}%
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Box sx={{ 
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'center',
                        p: 2
                      }}>
                        <Typography variant="body1" color="text.secondary">
                          {t('callAnalytics.blocksTabs.noData')}
                        </Typography>
                      </Box>
                    );
                  })()}
                </Box>
              </Box>
            </StatBox>

            {/* Требуют внимания */}
            <StatBox sx={{ flex: 1, p: 3 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 3 }}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('callAnalytics.blocksTabs.needAttention')}
                </Typography>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {(() => {
                    const needAttentionManagers = getManagersNeedingAttention(block);
                    return needAttentionManagers.length > 0 ? (
                      needAttentionManagers.map((manager: ManagerScore, index: number) => (
                        <Box key={manager.id}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography
                              sx={{
                                color: getBlockColor(block.id, theme.palette.mode),
                                fontWeight: 600,
                                minWidth: '30px',
                                textAlign: 'center'
                              }}
                            >
                              #{index + 1}
                            </Typography>
                            <Typography
                              color="text.secondary"
                              sx={{
                                flex: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {manager.id}
                            </Typography>
                            <Box sx={{ width: '200px' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Box sx={{ flex: 1 }}>
                                  <Box
                                    sx={{
                                      width: '100%',
                                      height: 8,
                                      bgcolor: theme => theme.palette.mode === 'dark' 
                                        ? 'rgba(255, 255, 255, 0.1)' 
                                        : 'rgba(0, 0, 0, 0.1)',
                                      borderRadius: 4,
                                      overflow: 'hidden',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: `${manager.score}%`,
                                        height: '100%',
                                        bgcolor: getBlockColor(block.id, theme.palette.mode),
                                        borderRadius: 4,
                                        transition: 'width 0.3s ease',
                                      }}
                                    />
                                  </Box>
                                </Box>
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    minWidth: '45px',
                                    textAlign: 'right',
                                    color: getBlockColor(block.id, theme.palette.mode),
                                  }}
                                >
                                  {manager.score}%
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Box sx={{ 
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'center',
                        p: 2
                      }}>
                        <Typography variant="body1" color="success.main" sx={{ fontWeight: 500, mb: 1 }}>
                          {t('callAnalytics.blocksTabs.excellent')}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {t('callAnalytics.blocksTabs.allGood')}
                        </Typography>
                      </Box>
                    );
                  })()}
                </Box>
              </Box>
            </StatBox>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default BlocksTabs; 
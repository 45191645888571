import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useFilters } from '../../context/FiltersContext';
import callAnalyticsService, { 
  CallEvaluation,
  ChecklistConfig 
} from '../../../../../services/callAnalyticsService';
import ClientsBarChart from './Statistics/ClientsBarChart';
import ClientsFlowChart from './Statistics/ClientsFlowChart';
import ClientsTable from './Statistics/ClientsTable';
import ClientsTabSkeleton from './Statistics/ClientsTabSkeleton';

const ClientsTab: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [evaluations, setEvaluations] = useState<CallEvaluation[]>([]);
  const [checklistConfig, setChecklistConfig] = useState<ChecklistConfig | null>(null);
  const { startDate, endDate, managers, departments } = useFilters();
  const [selectedClientId, setSelectedClientId] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      if (!startDate || !endDate) return;

      try {
        setLoading(true);
        setError(null);

        const [evalsData, configData] = await Promise.all([
          callAnalyticsService.getCallEvaluations(
            startDate.format('YYYY-MM-DD'),
            endDate.format('YYYY-MM-DD'),
            managers.includes('all') ? undefined : managers[0]
          ),
          callAnalyticsService.getChecklistConfig()
        ]);

        setEvaluations(evalsData);
        setChecklistConfig(configData);
      } catch (err) {
        console.error('Error fetching clients data:', err);
        setError(err instanceof Error ? err.message : 'Произошла ошибка при загрузке данных');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, managers, departments]);

  useEffect(() => {
    if (evaluations?.length && !selectedClientId) {
      // Выбираем случайного клиента при первой загрузке
      const randomEval = evaluations[Math.floor(Math.random() * evaluations.length)];
      setSelectedClientId(randomEval.client_id);
    }
  }, [evaluations, selectedClientId]);

  // Добавляем обработчик выбора клиента
  const handleClientSelect = (clientId: string) => {
    setSelectedClientId(clientId);
  };

  if (loading) {
    return <ClientsTabSkeleton />;
  }

  if (error) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '300px',
        color: 'error.main'
      }}>
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography 
          variant="h5" 
          sx={{ 
            mb: 1,
            fontWeight: 600,
            color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
          }}
        >
          Статистика по клиентам
        </Typography>
      </Grid>

      {/* График с топ клиентов */}
      <Grid item xs={12} md={6}>
        <ClientsBarChart 
          evaluations={evaluations?.length ? evaluations : undefined}
        />
      </Grid>

      {/* Диаграмма потока клиентов */}
      <Grid item xs={12} md={6}>
        <ClientsFlowChart 
          evaluations={evaluations?.length ? evaluations : undefined}
          selectedClientId={selectedClientId}
          onClientSelect={handleClientSelect}
        />
      </Grid>

      {/* Добавляем таблицу клиентов */}
      <Grid item xs={12}>
        <ClientsTable 
          evaluations={evaluations?.length ? evaluations : undefined}
        />
      </Grid>
    </Grid>
  );
};

export default ClientsTab; 
import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import { StatBox } from '../../../components/styled';

const ManagersTabSkeleton: React.FC = () => {
  return (
    <Grid container spacing={3}>
      {/* Заголовок */}
      <Grid item xs={12}>
        <Skeleton 
          variant="text" 
          width={220} 
          height={32}
          sx={{ mb: 1 }} 
        />
      </Grid>

      {/* Скелетон для BarChart */}
      <Grid item xs={12} md={6}>
        <StatBox sx={{ p: 3, height: '100%', minHeight: '300px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 3 }}>
            {/* Статистика */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box sx={{ p: 2, borderRadius: 2, bgcolor: 'rgba(255, 255, 255, 0.03)' }}>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                      <Skeleton variant="text" width={80} height={40} />
                      <Skeleton variant="text" width={120} height={24} />
                    </Box>
                    <Skeleton variant="text" width={60} height={20} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ p: 2, borderRadius: 2, bgcolor: 'rgba(255, 255, 255, 0.03)' }}>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                      <Skeleton variant="text" width={80} height={40} />
                      <Skeleton variant="text" width={120} height={24} />
                    </Box>
                    <Skeleton variant="text" width={60} height={20} />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* Список менеджеров */}
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Skeleton variant="text" width={140} height={24} />
              {[...Array(5)].map((_, index) => (
                <Box key={index}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                    <Skeleton variant="text" width={30} height={24} />
                    <Skeleton variant="text" width={300} height={24} />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Skeleton variant="text" width={100} height={20} />
                    <Skeleton variant="text" width={40} height={20} />
                  </Box>
                  <Skeleton variant="rectangular" height={8} sx={{ borderRadius: 4 }} />
                </Box>
              ))}
            </Box>
          </Box>
        </StatBox>
      </Grid>

      {/* Скелетон для RadarChart */}
      <Grid item xs={12} md={6}>
        <StatBox sx={{ p: 3, height: '100%', minHeight: '300px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Skeleton variant="text" width={160} height={24} />
              <Skeleton variant="text" width={180} height={36} sx={{ borderRadius: 2 }} />
            </Box>
            <Box sx={{ flex: 1, position: 'relative', minHeight: '250px' }}>
              <Skeleton 
                variant="circular" 
                width={250} 
                height={250} 
                sx={{ 
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }} 
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, mt: 3, mb: 1 }}>
              {[...Array(2)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                  <Skeleton variant="circular" width={10} height={10} />
                  <Skeleton variant="text" width={300} height={20} />
                </Box>
              ))}
            </Box>
          </Box>
        </StatBox>
      </Grid>

      {/* Скелетон для таблицы */}
      <Grid item xs={12}>
        <StatBox>
          {/* Расшифровка пунктов */}
          <Box sx={{ mb: 4 }}>
            <Skeleton variant="text" width={200} height={24} sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              {[...Array(3)].map((_, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Box sx={{ p: 2, borderRadius: 2, bgcolor: 'rgba(255, 255, 255, 0.03)' }}>
                    <Skeleton variant="text" width={160} height={24} sx={{ mb: 1 }} />
                    {[...Array(2)].map((_, i) => (
                      <Box key={i} sx={{ display: 'flex', gap: 2, mb: 1 }}>
                        <Skeleton variant="text" width={40} height={20} />
                        <Skeleton variant="text" width="100%" height={20} />
                      </Box>
                    ))}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Поиск и период */}
          <Box sx={{ mb: 3 }}>
            <Skeleton variant="text" width={180} height={24} sx={{ mb: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Skeleton variant="rectangular" width={300} height={36} sx={{ borderRadius: 2 }} />
                <Skeleton variant="rectangular" width={200} height={36} sx={{ borderRadius: 2 }} />
              </Box>
              <Skeleton variant="rectangular" width={140} height={36} sx={{ borderRadius: 2 }} />
            </Box>
          </Box>

          {/* Таблица */}
          <Box>
            {/* Заголовки */}
            <Skeleton variant="rectangular" height={100} sx={{ borderRadius: '12px 12px 0 0', mb: 1 }} />
            
            {/* Строки */}
            {[...Array(8)].map((_, index) => (
              <Skeleton 
                key={index}
                variant="rectangular" 
                height={48} 
                sx={{ 
                  borderRadius: 1,
                  mb: 0.5,
                  opacity: 1 - index * 0.1
                }} 
              />
            ))}
          </Box>
        </StatBox>
      </Grid>
    </Grid>
  );
};

export default ManagersTabSkeleton; 
import { GlobalStyles as MuiGlobalStyles } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { keyframes } from '@mui/material/styles';
import LogoWhite from '../assets/images/logo_white.png';
import LogoBlack from '../assets/images/logo_black.png';

export const rotateAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const logoBackgroundStyles = (theme: any, isDark: boolean) => ({
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '800px',
    height: '800px',
    backgroundImage: isDark 
      ? `url(${LogoWhite})`
      : `url(${LogoBlack})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transform: 'translate(-50%, -50%)',
    opacity: isDark ? 0.02 : 0.03,
    animation: `${rotateAnimation} 60s linear infinite`,
    animationPlayState: 'running',
  },
});
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { productService, ClientProduct } from '../../services/productService';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Chip,
  Divider,
  IconButton,
  Tooltip,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Call as CallIcon,
  Chat as ChatIcon,
  Bolt as BoltIcon,
  Analytics as AnalyticsIcon,
  Psychology as PsychologyIcon,
  Image as ImageIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import CallImage from '../../assets/images/call.png';
import SmmImage from '../../assets/images/smm.png';
import PredictImage from '../../assets/images/predict.png';
import BotImage from '../../assets/images/bot.png';

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: theme.palette.mode === 'dark' 
    ? 'rgba(30, 30, 30, 0.85)'
    : 'rgba(255, 255, 255, 0.85)',
  backdropFilter: 'blur(20px)',
  border: 'none',
  borderRadius: '24px',
  height: '100%',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 24px rgba(0, 0, 0, 0.4)'
    : '0 4px 24px rgba(0, 0, 0, 0.05)',
  overflow: 'hidden',
}));

const ProductImage = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '200px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.03)'
    : 'rgba(121, 62, 238, 0.03)',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.05) 100%)',
  }
}));

const ProductIcon = styled(Box)(({ theme }) => ({
  width: 60,
  height: 60,
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(220, 251, 1, 0.15)'
    : 'rgba(121, 62, 238, 0.8)',
  backdropFilter: 'blur(8px)',
  color: theme.palette.mode === 'dark'
    ? '#DCFB01'
    : '#FFFFFF',
  margin: '16px',
  position: 'relative',
  zIndex: 2,
  border: `1px solid ${theme.palette.mode === 'dark' 
    ? 'rgba(220, 251, 1, 0.1)'
    : 'rgba(121, 62, 238, 0.2)'}`,
  '& svg': {
    fontSize: '30px',
    width: '30px',
    height: '30px',
    filter: theme.palette.mode === 'dark'
      ? 'none'
      : 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2))',
  },
}));

const StatusChip = styled(Chip)<{ status: string }>(({ theme }) => ({
  position: 'absolute',
  top: 16,
  right: 16,
  zIndex: 1,
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(220, 251, 1, 0.3)'
    : 'rgba(121, 62, 238, 0.3)',
  color: theme.palette.mode === 'dark' 
    ? '#DCFB01'
    : '#793EEE',
  fontWeight: 700,
  fontSize: '0.7rem',
  backdropFilter: 'blur(12px)',
  border: `2px solid ${theme.palette.mode === 'dark' 
    ? 'rgba(220, 251, 1, 0.5)'
    : 'rgba(121, 62, 238, 0.5)'}`,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 2px 12px rgba(220, 251, 1, 0.25)'
    : '0 2px 12px rgba(121, 62, 238, 0.25)',
  padding: '4px 12px',
  height: 24,
  '& .MuiChip-label': {
    padding: 0,
    letterSpacing: '1px',
  },
}));

const ProductSkeleton: React.FC = () => (
  <Grid 
    item 
    xs={12}
    lg={6}
    xl={4}
    sx={{
      width: '100%',
      maxWidth: '580px',
    }}
  >
    <StyledPaper>
      <ProductImage>
        <Skeleton 
          variant="rectangular" 
          width="100%" 
          height="100%" 
          animation="wave"
          sx={{ position: 'absolute' }}
        />
        <ProductIcon>
          <Skeleton 
            variant="circular" 
            width={30} 
            height={30} 
            animation="wave"
          />
        </ProductIcon>
      </ProductImage>
      <Box sx={{ p: 3 }}>
        <Skeleton 
          variant="text" 
          width="60%" 
          height={32} 
          animation="wave" 
          sx={{ mb: 2 }}
        />
        <Skeleton 
          variant="text" 
          width="100%" 
          height={60} 
          animation="wave" 
          sx={{ mb: 2 }}
        />
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Skeleton variant="rounded" width={60} height={24} animation="wave" />
            <Skeleton variant="rounded" width={60} height={24} animation="wave" />
          </Box>
          <Skeleton variant="circular" width={40} height={40} animation="wave" />
        </Box>
      </Box>
    </StyledPaper>
  </Grid>
);

// Добавляем новый компонент для изображения с плавной загрузкой
const ProductImageWithFade: React.FC<{ src: string; alt: string }> = ({ src, alt }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setIsLoaded(true);
    img.onerror = () => setError(true);
  }, [src]);

  return (
    <Box
      component="img"
      src={src}
      alt={alt}
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        opacity: isLoaded ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
        backgroundColor: theme => theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.03)'
          : 'rgba(121, 62, 238, 0.03)',
      }}
      onLoad={() => setIsLoaded(true)}
      onError={() => setError(true)}
    />
  );
};

const Products: React.FC = () => {
  const [products, setProducts] = useState<ClientProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log('🔄 Starting products fetch...');
        const data = await productService.getProducts();
        console.log('📦 Received products:', data);
        
        // Проверим структуру каждого продукта перед установкой
        const validProducts = data.map(product => {
          console.log('🔍 Validating product:', product);
          if (!product.id || !product.name) {
            console.warn('⚠️ Invalid product structure:', product);
          }
          return product;
        });

        console.log('✅ Setting products state:', validProducts);
        setProducts(validProducts);
      } catch (error) {
        console.error('❌ Failed to fetch products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleProductClick = async (product: ClientProduct) => {
    console.log('🖱️ Product clicked:', product);
    if (product.status === 'coming') return;
    
    try {
      if (!product.has_access) {
        console.log('📝 Subscribing to product:', product.id);
        await productService.subscribeToProduct(product.id);
      }
      console.log('🔄 Navigating to product:', product.id);
      navigate(`/products/${product.id}`);
    } catch (error) {
      console.error('❌ Failed to handle product click:', error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
        <Grid 
          container 
          spacing={3} 
          sx={{ 
            maxWidth: '1920px',
            width: '100%',
            margin: 0,
            justifyContent: 'flex-start',
          }}
        >
          {[...Array(2)].map((_, index) => (
            <ProductSkeleton key={index} />
          ))}
        </Grid>
      </Box>
    );
  }

  console.log('🎨 Rendering products:', products);
  
  return (
    <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
      <Grid 
        container 
        spacing={3} 
        sx={{ 
          maxWidth: '1920px',
          width: '100%',
          margin: 0,
          justifyContent: 'flex-start',
        }}
      >
        {products.map((product) => {
          console.log('🎯 Rendering product:', product);
          return (
            <Grid 
              item 
              xs={12}
              lg={6}
              xl={4}
              key={product.id}
              sx={{
                width: '100%',
                maxWidth: '580px',
              }}
            >
              <StyledPaper>
                <ProductImage>
                  <ProductImageWithFade 
                    src={getProductImage(product)} 
                    alt={product.name} 
                  />
                  <ProductIcon>
                    {getProductIcon(product)}
                  </ProductIcon>
                </ProductImage>
                <Box sx={{ p: 3 }}>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      fontWeight: 600, 
                      mb: 2,
                      height: 32,
                      display: '-webkit-box',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      color: theme => theme.palette.mode === 'dark'
                        ? '#DCFB01'
                        : '#793EEE',
                      fontSize: '1.25rem',
                      letterSpacing: '0.5px',
                      transition: 'color 0.2s ease-in-out',
                      '&:hover': {
                        color: theme => theme.palette.mode === 'dark'
                          ? '#e4ff41'
                          : '#9355ff',
                      }
                    }}
                  >
                    {product.name}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                      mb: 2,
                      height: 60,
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      lineHeight: '20px',
                    }}
                  >
                    {product.description}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    height: 40,
                  }}>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'nowrap', overflow: 'hidden' }}>
                      {Array.isArray(product.features) && product.features.map((feature) => (
                        <Chip
                          key={feature.id}
                          label={feature.name}
                          size="small"
                          sx={{
                            height: 24,
                            borderRadius: '8px',
                            backgroundColor: theme => theme.palette.mode === 'dark'
                              ? 'rgba(255, 255, 255, 0.05)'
                              : 'rgba(0, 0, 0, 0.05)',
                            flexShrink: 0,
                          }}
                        />
                      ))}
                    </Box>
                    {product.status === 'soon' ? (
                      <Typography
                        variant="caption"
                        sx={{
                          color: theme => theme.palette.mode === 'dark'
                            ? '#DCFB01'
                            : '#793EEE',
                          fontWeight: 600,
                          fontSize: '0.875rem',
                          letterSpacing: '0.5px',
                          padding: '8px 16px',
                          backgroundColor: theme => theme.palette.mode === 'dark'
                            ? 'rgba(220, 251, 1, 0.1)'
                            : 'rgba(121, 62, 238, 0.1)',
                          borderRadius: '12px',
                          display: 'flex',
                          alignItems: 'center',
                          height: 40,
                          border: theme => `1px solid ${
                            theme.palette.mode === 'dark'
                              ? 'rgba(220, 251, 1, 0.2)'
                              : 'rgba(121, 62, 238, 0.2)'
                          }`,
                          backdropFilter: 'blur(8px)',
                          transition: 'all 0.2s ease-in-out',
                          '&:hover': {
                            backgroundColor: theme => theme.palette.mode === 'dark'
                              ? 'rgba(220, 251, 1, 0.15)'
                              : 'rgba(121, 62, 238, 0.15)',
                            border: theme => `1px solid ${
                              theme.palette.mode === 'dark'
                                ? 'rgba(220, 251, 1, 0.3)'
                                : 'rgba(121, 62, 238, 0.3)'
                            }`,
                          }
                        }}
                      >
                        Скоро
                      </Typography>
                    ) : (
                      product.has_access ? (
                        <IconButton 
                          color="primary"
                          onClick={() => handleProductClick(product)}
                          sx={{ 
                            flexShrink: 0,
                            width: 40,
                            height: 40,
                            backgroundColor: theme => theme.palette.mode === 'dark'
                              ? 'rgba(220, 251, 1, 0.1)'
                              : 'rgba(121, 62, 238, 0.1)',
                            color: theme => theme.palette.mode === 'dark'
                              ? '#DCFB01'
                              : '#793EEE',
                            '&:hover': {
                              backgroundColor: theme => theme.palette.mode === 'dark'
                                ? 'rgba(220, 251, 1, 0.2)'
                                : 'rgba(121, 62, 238, 0.2)',
                            },
                            '&.Mui-disabled': {
                              backgroundColor: 'rgba(255, 255, 255, 0.05)',
                              color: 'rgba(255, 255, 255, 0.3)',
                            }
                          }}
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                      ) : (
                        <Tooltip title="Подписаться на продукт">
                          <IconButton 
                            color="primary"
                            onClick={() => handleProductClick(product)}
                            sx={{ 
                              flexShrink: 0,
                              width: 40,
                              height: 40,
                              backgroundColor: theme => theme.palette.mode === 'dark'
                                ? 'rgba(220, 251, 1, 0.1)'
                                : 'rgba(121, 62, 238, 0.1)',
                              color: theme => theme.palette.mode === 'dark'
                                ? '#DCFB01'
                                : '#793EEE',
                              '&:hover': {
                                backgroundColor: theme => theme.palette.mode === 'dark'
                                  ? 'rgba(220, 251, 1, 0.2)'
                                  : 'rgba(121, 62, 238, 0.2)',
                              },
                              '&.Mui-disabled': {
                                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                color: 'rgba(255, 255, 255, 0.3)',
                              }
                            }}
                          >
                            <ArrowForwardIcon />
                          </IconButton>
                        </Tooltip>
                      )
                    )}
                  </Box>
                </Box>
              </StyledPaper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

// Вспомогательные функции
const getProductImage = (product: ClientProduct) => {
  const imageUrl = product.image_url || getFallbackImage(product.id);
  
  // Предварительная загрузка изображения
  if (imageUrl) {
    const img = new Image();
    img.src = imageUrl;
  }
  
  return imageUrl;
};

const getFallbackImage = (productId: string) => {
  switch (productId) {
    case 'calls-analyzer': return CallImage;
    case 'smm-assistant': return SmmImage;
    case 'sales-predictor': return PredictImage;
    case 'quick-responder': return BotImage;
    default: return '';
  }
};

const getProductIcon = (product: ClientProduct) => {
  console.log('Getting icon for product:', product);

  if (product.icon_type) {
    console.log('Using icon_type:', product.icon_type);
    switch (product.icon_type.toLowerCase()) {
      case 'callicon': return <CallIcon />;
      case 'chaticon': return <ChatIcon />;
      case 'analyticsicon': return <AnalyticsIcon />;
      case 'bolticon': return <BoltIcon />;
      case 'psychologyicon': return <PsychologyIcon />;
      case 'imageicon': return <ImageIcon />;
      default: 
        console.log('Unknown icon_type:', product.icon_type);
        return getFallbackIcon(product.id);
    }
  }
  
  return getFallbackIcon(product.id);
};

const getFallbackIcon = (productId: string) => {
  console.log('Using fallback icon for product id:', productId);
  switch (productId) {
    case 'calls-analyzer': return <CallIcon />;
    case 'smm-assistant': return <ChatIcon />;
    case 'sales-predictor': return <AnalyticsIcon />;
    case 'quick-responder': return <BoltIcon />;
    default: 
      console.log('No icon found for product id:', productId);
      return <BoltIcon />;
  }
};

const getTooltipText = (product: ClientProduct) => {
  if (product.status === 'soon') return 'Скоро будет доступно';
  if (!product.has_access) return 'Подписаться на продукт';
  return '';
};

export default Products; 
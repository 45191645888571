import React from 'react';
import { 
  Box, 
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Theme,
  Pagination,
  TextField,
  InputAdornment,
  Button
} from '@mui/material';
import { StatBox, StyledActionButton } from '../../../components/styled';
import { CallEvaluation, ChecklistConfig } from '../../../../../../services/callAnalyticsService';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';
import { useFilters } from '../../../context/FiltersContext';

interface Props {
  evaluations?: CallEvaluation[];
  checklistConfig?: ChecklistConfig | null;
}

interface Score {
  block_id: string;
  score: number;
}

interface ManagerScore {
  total: number;
  count: number;
}

interface ManagerData {
  id: string;
  scores: {
    [key: string]: ManagerScore;
  };
  totalScore: number;
}

interface ProcessedManagerData {
  id: string;
  scores: {
    [key: string]: number;
  };
  totalScore: number;
}

interface BlockItem {
  id: string;
  name: string;
  order: number;
  weight: number;
}

interface Block {
  id: string;
  name: string;
  items: BlockItem[];
}

interface ScoresMap {
  [key: string]: ManagerScore;
}

// Добавляем интерфейс для объекта слияния ячеек
interface CellRange {
  s: { r: number; c: number }; // start
  e: { r: number; c: number }; // end
}

// Обновляем функцию для получения стилей ячейки
const getCellStyle = (isLastInBlock: boolean = false) => ({
  borderRight: (theme: Theme) => {
    if (isLastInBlock) {
      return `2px solid ${theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.2)' 
        : 'rgba(0, 0, 0, 0.2)'}`;
    }
    return 'none';
  },
  borderBottom: (theme: Theme) => `1px solid ${theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.1)' 
    : 'rgba(0, 0, 0, 0.1)'}`,
  '&:first-of-type': {
    borderLeft: (theme: Theme) => `1px solid ${theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.1)'}`,
  }
});

// Добавляем функцию для определения цвета фона
const getScoreBackgroundColor = (score: number, theme: Theme) => {
  if (score >= 90) {
    return theme.palette.mode === 'dark' 
      ? 'rgba(0, 200, 83, 0.15)'
      : 'rgba(0, 200, 83, 0.1)';
  } else if (score >= 70) {
    return theme.palette.mode === 'dark'
      ? 'rgba(0, 200, 83, 0.1)'
      : 'rgba(0, 200, 83, 0.05)';
  } else if (score >= 50) {
    return theme.palette.mode === 'dark'
      ? 'rgba(255, 152, 0, 0.15)'
      : 'rgba(255, 152, 0, 0.1)';
  } else {
    return theme.palette.mode === 'dark'
      ? 'rgba(244, 67, 54, 0.15)'
      : 'rgba(244, 67, 54, 0.1)';
  }
};

const ManagersTable: React.FC<Props> = ({ evaluations, checklistConfig }) => {
  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState('');
  const itemsPerPage = 20;

  const { period, startDate, endDate } = useFilters();

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    });
  };

  const getPeriodString = () => {
    if (!startDate || !endDate) return '';

    const formattedStartDate = startDate.format('DD.MM.YY');
    const formattedEndDate = endDate.format('DD.MM.YY');
    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const managers = React.useMemo(() => {
    if (!evaluations?.length || !checklistConfig) return [];

    const managersMap = new Map<string, ManagerData>();
    const configBlocks = ((checklistConfig.blocks as any).blocks || []) as Block[];

    evaluations.forEach(evaluation => {
      const managerId = evaluation.manager_id;
      if (!managersMap.has(managerId)) {
        managersMap.set(managerId, {
          id: managerId,
          scores: configBlocks.reduce<ScoresMap>((acc, block: Block) => ({
            ...acc,
            [block.id]: {
              total: 0,
              count: 0,
            }
          }), {}),
          totalScore: 0,
        });
      }

      const managerData = managersMap.get(managerId);
      if (managerData) {
        configBlocks.forEach(block => {
          const blockScores = block.items.map(item => {
            const scoreKey = item.id.replace('.', '_');
            return evaluation.scores?.[scoreKey] || 0;
          });

          if (blockScores.length > 0) {
            const blockTotal = blockScores.reduce((sum, score) => sum + score, 0);
            managerData.scores[block.id].total += blockTotal;
            managerData.scores[block.id].count += blockScores.length;
          }
        });
      }
    });

    return Array.from(managersMap.values()).map(manager => ({
      ...manager,
      scores: Object.entries(manager.scores).reduce<{ [key: string]: number }>((acc, [blockId, data]) => ({
        ...acc,
        [blockId]: data.count > 0 ? Math.round((data.total / data.count) * 100) : 0
      }), {}),
      totalScore: Math.round(
        Object.values(manager.scores).reduce((sum, data) => 
          sum + (data.count > 0 ? (data.total / data.count) : 0), 0) / 
        Object.keys(manager.scores).length * 100
      )
    })) as ProcessedManagerData[];
  }, [evaluations, checklistConfig]);

  const filteredAndPaginatedManagers = React.useMemo(() => {
    if (!managers.length) return [];

    const filtered = searchQuery
      ? managers.filter(manager => 
          manager.id.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : managers;

    const startIndex = (page - 1) * itemsPerPage;
    return filtered.slice(startIndex, startIndex + itemsPerPage);
  }, [managers, page, searchQuery]);

  const totalPages = React.useMemo(() => {
    const filtered = searchQuery
      ? managers.filter(manager => 
          manager.id.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : managers;
    return Math.ceil(filtered.length / itemsPerPage);
  }, [managers, searchQuery]);

  React.useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  const handleExportToExcel = () => {
    if (!managers.length || !blocks.length) return;

    // Подготавливаем заголовки первого уровня
    const headerRow1 = [
      'ID менеджера', // Для первой колонки
      'Общий балл',   // Для второй колонки
      ...blocks.flatMap(block => [
        block.name,   // Для ST
        ...Array(block.items.length).fill(block.name) // Повторяем имя блока для каждого пункта
      ])
    ];

    // Подготавливаем заголовки второго уровня
    const headerRow2 = [
      'Менеджер',     // Для первой колонки
      'Балл',         // Для второй колонки
      ...blocks.flatMap(block => [
        'ST',         // Колонка ST
        ...block.items.map(item => item.id) // Колонки пунктов
      ])
    ];

    // Подготавливаем данные
    const data = managers.map(manager => [
      manager.id,
      `${manager.totalScore}%`,
      ...blocks.flatMap(block => [
        `${manager.scores[block.id]}%`,
        ...block.items.map(() => `${manager.scores[block.id]}%`)
      ])
    ]);

    // Создаем рабочую книгу Excel с двумя строками заголовков
    const ws = XLSX.utils.aoa_to_sheet([headerRow1, headerRow2, ...data]);
    const wb = XLSX.utils.book_new();

    // Получаем диапазон данных
    const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');

    // Стили для заголовков первого уровня
    const headerStyle1 = {
      font: { bold: true, color: { rgb: "666666" } },
      fill: { fgColor: { rgb: "F9F9F9" } },
      border: {
        top: { style: 'thin', color: { rgb: "E0E0E0" } },
        bottom: { style: 'thin', color: { rgb: "E0E0E0" } },
        left: { style: 'thin', color: { rgb: "E0E0E0" } },
        right: { style: 'thin', color: { rgb: "E0E0E0" } }
      },
      alignment: { horizontal: 'center', vertical: 'center' }
    };

    // Стили для заголовков второго уровня
    const headerStyle2 = {
      ...headerStyle1,
      fill: { fgColor: { rgb: "F5F5F5" } }
    };

    // Применяем стили к заголовкам
    for (let C = range.s.c; C <= range.e.c; C++) {
      // Для первой строки заголовков
      const cellRef1 = XLSX.utils.encode_cell({ r: 0, c: C });
      ws[cellRef1].s = headerStyle1;

      // Для второй строки заголовков
      const cellRef2 = XLSX.utils.encode_cell({ r: 1, c: C });
      ws[cellRef2].s = headerStyle2;
    }

    // Стили для данных
    for (let R = 1; R <= range.e.r; R++) {
      for (let C = 0; C <= range.e.c; C++) {
        const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
        if (!ws[cellRef]) continue;

        const value = ws[cellRef].v;
        const score = parseInt(value);
        
        let backgroundColor;
        if (!isNaN(score)) {
          if (score >= 90) backgroundColor = "E3F7E9";
          else if (score >= 70) backgroundColor = "E8F5E9";
          else if (score >= 50) backgroundColor = "FFF3E0";
          else backgroundColor = "FFEBEE";

          ws[cellRef].s = {
            font: { color: { rgb: "000000" } },
            fill: { fgColor: { rgb: backgroundColor } },
            border: {
              top: { style: 'thin', color: { rgb: "E0E0E0" } },
              bottom: { style: 'thin', color: { rgb: "E0E0E0" } },
              left: { style: 'thin', color: { rgb: "E0E0E0" } },
              right: { style: 'thin', color: { rgb: "E0E0E0" } }
            },
            alignment: { horizontal: 'center' }
          };
        }
      }
    }

    // Объединяем ячейки для заголовков блоков
    let merges: CellRange[] = [];
    let currentCol = 2; // Начинаем после ID и общего балла
    blocks.forEach(block => {
      // Объединяем ячейки только в первой строке заголовков
      merges.push({
        s: { r: 0, c: currentCol },
        e: { r: 0, c: currentCol + block.items.length }
      });
      currentCol += block.items.length + 1;
    });
    ws['!merges'] = merges;

    // Устанавливаем ширину колонок
    const colWidths = headerRow2.map(h => ({ wch: Math.max(h.length, 15) }));
    ws['!cols'] = colWidths;

    // Получаем строку периода для названия файла и листа
    const getSheetName = () => {
      if (period === 'custom' && startDate && endDate) {
        return `${startDate.format('DD.MM.YY')}-${endDate.format('DD.MM.YY')}`;
      }

      const today = new Date();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const year = today.getFullYear().toString().slice(2);
      
      switch (period) {
        case 'week':
          return `${today.getDate().toString().padStart(2, '0')}.${month}.${year}`;
        case 'month':
          return `${month}.${year}`;
        case 'quarter':
          return `Q${Math.floor(today.getMonth() / 3) + 1}.${year}`;
        case 'year':
          return year;
        default:
          return `${today.getDate().toString().padStart(2, '0')}.${month}.${year}`;
      }
    };

    const periodString = getPeriodString(); // для имени файла
    const sheetName = getSheetName();       // для имени листа

    // Добавляем лист с коротким именем
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    // В имени файла можем использовать полное описание периода
    XLSX.writeFile(wb, `managers-checklist-${periodString}.xlsx`, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'binary',
      cellStyles: true
    });
  };

  if (!checklistConfig || !managers.length) return null;

  const blocks = ((checklistConfig.blocks as any).blocks || []) as Block[];

  return (
    <StatBox>
      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          Расшифровка пунктов чек-листа:
        </Typography>
        <Grid container spacing={2} alignItems="stretch">
          {blocks.map((block, index) => (
            <Grid item xs={12} md={4} key={block.id} sx={{ display: 'flex' }}>
              <Box
                sx={{
                  p: 2,
                  borderRadius: 2,
                  backgroundColor: theme => theme.palette.mode === 'dark' 
                    ? 'rgba(255, 255, 255, 0.05)' 
                    : 'rgba(0, 0, 0, 0.02)',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    mb: 1,
                    color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                    fontSize: '0.875rem',
                    fontWeight: 600
                  }}
                >
                  {`${(index + 1).toString().padStart(2, '0')}. ${block.name}`}
                </Typography>
                <Box sx={{ 
                  display: 'grid', 
                  gridTemplateColumns: 'auto 1fr',
                  gap: '4px 12px',
                  '& > *': {
                    fontSize: '0.75rem !important',
                  },
                  flex: 1
                }}>
                  {block.items.map(item => (
                    <React.Fragment key={item.id}>
                      <Typography 
                        color="text.secondary" 
                        sx={{ 
                          fontWeight: 600,
                          fontSize: '0.75rem !important'
                        }}
                      >
                        {item.id}:
                      </Typography>
                      <Typography sx={{ fontSize: '0.75rem !important' }}>
                        {item.name}
                      </Typography>
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ 
        mb: 3,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Typography 
          variant="subtitle2" 
          color="text.secondary"
          sx={{ mb: 2 }}
        >
          Чек-лист по менеджерам:
        </Typography>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
            <TextField
              size="small"
              placeholder="Поиск по ID"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              autoComplete="off"
              sx={{
                width: '300px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: theme => theme.palette.mode === 'dark' 
                    ? 'rgba(255, 255, 255, 0.03)' 
                    : 'rgba(0, 0, 0, 0.02)',
                  height: '36px',
                  '& fieldset': {
                    border: 'none'
                  },
                  '&:hover fieldset': {
                    border: 'none'
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none'
                  }
                },
                '& .MuiInputBase-input': {
                  padding: '0 14px',
                  height: '36px',
                  lineHeight: '36px'
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'text.secondary' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ 
              height: '36px',
              display: 'flex',
              alignItems: 'center',
              px: 2,
              borderRadius: '12px',
              backgroundColor: theme => theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.03)' 
                : 'rgba(0, 0, 0, 0.02)',
            }}>
              <Typography variant="body2" color="text.secondary">
                Данные за период: {getPeriodString()}
              </Typography>
            </Box>
          </Box>
          
          <StyledActionButton
            startIcon={<FileDownloadIcon />}
            onClick={handleExportToExcel}
            disableRipple
          >
            Скачать Excel
          </StyledActionButton>
        </Box>
      </Box>
      
      <TableContainer>
        <Table size="small" sx={{
          borderCollapse: 'separate',
          borderSpacing: 0,
          '& .MuiTableCell-root': {
            padding: '8px 16px',
          },
          '& .MuiTableHead-root': {
            position: 'sticky',
            top: 0,
            zIndex: 2,
            backgroundColor: theme => theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.03)'
              : 'rgba(0, 0, 0, 0.02)',
            '& .MuiTableRow-root:first-of-type': {
              '& .MuiTableCell-root': {
                borderTop: theme => `1px solid ${theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.1)' 
                  : 'rgba(0, 0, 0, 0.1)'}`,
                borderBottom: 'none',
                '&:first-of-type': {
                  borderTopLeftRadius: '8px',
                  borderLeft: theme => `1px solid ${theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.1)'
                    : 'rgba(0, 0, 0, 0.1)'}`,
                },
                '&:last-of-type': {
                  borderTopRightRadius: '8px',
                  borderRight: theme => `1px solid ${theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.1)'
                    : 'rgba(0, 0, 0, 0.1)'}`,
                },
              }
            },
            '& .MuiTableRow-root:last-of-type': {
              '& .MuiTableCell-root': {
                borderTop: 'none',
                borderBottom: theme => `2px solid ${theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.2)'
                  : 'rgba(0, 0, 0, 0.2)'}`,
                '&:first-of-type': {
                  borderLeft: theme => `1px solid ${theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.1)'
                    : 'rgba(0, 0, 0, 0.1)'}`,
                },
                '&:last-of-type': {
                  borderRight: theme => `1px solid ${theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.1)'
                    : 'rgba(0, 0, 0, 0.1)'}`,
                },
              }
            }
          },
          '& .MuiTableBody-root .MuiTableRow-root': {
            '& .MuiTableCell-root': {
              borderBottom: theme => `1px solid ${theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, 0.1)'}`,
              '&:first-of-type': {
                borderLeft: theme => `1px solid ${theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.1)'}`,
              },
              '&:last-of-type': {
                borderRight: theme => `1px solid ${theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.1)'}`,
              },
            },
            '&:last-child .MuiTableCell-root': {
              borderBottom: theme => `1px solid ${theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, 0.1)'}`,
              '&:first-of-type': {
                borderBottomLeftRadius: '8px',
              },
              '&:last-of-type': {
                borderBottomRightRadius: '8px',
              },
            },
            '&:hover': {
              backgroundColor: theme => theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.05)'
                : 'rgba(0, 0, 0, 0.03)',
            }
          }
        }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ 
                fontWeight: 600, 
                color: 'text.secondary',
                py: 2,
              }}>
                ID
              </TableCell>
              <TableCell sx={{ 
                fontWeight: 600, 
                color: 'text.secondary',
              }}>
                AVG
              </TableCell>
              {blocks.map((block, index) => (
                <React.Fragment key={block.id}>
                  <TableCell 
                    sx={{ 
                      fontWeight: 600, 
                      color: 'text.secondary',
                      textAlign: 'center',
                      borderRight: theme => index < blocks.length - 1
                        ? `2px solid ${theme.palette.mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.2)' 
                          : 'rgba(0, 0, 0, 0.2)'}`
                        : 'none',
                    }}
                    colSpan={block.items.length + 1}
                  >
                    {`${(index + 1).toString().padStart(2, '0')}. ${block.name}`}
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
            <TableRow>
              <TableCell sx={{ 
                color: 'text.secondary',
                py: 2,
              }}>
                Менеджер
              </TableCell>
              <TableCell sx={{ 
                color: 'text.secondary',
              }}>
                Балл
              </TableCell>
              {blocks.map((block, blockIndex) => (
                <React.Fragment key={block.id}>
                  <TableCell 
                    sx={{ 
                      color: 'text.secondary',
                      textAlign: 'center',
                      ...getCellStyle(false),
                      borderRight: theme => `2px solid ${theme.palette.mode === 'dark' 
                        ? 'rgba(255, 255, 255, 0.2)' 
                        : 'rgba(0, 0, 0, 0.2)'}`,
                    }}
                  >
                    ST
                  </TableCell>
                  {block.items.map((item, itemIndex) => (
                    <TableCell 
                      key={item.id}
                      sx={{ 
                        color: 'text.secondary',
                        textAlign: 'center',
                        ...getCellStyle(
                          itemIndex === block.items.length - 1 && 
                          blockIndex < blocks.length - 1
                        )
                      }}
                    >
                      {`${blockIndex + 1}.${item.id.split('.')[1].padStart(2, '0')}`}
                    </TableCell>
                  ))}
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndPaginatedManagers.map(manager => (
              <TableRow key={manager.id}>
                <TableCell sx={getCellStyle()}>
                  {manager.id}
                </TableCell>
                <TableCell sx={{ 
                  color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                  fontWeight: 600,
                  ...getCellStyle(true),
                  backgroundColor: theme => getScoreBackgroundColor(manager.totalScore, theme)
                }}>
                  {manager.totalScore}%
                </TableCell>
                {blocks.map((block, blockIndex) => (
                  <React.Fragment key={block.id}>
                    <TableCell 
                      sx={{ 
                        textAlign: 'center',
                        ...getCellStyle(false),
                        borderRight: theme => `2px solid ${theme.palette.mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.2)' 
                          : 'rgba(0, 0, 0, 0.2)'}`,
                        backgroundColor: theme => getScoreBackgroundColor(manager.scores[block.id], theme)
                      }}
                    >
                      {manager.scores[block.id]}%
                    </TableCell>
                    {block.items.map((item, itemIndex) => (
                      <TableCell 
                        key={item.id}
                        sx={{ 
                          textAlign: 'center',
                          ...getCellStyle(
                            itemIndex === block.items.length - 1 && 
                            blockIndex < blocks.length - 1
                          ),
                          backgroundColor: theme => getScoreBackgroundColor(manager.scores[block.id], theme)
                        }}
                      >
                        {manager.scores[block.id]}%
                      </TableCell>
                    ))}
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {totalPages > 1 && (
        <Box sx={{ 
          mt: 2, 
          display: 'flex', 
          justifyContent: 'center',
          '& .MuiPaginationItem-root': {
            color: theme => theme.palette.mode === 'dark' 
              ? 'rgba(255, 255, 255, 0.7)' 
              : 'rgba(0, 0, 0, 0.7)',
            '&.Mui-selected': {
              backgroundColor: theme => theme.palette.mode === 'dark' 
                ? 'rgba(220, 251, 1, 0.2)' 
                : 'rgba(121, 62, 238, 0.2)',
              color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
            }
          }
        }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(_, newPage) => setPage(newPage)}
            color="primary"
            shape="rounded"
            size="small"
          />
        </Box>
      )}
    </StatBox>
  );
};

export default ManagersTable; 
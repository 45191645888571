import React, { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend } from 'recharts';
import { StatBox } from '../../styled';
import { CallStatistics, ChecklistConfig } from '../../../../../../services/callAnalyticsService';
import { useFilters } from '../../../context/FiltersContext';
import { useTranslation } from 'react-i18next';

interface Props {
  statistics?: CallStatistics[];
  checklistConfig?: ChecklistConfig | null;
}

interface Block {
  id: string;
  name: string;
}

interface ChartDataPoint {
  date: string;
  fullDate: Date;
  [key: string]: any;
}

interface TooltipEntry {
  dataKey: string;
  value: number;
  color: string;
  payload: ChartDataPoint;
}

interface ConfigBlock {
  id: string;
  name: string;
  order: number;
  items: {
    id: string;
    name: string;
    order: number;
    weight: number;
  }[];
}

const BlocksScoresDynamics: React.FC<Props> = ({ statistics, checklistConfig }) => {
  const theme = useTheme();
  const { period } = useFilters();
  const { t, i18n } = useTranslation();

  const blocks = useMemo<Block[]>(() => {
    const blocksArray = ((checklistConfig?.blocks as any)?.blocks || []) as ConfigBlock[];
    
    return blocksArray
      .sort((a, b) => a.order - b.order)
      .map(block => ({
        id: block.id,
        name: block.name
      }));
  }, [checklistConfig]);

  const chartData = useMemo<ChartDataPoint[]>(() => {
    if (!statistics?.length || !blocks?.length) return [];

    if (statistics?.length > 180) {
      const weeklyData = statistics.reduce((acc, stat) => {
        const date = new Date(stat.date);
        const weekStart = new Date(date);
        weekStart.setDate(date.getDate() - date.getDay() + 1);
        const weekKey = weekStart.toISOString().split('T')[0];

        if (!acc[weekKey]) {
          acc[weekKey] = {
            date: weekStart.toLocaleDateString(i18n.language, {
              day: '2-digit',
              month: 'short'
            }),
            fullDate: weekStart,
            counts: {},
            totals: {}
          };
        }

        blocks.forEach((block: Block) => {
          const blockKey = `block${block.id.replace(/^0/, '')}`;
          const value = stat.block_scores[blockKey];
          
          if (value !== undefined) {
            const key = `block_${block.id}`;
            acc[weekKey].counts[key] = (acc[weekKey].counts[key] || 0) + 1;
            acc[weekKey].totals[key] = (acc[weekKey].totals[key] || 0) + value;
          }
        });

        return acc;
      }, {} as Record<string, any>);

      return Object.values(weeklyData)
        .map(week => {
          const result: ChartDataPoint = {
            date: week.date,
            fullDate: week.fullDate,
          };

          blocks.forEach((block: Block) => {
            const key = `block_${block.id}`;
            result[key] = week.counts[key] 
              ? Math.round(week.totals[key] / week.counts[key]) 
              : 0;
          });

          return result;
        })
        .sort((a, b) => a.fullDate.getTime() - b.fullDate.getTime());
    }

    return statistics?.map(stat => {
      const result: ChartDataPoint = {
        date: new Date(stat.date).toLocaleDateString(i18n.language, {
          day: '2-digit',
          month: 'short'
        }),
        fullDate: new Date(stat.date),
      };

      blocks.forEach((block: Block) => {
        const blockKey = `block${block.id.replace(/^0/, '')}`;
        const value = stat.block_scores[blockKey];
        result[`block_${block.id}`] = value || 0;
      });

      return result;
    }) || [];
  }, [statistics, blocks, i18n.language]);

  const getBlockColor = (blockId: string, mode: 'light' | 'dark' = 'light') => {
    if (mode === 'dark') {
      switch (blockId) {
        case '01': return '#DCFB01'; // фирменный неоново-желтый
        case '02': return '#36F1CD'; // бирюзовый неон
        case '03': return '#3DD5FF'; // электрический голубой
        case '04': return '#A2FC7C'; // весенний зеленый
        case '05': return '#FFD53D'; // золотистый
        case '06': return '#96F7B3'; // мятный
        case '07': return '#59FFC1'; // аквамариновый
        case '08': return '#00E2B0'; // изумрудный неон
        default: return '#DCFB01';
      }
    } else {
      switch (blockId) {
        case '01': return '#793EEE'; // фирменный фиолетовый
        case '02': return '#2A6B9F'; // глубокий синий
        case '03': return '#00A7B5'; // морской волны
        case '04': return '#348553'; // малахитовый
        case '05': return '#0B877D'; // изумрудный
        case '06': return '#B86E00'; // янтарный
        case '07': return '#236C70'; // бирюзовый
        case '08': return '#1E754C'; // нефритовый
        default: return '#793EEE';
      }
    }
  };

  const calculateYAxisDomain = (data: ChartDataPoint[]) => {
    if (!data?.length || !blocks?.length) return [0, 100];

    const allValues = data.flatMap(item => 
      blocks.map((block: Block) => item[`block_${block.id}`] || 0)
    );

    const min = Math.min(...allValues);
    const max = Math.max(...allValues);

    if (min >= 90) return [85, 100];
    if (min >= 80) return [75, 100];
    if (max - min < 20) {
      const middle = (max + min) / 2;
      return [
        Math.max(0, Math.floor((middle - 15) / 5) * 5),
        Math.min(100, Math.ceil((middle + 15) / 5) * 5)
      ];
    }

    return [
      Math.max(0, Math.floor((min - 5) / 5) * 5),
      Math.min(100, Math.ceil((max + 5) / 5) * 5)
    ];
  };

  if (!blocks?.length || !chartData.length) return null;

  return (
    <StatBox sx={{ 
      height: '100%',
      display: 'flex', 
      flexDirection: 'column',
      p: 3,
      position: 'relative'
    }}>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        {t('callAnalytics.blocksScoresDynamics.title')}
      </Typography>
      
      <Box sx={{ 
        display: 'flex', 
        flexWrap: 'wrap',
        gap: 1.5,
        mt: 1,
        mb: 1.5
      }}>
        {blocks.map((block: Block) => (
          <Box 
            key={block.id}
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 0.5,
              minWidth: 'auto',
              height: 20
            }}
          >
            <Box 
              component="span"
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 0.5
              }}
            >
              <Box
                sx={{ 
                  width: 6,
                  height: 6,
                  borderRadius: '50%',
                  backgroundColor: getBlockColor(block.id, theme.palette.mode),
                }}
              />
              <Typography 
                variant="caption" 
                sx={{ 
                  color: getBlockColor(block.id, theme.palette.mode),
                  fontWeight: 500,
                  lineHeight: 1
                }}
              >
                {block.id}
              </Typography>
            </Box>
            <Typography 
              variant="caption" 
              sx={{ 
                color: theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.7)' 
                  : 'rgba(0, 0, 0, 0.6)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineHeight: 1
              }}
            >
              {block.name}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box sx={{ 
        flex: 1,
        minHeight: 0,
        position: 'relative'
      }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart 
            data={chartData}
            margin={{ top: 15, right: 15, left: 0, bottom: 0 }}
          >
            <defs>
              {blocks.map((block: Block) => (
                <linearGradient 
                  key={block.id} 
                  id={`color${block.id}`} 
                  x1="0" 
                  y1="0" 
                  x2="0" 
                  y2="1"
                >
                  <stop 
                    offset="5%" 
                    stopColor={getBlockColor(block.id, theme.palette.mode)} 
                    stopOpacity={0.1}
                  />
                  <stop 
                    offset="95%" 
                    stopColor={getBlockColor(block.id, theme.palette.mode)} 
                    stopOpacity={0.01}
                  />
                </linearGradient>
              ))}
            </defs>
            <CartesianGrid 
              strokeDasharray="3 3" 
              vertical={false}
              stroke={theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.03)'}
            />
            <XAxis 
              dataKey="date" 
              axisLine={false}
              tickLine={false}
              tick={{ 
                fill: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                fontSize: 12 
              }}
              dy={5}
              interval="preserveStartEnd"
              height={30}
            />
            <YAxis 
              axisLine={false}
              tickLine={false}
              tick={{ 
                fill: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                fontSize: 12 
              }}
              width={45}
              domain={calculateYAxisDomain(chartData)}
              tickFormatter={(value) => `${value}%`}
            />
            <Tooltip 
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const date = payload[0].payload.fullDate as Date;
                  
                  const dateText = (statistics?.length ?? 0) > 180 
                    ? `${date.toLocaleDateString(i18n.language, {
                        day: '2-digit',
                        month: 'short'
                      })} - ${new Date(date.getTime() + 6 * 24 * 60 * 60 * 1000).toLocaleDateString(i18n.language, {
                        day: '2-digit',
                        month: 'short'
                      })}`
                    : payload[0].payload.date;

                  return (
                    <Box
                      sx={{
                        backgroundColor: theme => theme.palette.mode === 'dark' ? '#1E1E1E' : '#FFF',
                        border: theme => `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
                        p: 1.5,
                        borderRadius: '8px',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                        backdropFilter: 'blur(4px)',
                      }}
                    >
                      <Typography variant="body2" sx={{ mb: 1, fontWeight: 600 }}>
                        {dateText}
                      </Typography>
                      {(payload as TooltipEntry[])
                        .sort((a, b) => b.value - a.value)
                        .map((entry) => {
                          const blockId = typeof entry.dataKey === 'string' ? 
                            entry.dataKey.split('_')[1] : '';
                          
                          return (
                            <Box 
                              key={entry.dataKey} 
                              sx={{ 
                                display: 'flex', 
                                alignItems: 'center',
                                gap: 1,
                                mb: 0.5,
                                py: 0.5,
                                px: 1,
                                borderRadius: 1,
                                '&:hover': {
                                  backgroundColor: theme => theme.palette.mode === 'dark' 
                                    ? 'rgba(255, 255, 255, 0.05)' 
                                    : 'rgba(0, 0, 0, 0.02)'
                                }
                              }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box
                                  sx={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: entry.color,
                                  }}
                                />
                                <Typography 
                                  variant="body2" 
                                  sx={{ 
                                    color: getBlockColor(blockId, theme.palette.mode),
                                    fontWeight: 500
                                  }}
                                >
                                  {blockId}
                                </Typography>
                              </Box>
                              <Typography 
                                variant="body2" 
                                sx={{ 
                                  color: entry.color,
                                  fontWeight: 600,
                                }}
                              >
                                {Math.round(entry.value)}%
                              </Typography>
                            </Box>
                          );
                        })}
                    </Box>
                  );
                }
                return null;
              }}
            />
            {blocks.map((block: Block) => (
              <Area
                key={block.id}
                type="monotone"
                dataKey={`block_${block.id}`}
                name={block.name}
                stroke={getBlockColor(block.id, theme.palette.mode)}
                fill={`url(#color${block.id})`}
                strokeWidth={2.5}
                dot={false}
                activeDot={{
                  stroke: getBlockColor(block.id, theme.palette.mode),
                  strokeWidth: 2,
                  r: 6,
                  fill: theme.palette.mode === 'dark' ? '#1E1E1E' : '#FFF'
                }}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </Box>

      {period === 'today' && (
        <Box sx={{ 
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ textAlign: 'center' }}
          >
            {t('callAnalytics.blocksScoresDynamics.noChart')}
          </Typography>
        </Box>
      )}
    </StatBox>
  );
};

export default BlocksScoresDynamics; 
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider, useAuth } from './context/AuthContext';
import { ThemeProvider } from './context/ThemeContext';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import RequestAccess from './pages/RequestAccess/RequestAccess';
import Dashboard from './pages/Dashboard/Dashboard';
import Products from './pages/Products/Products';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import './i18n';
import Layout from './components/Layout/Layout';
import Profile from './pages/Profile/Profile';
import Team from './pages/Team/Team';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CallAnalytics from './pages/Products/CallAnalytics';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';

// Создаем компонент-обертку для роутов
const AppRoutes = () => {
  const { loading: initialLoading } = useAuth();

  // Показываем загрузку только при начальной инициализации
  if (initialLoading) {
    return <LoadingScreen />;
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route 
        path="/request-access" 
        element={
          <ProtectedRoute requireTeam={false}>
            <RequestAccess />
          </ProtectedRoute>
        } 
      />
      <Route
        element={
          <ProtectedRoute requireTeam={true}>
            <Layout>
              <Outlet />
            </Layout>
          </ProtectedRoute>
        }
      >
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/0a4fd722-9ff1-4e27-8e1a-122bb09e258b" element={<CallAnalytics />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/team" element={<Team />} />
      </Route>
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CssBaseline />
            <AppRoutes />
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;

import { createTheme, Theme } from '@mui/material/styles';

// Общие настройки типографики
const typography = {
  fontFamily: 'Manrope, sans-serif',
  h1: {
    fontFamily: 'Inter Tight, sans-serif',
    fontWeight: 700,
  },
  h2: {
    fontFamily: 'Inter Tight, sans-serif',
    fontWeight: 700,
  },
  h3: {
    fontFamily: 'Inter Tight, sans-serif',
    fontWeight: 700,
  },
  h4: {
    fontFamily: 'Inter Tight, sans-serif',
    fontWeight: 700,
  },
  h5: {
    fontFamily: 'Inter Tight, sans-serif',
    fontWeight: 700,
  },
  h6: {
    fontFamily: 'Inter Tight, sans-serif',
    fontWeight: 600,
  },
  subtitle1: {
    fontFamily: 'Inter Tight, sans-serif',
    fontWeight: 600,
  },
  subtitle2: {
    fontFamily: 'Inter Tight, sans-serif',
    fontWeight: 600,
  },
  body1: {
    fontFamily: 'Manrope, sans-serif',
    fontWeight: 400,
  },
  body2: {
    fontFamily: 'Manrope, sans-serif',
    fontWeight: 400,
  },
};

// Базовые стили скроллбара без цветов
const baseScrollbarStyles = {
  body: {
    WebkitOverflowScrolling: 'touch',
    '& #root': {
      height: '100vh',
      overflow: 'overlay',
      scrollbarWidth: 'thin',
      scrollbarGutter: 'stable both-edges',
      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
        background: 'transparent',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-corner': {
        background: 'transparent',
      },
    },
  },
};

// Темная тема с неоново-желтым
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#DCFB01',
      light: '#E4FC34',
      dark: '#B3C900',
    },
    secondary: {
      main: '#793EEE',
    },
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
  },
  typography,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...baseScrollbarStyles,
        body: {
          ...baseScrollbarStyles.body,
          backgroundColor: '#121212',
          fontFamily: 'Manrope, sans-serif',
          '& #root': {
            ...baseScrollbarStyles.body['& #root'],
            colorScheme: 'dark',
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(255, 255, 255, 0.1)',
              borderRadius: '4px',
              border: '2px solid transparent',
              backgroundClip: 'padding-box',
              '&:hover': {
                background: 'rgba(255, 255, 255, 0.2)',
              },
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          borderRadius: '12px',
        },
      },
    },
  },
});

// Светлая тема с фиолетовым
export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#793EEE',
      light: '#9B6AF6',
      dark: '#4B25D6',
    },
    secondary: {
      main: '#DCFB01',
    },
    background: {
      default: '#F5F5F7',
      paper: '#FFFFFF',
    },
  },
  typography,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...baseScrollbarStyles,
        body: {
          ...baseScrollbarStyles.body,
          backgroundColor: '#F5F5F7',
          fontFamily: 'Manrope, sans-serif',
          '& #root': {
            ...baseScrollbarStyles.body['& #root'],
            colorScheme: 'light',
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(0, 0, 0, 0.1)',
              borderRadius: '4px',
              border: '2px solid transparent',
              backgroundClip: 'padding-box',
              '&:hover': {
                background: 'rgba(0, 0, 0, 0.2)',
              },
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          borderRadius: '12px',
        },
      },
    },
  },
});

export type ThemeMode = 'light' | 'dark'; 
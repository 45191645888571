import api from './api';

export interface ProductFeature {
  id: string;
  name: string;
  isEnabled: boolean;
  description: string;
}

export interface ClientProduct {
  id: string;
  name: string;
  description: string;
  version: string;
  status: string;
  features: ProductFeature[];
  visibility: string;
  icon_type?: string;
  image_url?: string;
  subscription_status: string;
  subscription_start_date: string | null;
  subscription_end_date: string | null;
  has_access: boolean;
}

export const productService = {
  getProducts: async (): Promise<ClientProduct[]> => {
    try {
      console.log('🚀 Fetching products...');
      const { data } = await api.get('/products');
      console.log('📦 Raw products data:', data);

      const processedProducts = data.map((product: any) => {
        console.log('🔄 Processing product:', product);
        const features = Array.isArray(product.features) 
          ? product.features 
          : typeof product.features === 'string'
            ? JSON.parse(product.features)
            : [];
        
        console.log('📋 Processed features:', features);
        
        return {
          ...product,
          features,
          name: product.name || '',
          description: product.description || '',
          status: product.status || 'inactive',
          visibility: product.visibility || 'private',
          subscription_status: product.subscription_status || 'inactive',
          has_access: !!product.has_access
        };
      });

      console.log('✅ Processed products:', processedProducts);
      return processedProducts;
    } catch (error) {
      console.error('❌ Error in getProducts:', error);
      throw error;
    }
  },

  getProduct: async (id: string): Promise<ClientProduct> => {
    const { data } = await api.get(`/products/${id}`);
    return data;
  },

  subscribeToProduct: async (id: string) => {
    const { data } = await api.post(`/products/${id}/subscribe`);
    return data;
  }
}; 
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
  styled,
} from '@mui/material';
import { ArrowBack, FilterList } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Filters from './components/Filters';
import MainTab from './components/MainTab';
import { FiltersProvider } from './context/FiltersContext';
import ManagersTab from './components/ManagersTab';
import ClientsTab from './components/ClientsTab';
import CallsTab from './components/CallsTab';
import { useTranslation } from 'react-i18next';

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  background: theme.palette.mode === 'dark' 
    ? 'rgba(30, 30, 30, 0.85)'
    : 'rgba(255, 255, 255, 0.85)',
  backdropFilter: 'blur(20px)',
  borderRadius: '24px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const CallAnalytics: React.FC = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const { t } = useTranslation();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <FiltersProvider>
      <Box sx={{ p: 3, pt: 1 }}>
        {/* Кнопка "Назад" */}
        <Box 
          onClick={() => navigate('/products')} 
          sx={{ 
            display: 'inline-flex',
            alignItems: 'center',
            gap: 1,
            mb: 2,
            cursor: 'pointer',
            color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
            '&:hover': { opacity: 0.8 }
          }}
        >
          <ArrowBack sx={{ fontSize: 20 }} />
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {t('callAnalytics.navigation.backToProducts')}
          </Typography>
        </Box>

        <Filters />

        {/* Основной контент */}
        <StyledPaper>
          <Box>
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange}
              sx={{
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 500,
                  '&:hover': {
                    color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                  },
                  '&.Mui-selected': {
                    color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                  },
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                  height: '3px',
                  borderRadius: '4px 4px 0 0',
                }
              }}
            >
              <Tab label={t('callAnalytics.tabs.main')} disableRipple />
              <Tab label={t('callAnalytics.tabs.managers')} disableRipple />
              <Tab label={t('callAnalytics.tabs.clients')} disableRipple />
              <Tab label={t('callAnalytics.tabs.calls')} disableRipple />
            </Tabs>
          </Box>

          {/* Контент табов */}
          <TabPanel value={tabValue} index={0}>
            <MainTab />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ManagersTab />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <ClientsTab />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <CallsTab />
          </TabPanel>
        </StyledPaper>
      </Box>
    </FiltersProvider>
  );
};

// TabPanel компонент
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 4 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default CallAnalytics; 
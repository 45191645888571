import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
  ListItemIcon,
  Grid,
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  PersonAdd as PersonAddIcon,
  Search as SearchIcon,
  Mail as MailIcon,
  AdminPanelSettings as AdminIcon,
  Person as PersonIcon,
  Block as BlockIcon,
  Groups as GroupsIcon,
  Business as BusinessIcon,
  DateRange as DateRangeIcon,
  Category as CategoryIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

// Добавим тип для разрешений
type TeamPermission = 'view' | 'edit' | 'manage_users';

interface TeamMember {
  id: string;
  name: string;
  email: string;
  role: 'admin' | 'member';
  status: 'active' | 'inactive';
  lastActive: string;
  avatar: string;
  permissions: TeamPermission[];
}

// Добавим интерфейс для общей информации о комаде
interface TeamInfo {
  name: string;
  membersCount: number;
  createdAt: string;
  company: string;
  industry: string;
}

const MOCK_TEAM_MEMBERS: TeamMember[] = [
  {
    id: '1',
    name: 'Александр Иванов',
    email: 'a.ivanov@example.com',
    role: 'admin',
    status: 'active',
    lastActive: '2024-01-20T10:30:00',
    avatar: '',
    permissions: ['view', 'edit', 'manage_users'],
  },
  {
    id: '2',
    name: 'Елена Петрова',
    email: 'e.petrova@example.com',
    role: 'member',
    status: 'active',
    lastActive: '2024-01-19T15:45:00',
    avatar: '',
    permissions: ['view', 'edit'],
  },
  // ... добавьте больше мокап данных
];

// Мокап данные для общей информации
const MOCK_TEAM_INFO: TeamInfo = {
  name: 'Основная команда',
  membersCount: 12,
  createdAt: '2023-12-01',
  company: 'NeuroPlume Technologies',
  industry: 'IT & Software',
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: theme.palette.mode === 'dark'
    ? 'rgba(30, 30, 30, 0.85)'
    : 'rgba(255, 255, 255, 0.85)',
  backdropFilter: 'blur(20px)',
  borderRadius: '24px',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  padding: theme.spacing(3),
}));

const RoleChip = styled(Chip)<{ role: string }>(({ theme, role }) => ({
  borderRadius: '12px',
  fontWeight: 500,
  ...(role === 'admin' && {
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(220, 251, 1, 0.1)'
      : 'rgba(121, 62, 238, 0.1)',
    color: theme.palette.mode === 'dark'
      ? '#DCFB01'
      : '#793EEE',
  }),
  ...(role === 'member' && {
    backgroundColor: 'rgba(25, 118, 210, 0.1)',
    color: '#1976D2',
  }),
}));

const StatusDot = styled('span')<{ status: string }>(({ status }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: 8,
  backgroundColor: status === 'active' ? '#4CAF50' : '#9E9E9E',
}));

// Обновляем компонент InfoCard
const InfoCard = styled(Box)(({ theme }) => ({
  padding: '24px',
  background: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.05)'
    : 'rgba(121, 62, 238, 0.05)',
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  height: '100%', // Добавляем фиксированную высоту
  minHeight: '120px', // Минимальная высота для консистентности
  '& .icon': {
    width: 48,
    height: 48,
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(220, 251, 1, 0.1)'
      : 'rgba(121, 62, 238, 0.1)',
    color: theme.palette.mode === 'dark'
      ? '#DCFB01'
      : '#793EEE',
    flexShrink: 0, // Предотвращаем сжатие иконки
  },
  '& .info': {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: '100%', // Занимаем всю доступную ширину
    overflow: 'hidden', // Предотвращаем переполнение
  },
  '& .label': {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    whiteSpace: 'nowrap', // Предотвращаем перенос текста
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .value': {
    fontSize: '1.25rem',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

// Добавляем стилизованную таблицу
const ActivityTable = styled(TableContainer)(({ theme }) => ({
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
  },
  '& .MuiTableHead-root .MuiTableCell-root': {
    borderBottom: 'none',
    padding: theme.spacing(1.5),
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent',
  },
  '& .MuiTableBody-root .MuiTableCell-root': {
    borderBottom: 'none',
    padding: theme.spacing(1.5),
    fontSize: '0.875rem',
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.03)'
      : 'rgba(0, 0, 0, 0.02)',
    '&:first-of-type': {
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '12px',
      borderBottomRightRadius: '12px',
    },
  },
  '& .MuiTableRow-root': {
    transition: 'all 0.2s ease',
    '&:hover .MuiTableCell-root': {
      backgroundColor: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : 'rgba(0, 0, 0, 0.03)',
      '&:first-of-type': {
        borderTopLeftRadius: '12px',
        borderBottomLeftRadius: '12px',
      },
      '&:last-of-type': {
        borderTopRightRadius: '12px',
        borderBottomRightRadius: '12px',
      },
    },
  },
}));

const Team: React.FC = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMember, setSelectedMember] = useState<string | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, memberId: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedMember(memberId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedMember(null);
  };

  const filteredMembers = MOCK_TEAM_MEMBERS.filter(member =>
    member.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    member.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4 }}>
        <Button
          variant="contained"
          startIcon={<PersonAddIcon />}
          sx={{
            bgcolor: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
            color: theme => theme.palette.mode === 'dark' ? '#121212' : '#fff',
            '&:hover': {
              bgcolor: theme => theme.palette.mode === 'dark' ? '#c5e001' : '#6935d3',
            },
          }}
        >
          {t('team.invite')}
        </Button>
      </Box>

      <StyledPaper sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ p: 2 }}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box 
                sx={{ 
                  width: 48,
                  height: 48,
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: theme => theme.palette.mode === 'dark' 
                    ? 'rgba(220, 251, 1, 0.1)'
                    : 'rgba(121, 62, 238, 0.1)',
                  color: theme => theme.palette.mode === 'dark'
                    ? '#DCFB01'
                    : '#793EEE',
                }}
              >
                <BusinessIcon />
              </Box>
              <Box>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ mb: 0.5 }}
                >
                  {t('team.info.company')}
                </Typography>
                <Typography variant="h6">
                  {MOCK_TEAM_INFO.company}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box 
                sx={{ 
                  width: 48,
                  height: 48,
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: theme => theme.palette.mode === 'dark' 
                    ? 'rgba(220, 251, 1, 0.1)'
                    : 'rgba(121, 62, 238, 0.1)',
                  color: theme => theme.palette.mode === 'dark'
                    ? '#DCFB01'
                    : '#793EEE',
                }}
              >
                <CategoryIcon />
              </Box>
              <Box>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ mb: 0.5 }}
                >
                  {t('team.info.industry')}
                </Typography>
                <Typography variant="h6">
                  {t(`industry.${MOCK_TEAM_INFO.industry.toLowerCase()}` as const)}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box 
                sx={{ 
                  width: 48,
                  height: 48,
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: theme => theme.palette.mode === 'dark' 
                    ? 'rgba(220, 251, 1, 0.1)'
                    : 'rgba(121, 62, 238, 0.1)',
                  color: theme => theme.palette.mode === 'dark'
                    ? '#DCFB01'
                    : '#793EEE',
                }}
              >
                <GroupsIcon />
              </Box>
              <Box>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ mb: 0.5 }}
                >
                  {t('team.info.members')}
                </Typography>
                <Typography variant="h6">
                  {MOCK_TEAM_INFO.membersCount}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box 
                sx={{ 
                  width: 48,
                  height: 48,
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: theme => theme.palette.mode === 'dark' 
                    ? 'rgba(220, 251, 1, 0.1)'
                    : 'rgba(121, 62, 238, 0.1)',
                  color: theme => theme.palette.mode === 'dark'
                    ? '#DCFB01'
                    : '#793EEE',
                }}
              >
                <DateRangeIcon />
              </Box>
              <Box>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ mb: 0.5 }}
                >
                  {t('team.info.created')}
                </Typography>
                <Typography variant="h6">
                  {new Date(MOCK_TEAM_INFO.createdAt).toLocaleDateString()}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper>
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            placeholder={t('team.search')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'text.secondary' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
              },
            }}
          />
        </Box>

        <ActivityTable>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('team.member')}</TableCell>
                <TableCell>{t('team.role')}</TableCell>
                <TableCell>{t('team.status')}</TableCell>
                <TableCell>{t('team.lastActive')}</TableCell>
                <TableCell>{t('team.permissions.title')}</TableCell>
                <TableCell align="right">{t('team.actions.title')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredMembers.map((member) => (
                <TableRow key={member.id}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Avatar src={member.avatar}>
                        {member.name.charAt(0)}
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2">
                          {member.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {member.email}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <RoleChip
                      role={member.role}
                      label={member.role === 'admin' ? t('team.roles.admin') : t('team.roles.member')}
                      icon={member.role === 'admin' ? <AdminIcon /> : <PersonIcon />}
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <StatusDot status={member.status} />
                      <Typography variant="body2">
                        {member.status === 'active' ? t('team.statuses.active') : t('team.statuses.inactive')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {new Date(member.lastActive).toLocaleString()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {member.permissions.map((permission: TeamPermission) => (
                        <Chip
                          key={permission}
                          label={t(`team.permissions.${permission}` as const)}
                          size="small"
                          sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                            borderRadius: '8px',
                          }}
                        />
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={(e) => handleMenuOpen(e, member.id)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ActivityTable>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          disableScrollLock={true}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            sx: {
              mt: 1.5,
              background: theme => theme.palette.mode === 'dark'
                ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.85) 0%, rgba(18, 18, 18, 0.85) 100%)'
                : 'rgba(255, 255, 255, 0.85)',
              backdropFilter: 'blur(20px)',
              border: 'none',
              borderRadius: '12px',
              minWidth: '180px',
              boxShadow: theme => theme.palette.mode === 'dark'
                ? '0 4px 24px rgba(0, 0, 0, 0.4)'
                : '0 4px 24px rgba(0, 0, 0, 0.05)',
              '& .MuiList-root': {
                padding: '8px 0',
              },
              '& .MuiMenuItem-root': {
                px: 2,
                py: 1,
                mx: 1,
                my: 0.5,
                fontSize: '0.875rem',
                borderRadius: '8px',
                color: 'text.primary',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: 'transparent',
                  transform: 'translateX(4px)',
                  '& .MuiSvgIcon-root': {
                    transform: 'scale(1.1)',
                  },
                },
                '& .MuiSvgIcon-root': {
                  color: theme => theme.palette.primary.main,
                  transition: 'all 0.3s ease',
                  marginRight: 2,
                  fontSize: '20px',
                },
              },
            },
          }}
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <MenuItem onClick={handleMenuClose}>
            <ListItemIcon>
              <AdminIcon fontSize="small" sx={{ color: 'inherit' }} />
            </ListItemIcon>
            {t('team.actions.changeRole')}
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <ListItemIcon>
              <MailIcon fontSize="small" sx={{ color: 'inherit' }} />
            </ListItemIcon>
            {t('team.actions.sendInvite')}
          </MenuItem>
          <MenuItem 
            onClick={handleMenuClose}
            sx={{
              color: 'error.main',
              '& .MuiSvgIcon-root': {
                color: 'error.main !important',
              },
            }}
          >
            <ListItemIcon>
              <BlockIcon fontSize="small" />
            </ListItemIcon>
            {t('team.actions.remove')}
          </MenuItem>
        </Menu>
      </StyledPaper>
    </Box>
  );
};

export default Team; 
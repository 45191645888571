import React, { useMemo, useState } from 'react';
import {
  Box,
  Typography,
  useTheme,
  CircularProgress,
  Card,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  useMediaQuery,
  TextField,
  InputAdornment,
  Avatar,
  Tooltip,
} from '@mui/material';
import { StatBox, StyledActionButton } from '../../../components/styled';
import { styled, Theme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CloseIcon from '@mui/icons-material/Close';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CallEvaluation } from '../../../../../../services/callAnalyticsService';

interface Props {
  evaluations?: CallEvaluation[];
  selectedClientId?: string;
  onClientSelect?: (clientId: string) => void;
}

// Расширяем интерфейс для оценок
interface ExtendedCallEvaluation extends CallEvaluation {
  created_at: string;
}

interface ManagerStats {
  managerId: string;
  totalCalls: number;
  avgScore: number;
  lastCall: Date;
  trend: number;
}

// Добавляем интерфейс для статистики клиента
interface ClientStats {
  id: string;
  avgScore: number;
  totalCalls: number;
}

// Добавляем стили для модального окна
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(4),
    background: theme.palette.mode === 'dark'
      ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)'
      : 'rgba(255, 255, 255, 0.85)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '24px',
    position: 'relative',
    zIndex: 1,
    boxShadow: theme.palette.mode === 'dark'
      ? '0 4px 24px rgba(0, 0, 0, 0.4)'
      : '0 4px 24px rgba(0, 0, 0, 0.05)',
    '& .MuiDialogTitle-root': {
      padding: 0,
      paddingBottom: theme.spacing(3),
      color: theme.palette.text.primary,
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      paddingTop: theme.spacing(2),
    },
    transition: 'box-shadow 0.3s ease',
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(10px)',
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(0, 0, 0, 0.8)'
      : 'rgba(255, 255, 255, 0.8)',
  },
}));

// Добавляем стили для скроллбара
const scrollbarStyles = (theme: Theme) => ({
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    '&:hover': {
      background: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.2)' 
        : 'rgba(0, 0, 0, 0.2)',
    },
  },
  '&::-webkit-scrollbar-corner': {
    background: 'transparent',
  },
});

// Обновляем стили для контента модального окна
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  height: '60vh',
  maxHeight: '600px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2, 0),
  '& .search-field': {
    padding: theme.spacing(0, 2),
  },
  '& .manager-list, & .client-list': {
    flex: 1,
    overflow: 'auto',
    paddingRight: theme.spacing(2),
    marginRight: -theme.spacing(2),
    paddingLeft: theme.spacing(2),
    ...scrollbarStyles(theme),
  },
  '& .empty-message': {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  }
}));

// Добавляем стиль для кнопки закрытия
const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: theme.spacing(1),
  color: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.7)' 
    : 'rgba(0, 0, 0, 0.7)',
  '&:hover': {
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    backgroundColor: 'transparent',
  },
}));

// Стилизованный блок статистики
const StatBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.03)' 
    : 'rgba(0, 0, 0, 0.02)',
  borderRadius: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  '& .icon': {
    color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
    fontSize: '28px',
  },
  '& .value': {
    fontSize: '1.5rem',
    fontWeight: 600,
    color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
  },
  '& .label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  }
}));

const ClientsFlowChart: React.FC<Props> = ({ evaluations, selectedClientId, onClientSelect }) => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClientsModalOpen, setIsClientsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [modalSearchQuery, setModalSearchQuery] = useState('');
  const [clientSearchQuery, setClientSearchQuery] = useState('');
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { managerStats, clientStats } = useMemo(() => {
    if (!evaluations?.length || !selectedClientId) return { managerStats: [], clientStats: null };

    const clientEvaluations = evaluations.filter(evaluation => 
      evaluation.client_id === selectedClientId
    ) as ExtendedCallEvaluation[];

    // Группируем оценки по менеджерам
    const managersData = clientEvaluations.reduce((acc, evaluation) => {
      const managerId = evaluation.manager_id;
      if (!acc[managerId]) {
        acc[managerId] = {
          managerId,
          scores: [],
          calls: [],
          lastCall: new Date(evaluation.created_at)
        };
      }
      
      const score = evaluation.total_score * 100;
      acc[managerId].scores.push(score);
      acc[managerId].calls.push({
        date: new Date(evaluation.created_at),
        score
      });
      
      return acc;
    }, {} as Record<string, any>);

    // Преобразуем данные в массив статистики менеджеров
    const managerStats = Object.entries(managersData)
      .map(([managerId, data]) => {
        const avgScore = data.scores.reduce((sum: number, score: number) => sum + score, 0) / data.scores.length;
        return {
          managerId,
          totalCalls: data.calls.length,
          avgScore,
          lastCall: data.lastCall
        };
      })
      .sort((a, b) => b.avgScore - a.avgScore);

    const clientStats = {
      totalCalls: clientEvaluations.length,
      avgScore: clientEvaluations.reduce((sum, evaluation) => 
        sum + evaluation.total_score * 100, 0) / clientEvaluations.length,
      totalManagers: managerStats.length
    };

    return { managerStats, clientStats };
  }, [evaluations, selectedClientId]);

  const displayedManagers = useMemo(() => {
    if (!managerStats) return [];
    return managerStats.slice(0, 5);
  }, [managerStats]);

  const filteredModalManagers = useMemo(() => {
    if (!managerStats) return [];
    return managerStats.filter(manager => 
      manager.managerId.toLowerCase().includes(modalSearchQuery.toLowerCase())
    );
  }, [managerStats, modalSearchQuery]);

  const allClients = useMemo(() => {
    if (!evaluations?.length) return [];
    
    const uniqueClients = new Set(evaluations.map(evaluation => evaluation.client_id));
    return Array.from(uniqueClients).sort();
  }, [evaluations]);

  const filteredClients = useMemo(() => {
    return allClients.filter(clientId => 
      clientId.toLowerCase().includes(clientSearchQuery.toLowerCase())
    );
  }, [allClients, clientSearchQuery]);

  // В компоненте добавляем расчет статистики для всех клиентов
  const clientsWithStats = useMemo(() => {
    if (!evaluations?.length) return [];
    
    // Группируем оценки по клиентам и считаем статистику
    const statsMap = evaluations.reduce((acc, evaluation) => {
      const clientId = evaluation.client_id;
      if (!acc[clientId]) {
        acc[clientId] = {
          id: clientId,
          scores: [],
          totalCalls: 0
        };
      }
      
      acc[clientId].scores.push(evaluation.total_score * 100);
      acc[clientId].totalCalls += 1;
      
      return acc;
    }, {} as Record<string, { id: string; scores: number[]; totalCalls: number }>);

    // Преобразуем в массив и считаем средние значения
    return Object.values(statsMap).map(data => ({
      id: data.id,
      avgScore: data.scores.reduce((sum, score) => sum + score, 0) / data.scores.length,
      totalCalls: data.totalCalls
    }));
  }, [evaluations]);

  if (!clientStats) {
    return (
      <StatBox sx={{ p: 3, height: '100%', minHeight: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size={40} sx={{ color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE' }} />
      </StatBox>
    );
  }

  return (
    <>
      <StatBox sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 3 
        }}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Взаимодействия с клиентом
            </Typography>
            <Typography variant="h6" sx={{ 
              color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
              fontWeight: 600 
            }}>
              {selectedClientId}
            </Typography>
          </Box>
          <StyledActionButton
            onClick={() => setIsClientsModalOpen(true)}
            startIcon={<PersonIcon />}
            disableRipple
          >
            Выбрать клиента
          </StyledActionButton>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 3 }}>
          {/* Блоки статистики */}
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <StatBlock>
                <AssessmentIcon className="icon" />
                <Box>
                  <Typography className="value">{clientStats.avgScore.toFixed(1)}%</Typography>
                  <Typography className="label">Среднее</Typography>
                </Box>
              </StatBlock>
            </Grid>
            <Grid item xs={4}>
              <StatBlock>
                <PhoneInTalkIcon className="icon" />
                <Box>
                  <Typography className="value">{clientStats.totalCalls}</Typography>
                  <Typography className="label">Всего звонков</Typography>
                </Box>
              </StatBlock>
            </Grid>
            <Grid item xs={4}>
              <StatBlock>
                <PeopleAltIcon className="icon" />
                <Box>
                  <Typography className="value">{clientStats.totalManagers}</Typography>
                  <Typography className="label">Менеджеров</Typography>
                </Box>
              </StatBlock>
            </Grid>
          </Grid>

          {/* Список менеджеров */}
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {displayedManagers.map((manager, index) => (
              <Box key={manager.managerId}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography
                    sx={{
                      color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                      fontWeight: 600,
                      minWidth: '30px',
                      textAlign: 'center'
                    }}
                  >
                    #{index + 1}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{
                      minWidth: '300px',
                      maxWidth: '400px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {manager.managerId}
                  </Typography>
                  <Box sx={{ flex: 1 }}>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      mb: 0.5 
                    }}>
                      <Typography color="text.secondary">
                        {manager.totalCalls} звонков
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          minWidth: '60px',
                          textAlign: 'right'
                        }}
                      >
                        {manager.avgScore.toFixed(1)}%
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        height: 8,
                        bgcolor: theme => theme.palette.mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.1)' 
                          : 'rgba(0, 0, 0, 0.1)',
                        borderRadius: 4,
                        overflow: 'hidden',
                      }}
                    >
                      <Box
                        sx={{
                          width: `${manager.avgScore}%`,
                          height: '100%',
                          bgcolor: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                          borderRadius: 4,
                          transition: 'width 0.3s ease',
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          {/* Кнопка "Показать всех" */}
          {clientStats.totalManagers > 5 && (
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <StyledActionButton
                onClick={() => setIsModalOpen(true)}
                startIcon={<PeopleAltIcon />}
                disableRipple
              >
                Показать всех ({clientStats.totalManagers})
              </StyledActionButton>
            </Box>
          )}
        </Box>
      </StatBox>

      {/* Модальное окно выбора клиента */}
      <StyledDialog
        fullScreen={fullScreen}
        open={isClientsModalOpen}
        onClose={() => {
          setIsClientsModalOpen(false);
          setClientSearchQuery('');
        }}
        maxWidth="md"
        fullWidth
        disableScrollLock
        PaperProps={{
          sx: {
            margin: '16px',
            width: 'calc(100% - 32px)',
            maxWidth: '900px',
          }
        }}
      >
        <CloseButton 
          onClick={() => {
            setIsClientsModalOpen(false);
            setClientSearchQuery('');
          }}
        >
          <CloseIcon />
        </CloseButton>

        <DialogTitle sx={{ 
          m: 0, 
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          {/* Обновляем заголовок */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2
          }}>
            <Typography variant="h6" sx={{ flex: 1 }}>
              Все клиенты ({filteredClients.length})
            </Typography>
          </Box>
          <TextField
            fullWidth
            placeholder="Поиск клиента..."
            value={clientSearchQuery}
            onChange={(e) => setClientSearchQuery(e.target.value)}
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'text.secondary' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: theme => theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.03)' 
                  : 'rgba(0, 0, 0, 0.02)',
              }
            }}
          />
        </DialogTitle>

        <StyledDialogContent>
          {filteredClients.length === 0 ? (
            <Box className="empty-message">
              <Typography color="text.secondary">
                Клиенты не найдены
              </Typography>
            </Box>
          ) : (
            <Box className="client-list">
              {/* Заголовки столбцов */}
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 2,
                  mb: 2,
                  px: 1
                }}
              >
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '0.75rem',
                    minWidth: '30px',
                    textAlign: 'center'
                  }}
                >
                  №
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  flex: 1,
                  minWidth: 0
                }}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: '0.75rem',
                      flex: 1,
                      minWidth: 0
                    }}
                  >
                    ID клиента
                  </Typography>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    minWidth: '200px',
                    justifyContent: 'flex-end'
                  }}>
                    <Typography
                      sx={{
                        color: 'text.secondary',
                        fontSize: '0.75rem',
                        flex: 1,
                        textAlign: 'right'
                      }}
                    >
                      Звонков
                    </Typography>
                    <Typography
                      sx={{
                        color: 'text.secondary',
                        fontSize: '0.75rem',
                        minWidth: '60px',
                        textAlign: 'right'
                      }}
                    >
                      Средняя
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Список всех клиентов */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {filteredClients.map((clientId, index) => {
                  const clientStats = clientsWithStats.find(c => c.id === clientId);
                  
                  return (
                    <Box 
                      key={clientId}
                      onClick={() => {
                        onClientSelect?.(clientId);
                        setIsClientsModalOpen(false);
                        setClientSearchQuery('');
                      }}
                      sx={{ 
                        cursor: 'pointer',
                        position: 'relative',
                        ml: 3,
                        '&:hover': {
                          bgcolor: theme => theme.palette.mode === 'dark' 
                            ? 'rgba(255, 255, 255, 0.05)' 
                            : 'rgba(0, 0, 0, 0.02)',
                        },
                        borderRadius: 1,
                        p: 1
                      }}
                    >
                      {selectedClientId === clientId && (
                        <CheckCircleIcon
                          sx={{
                            color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                            fontSize: '20px',
                            position: 'absolute',
                            left: '-28px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            pointerEvents: 'none'
                          }}
                        />
                      )}
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography
                          sx={{
                            color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                            fontWeight: 600,
                            minWidth: '30px',
                            textAlign: 'center'
                          }}
                        >
                          #{index + 1}
                        </Typography>
                        <Typography
                          color="text.secondary"
                          sx={{
                            minWidth: '300px',
                            maxWidth: '400px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {clientId}
                        </Typography>
                        <Box sx={{ flex: 1 }}>
                          <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between',
                            mb: 0.5 
                          }}>
                            <Typography color="text.secondary">
                              {clientStats?.totalCalls || 0} звонков
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                minWidth: '60px',
                                textAlign: 'right'
                              }}
                            >
                              {clientStats?.avgScore.toFixed(1) || '0.0'}%
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: '100%',
                              height: 8,
                              bgcolor: theme => theme.palette.mode === 'dark' 
                                ? 'rgba(255, 255, 255, 0.1)' 
                                : 'rgba(0, 0, 0, 0.1)',
                              borderRadius: 4,
                              overflow: 'hidden',
                            }}
                          >
                            <Box
                              sx={{
                                width: `${clientStats?.avgScore || 0}%`,
                                height: '100%',
                                bgcolor: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                                borderRadius: 4,
                                transition: 'width 0.3s ease',
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </StyledDialogContent>
      </StyledDialog>

      {/* Модальное окно со списком менеджеров */}
      <StyledDialog
        fullScreen={fullScreen}
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setModalSearchQuery('');
        }}
        maxWidth="md"
        fullWidth
        disableScrollLock
        PaperProps={{
          sx: {
            margin: '16px',
            width: 'calc(100% - 32px)',
            maxWidth: '900px',
          }
        }}
      >
        <CloseButton onClick={() => {
          setIsModalOpen(false);
          setModalSearchQuery('');
        }}>
          <CloseIcon />
        </CloseButton>

        <DialogTitle sx={{ 
          m: 0, 
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          {/* Блок с общей статистикой */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StatBlock>
                <AssessmentIcon className="icon" />
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                    <Typography className="value">{clientStats.avgScore.toFixed(1)}%</Typography>
                  </Box>
                  <Typography className="label">Среднее</Typography>
                </Box>
              </StatBlock>
            </Grid>
            <Grid item xs={6}>
              <StatBlock>
                <PeopleAltIcon className="icon" />
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                    <Typography className="value">{clientStats.totalManagers}</Typography>
                  </Box>
                  <Typography className="label">Менеджеров</Typography>
                </Box>
              </StatBlock>
            </Grid>
          </Grid>

          {/* Обновляем заголовок */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2
          }}>
            <Typography variant="h6" sx={{ flex: 1 }}>
              Все менеджеры ({filteredModalManagers.length})
            </Typography>
          </Box>
          <TextField
            fullWidth
            placeholder="Поиск менеджера..."
            value={modalSearchQuery}
            onChange={(e) => setModalSearchQuery(e.target.value)}
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'text.secondary' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: theme => theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.03)' 
                  : 'rgba(0, 0, 0, 0.02)',
              }
            }}
          />
        </DialogTitle>

        <StyledDialogContent>
          {filteredModalManagers.length === 0 ? (
            <Box className="empty-message">
              <Typography color="text.secondary">
                Менеджеры не найдены
              </Typography>
            </Box>
          ) : (
            <Box className="manager-list">
              {/* Заголовки столбцов */}
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 2,
                  mb: 2,
                  px: 1
                }}
              >
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '0.75rem',
                    minWidth: '30px',
                    textAlign: 'center'
                  }}
                >
                  №
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  flex: 1,
                  minWidth: 0
                }}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: '0.75rem',
                      flex: 1,
                      minWidth: 0
                    }}
                  >
                    ID менеджера
                  </Typography>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    minWidth: '200px',
                    justifyContent: 'flex-end'
                  }}>
                    <Typography
                      sx={{
                        color: 'text.secondary',
                        fontSize: '0.75rem',
                        flex: 1,
                        textAlign: 'right'
                      }}
                    >
                      Звонков
                    </Typography>
                    <Typography
                      sx={{
                        color: 'text.secondary',
                        fontSize: '0.75rem',
                        minWidth: '60px',
                        textAlign: 'right'
                      }}
                    >
                      Средняя
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Список всех менеджеров */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {filteredModalManagers.map((manager, index) => (
                  <Box key={manager.managerId}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography
                        sx={{
                          color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                          fontWeight: 600,
                          minWidth: '30px',
                          textAlign: 'center'
                        }}
                      >
                        #{index + 1}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        sx={{
                          minWidth: '300px',
                          maxWidth: '400px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {manager.managerId}
                      </Typography>
                      <Box sx={{ flex: 1 }}>
                        <Box sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between',
                          mb: 0.5 
                        }}>
                          <Typography color="text.secondary">
                            {manager.totalCalls} звонков
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              minWidth: '60px',
                              textAlign: 'right'
                            }}
                          >
                            {manager.avgScore.toFixed(1)}%
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: '100%',
                            height: 8,
                            bgcolor: theme => theme.palette.mode === 'dark' 
                              ? 'rgba(255, 255, 255, 0.1)' 
                              : 'rgba(0, 0, 0, 0.1)',
                            borderRadius: 4,
                            overflow: 'hidden',
                          }}
                        >
                          <Box
                            sx={{
                              width: `${manager.avgScore}%`,
                              height: '100%',
                              bgcolor: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                              borderRadius: 4,
                              transition: 'width 0.3s ease',
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </StyledDialogContent>
      </StyledDialog>
    </>
  );
};

export default ClientsFlowChart; 
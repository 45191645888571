import React, { createContext, useContext, useState, useEffect } from 'react';
import { authService, User } from '../services/authService';
import { useNavigate, useLocation } from 'react-router-dom';

interface AuthContextType {
  user: User | null;
  token: string | null;
  teamId: string | null;
  isAuthenticated: boolean;
  login: (email: string, password: string, captchaToken: string) => Promise<void>;
  logout: () => Promise<void>;
  loading: boolean;
  setTeamId: (teamId: string | null) => void;
  updateUser: (user: User) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [teamId, setTeamId] = useState<string | null>(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loadStoredUser = async () => {
      try {
        console.log('🔐 Initial auth check');
        const storedUser = localStorage.getItem('user');
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        
        console.log('📦 Stored data check:', {
          hasUser: !!storedUser,
          hasAccessToken: !!accessToken,
          hasRefreshToken: !!refreshToken
        });
        
        if (storedUser && accessToken && refreshToken) {
          const userData = JSON.parse(storedUser);
          setUser(userData);
          setToken(accessToken);
          setTeamId(userData.teamId);
          
          try {
            await authService.validateToken();
            console.log('✅ Token validated successfully');
            
            if (location.pathname === '/login' || location.pathname === '/register') {
              navigate('/dashboard');
            }
          } catch (error) {
            console.log('🔄 Token invalid, attempting refresh...');
            try {
              const response = await authService.refreshToken(refreshToken);
              setToken(response.accessToken);
              localStorage.setItem('accessToken', response.accessToken);
              localStorage.setItem('refreshToken', response.refreshToken);
              console.log('✅ Tokens refreshed successfully');
            } catch (refreshError) {
              console.error('❌ Token refresh failed:', refreshError);
              logout();
              navigate('/login');
            }
          }
        } else {
          if (location.pathname !== '/login' && location.pathname !== '/register') {
            navigate('/login');
          }
        }
      } catch (error) {
        console.error('❌ Error loading stored user:', error);
        logout();
        navigate('/login');
      } finally {
        setTimeout(() => {
          setInitialLoading(false);
        }, 300);
      }
    };

    loadStoredUser();
  }, []);

  const login = async (email: string, password: string, captchaToken: string) => {
    try {
      if (!captchaToken) {
        throw new Error('Captcha token is required');
      }

      console.log('Attempting login with credentials:', { 
        hasEmail: !!email, 
        hasPassword: !!password,
        captchaTokenLength: captchaToken.length 
      });
      
      const response = await authService.login(email, password, captchaToken);
      
      setToken(response.tokens.accessToken);
      localStorage.setItem('accessToken', response.tokens.accessToken);
      localStorage.setItem('refreshToken', response.tokens.refreshToken);
      
      localStorage.setItem('user', JSON.stringify(response.user));
      setUser(response.user);
      setTeamId(response.user.teamId);
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await authService.logout();
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      setUser(null);
      setToken(null);
      setTeamId(null);
    }
  };

  const updateUser = (updatedUser: User) => {
    setUser(updatedUser);
  };

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        token,
        teamId,
        isAuthenticated: !!user && !!token, 
        login, 
        logout, 
        loading: initialLoading,
        setTeamId,
        updateUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 
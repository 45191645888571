import React from 'react';
import { IconButton, Menu, MenuItem, Theme } from '@mui/material';
import { Language } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.MuiMenuItem-root': {
    borderRadius: '8px',
    margin: '2px 8px',
    minHeight: '32px',
    width: 'calc(100% - 16px)',
    color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#121212',
    '&:first-of-type': {
      marginTop: '8px',
    },
    '&:last-child': {
      marginBottom: '8px',
    },
    '&.Mui-selected': {
      backgroundColor: `${theme.palette.primary.main}20`,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}30`,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.08)'
        : 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

const scrollbarStyles = (theme: Theme) => ({
  '&::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
    margin: '8px 0',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    border: '2px solid transparent',
    backgroundClip: 'padding-box',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
    },
  },
});

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          position: 'absolute',
          top: (theme: Theme) => theme.spacing(2),
          right: (theme: Theme) => theme.spacing(8),
          color: 'primary.main',
          backgroundColor: (theme: Theme) => 
            theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
          '&:hover': {
            backgroundColor: (theme: Theme) =>
              theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <Language />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            mt: 1.5,
            background: theme => theme.palette.mode === 'dark'
              ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.85) 0%, rgba(18, 18, 18, 0.85) 100%)'
              : 'rgba(255, 255, 255, 0.85)',
            backdropFilter: 'blur(20px)',
            border: 'none',
            borderRadius: '12px',
            minWidth: '120px',
            boxShadow: theme => theme.palette.mode === 'dark'
              ? '0 4px 24px rgba(0, 0, 0, 0.4)'
              : '0 4px 24px rgba(0, 0, 0, 0.05)',
            '& .MuiList-root': {
              padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
              px: 2,
              py: 0.75,
              mx: 1,
              my: 0.25,
              fontSize: '0.875rem',
              borderRadius: '8px',
              color: 'text.primary',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: 'transparent',
                transform: 'translateX(4px)',
              },
            },
          },
        }}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <MenuItem onClick={() => changeLanguage('ru')}>
          Русский
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('en')}>
          English
        </MenuItem>
      </Menu>
    </>
  );
};

export default LanguageSwitcher; 
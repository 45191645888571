import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  IconButton,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Dashboard,
  People,
  SmartToy,
  ChevronLeft,
} from '@mui/icons-material';
import LogoWhite from '../../assets/images/logo_white.png';
import LogoBlack from '../../assets/images/logo_black.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

const DRAWER_WIDTH = 240;
const COLLAPSED_WIDTH = 72;

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed'
})<{ isCollapsed: boolean }>(({ theme, isCollapsed }) => ({
  width: isCollapsed ? COLLAPSED_WIDTH : DRAWER_WIDTH,
  flexShrink: 0,
  border: 'none',
  transition: 'width 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '& .MuiDrawer-paper': {
    width: isCollapsed ? COLLAPSED_WIDTH : DRAWER_WIDTH,
    boxSizing: 'border-box',
    background: theme.palette.mode === 'dark' 
      ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.7) 0%, rgba(18, 18, 18, 0.7) 100%)'
      : 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(20px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '4px 0 12px rgba(0, 0, 0, 0.1)'
      : '4px 0 24px rgba(0, 0, 0, 0.05)',
    border: 'none',
    borderRadius: 0,
    margin: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'width 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    overflowX: 'hidden',
  },
}));

const LogoImage = styled('img')({
  width: '31px',
  height: '31px',
  marginRight: '12px',
  verticalAlign: 'middle',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  cursor: 'pointer',
  '&:hover': {
    transform: 'rotate(180deg)',
  }
});

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  '& img': {
    width: '31px',
    height: '31px',
  }
}));

// Добавляем интерфейс для пропсов
interface StyledListItemButtonProps {
  isCollapsed?: boolean;
}

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed'
})<StyledListItemButtonProps>(({ theme, isCollapsed = false }) => ({
  margin: '0 8px',
  borderRadius: '12px',
  marginBottom: 4,
  padding: '8px 12px',
  height: '40px',
  position: 'relative',
  WebkitTapHighlightColor: 'transparent',
  transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  display: 'flex',
  alignItems: 'center',

  '& .MuiListItemIcon-root': {
    color: theme.palette.text.secondary,
    minWidth: '40px',
    width: '40px',
    height: '28px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 0,
    transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    transform: 'translateX(0)',
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    }
  },

  '& .MuiListItemText-root': {
    marginLeft: '12px',
    flex: 'none',
    width: '140px',
    opacity: isCollapsed ? 0 : 1,
    visibility: isCollapsed ? 'hidden' : 'visible',
    transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    transform: isCollapsed ? 'translateX(-10px)' : 'translateX(0)',
  },

  '&:hover': {
    backgroundColor: 'transparent !important',
    color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
    '& .MuiListItemIcon-root': {
      color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
      transform: 'translateX(4px)',
    },
    '& .MuiListItemText-root': {
      color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
      transform: isCollapsed ? 'translateX(-10px)' : 'translateX(4px)',
    },
  },

  '&.Mui-selected': {
    backgroundColor: 'transparent !important',
    color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
    '& .MuiListItemIcon-root': {
      color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      right: -8,
      top: '50%',
      transform: 'translateY(-50%)',
      width: 3,
      height: '70%',
      backgroundColor: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
      borderRadius: '4px 0 0 4px',
      opacity: 0,
      animation: 'indicatorAppear 0.3s ease forwards',
    },
    '@keyframes indicatorAppear': {
      '0%': {
        opacity: 0,
        transform: 'translate(4px, -50%)',
      },
      '100%': {
        opacity: 1,
        transform: 'translate(0, -50%)',
      },
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
      '&::before': {
        right: -8,
      },
    },
  },

  '&:active': {
    backgroundColor: 'transparent !important',
  },
}));

const ToggleButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: -8,
  top: '50vh',
  transform: 'translateY(-50%)',
  width: 40,
  height: 40,
  backgroundColor: 'transparent',
  backdropFilter: 'none',
  boxShadow: 'none',
  border: 'none',
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
    transform: 'translateY(-50%) scale(1.2)',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
  '& svg': {
    fontSize: '2rem',
    color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
    transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    transform: 'rotate(0deg)',
    fontWeight: 'bold',
  },
  '&.rotated svg': {
    transform: 'rotate(180deg)',
  },
  transition: 'all 0.2s ease',
}));

const SoonChip = styled(Chip)(({ theme }) => ({
  height: '20px',
  padding: '0 8px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(220, 251, 1, 0.1)' 
    : 'rgba(121, 62, 238, 0.1)',
  color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
  fontSize: '0.75rem',
  fontWeight: 500,
  marginLeft: '8px',
  '& .MuiChip-label': {
    padding: '0',
  },
}));

const Sidebar: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { logout } = useAuth();
  const isDark = theme.palette.mode === 'dark';
  const { t } = useTranslation();

  const handleLogoClick = () => {
    navigate('/dashboard');
  };

  const toggleDrawer = () => {
    setIsCollapsed(!isCollapsed);
  };

  const menuItems = [
    {
      text: t('header.dashboard'),
      icon: <Dashboard />,
      path: '/dashboard',
    },
    {
      text: t('header.products'),
      icon: <SmartToy />,
      path: '/products',
    },
    {
      text: t('header.team'),
      icon: <People />,
      path: '/team',
      disabled: true,
      chip: <SoonChip label={t('filters.soon')} size="small" />
    },
  ];

  return (
    <StyledDrawer 
      variant="permanent" 
      isCollapsed={isCollapsed}
    >
      <Box sx={{ 
        p: '12px 24px',
        mb: 2,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}>
        <LogoContainer>
          <LogoImage 
            src={isDark ? LogoWhite : LogoBlack} 
            alt={t('sidebar.appName')} 
            onClick={handleLogoClick}
          />
          {!isCollapsed && (
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                fontSize: '1.1rem',
                whiteSpace: 'nowrap',
                color: theme => theme.palette.mode === 'dark' 
                  ? '#DCFB01'
                  : '#793EEE',
              }}
            >
              {t('sidebar.appName')}
            </Typography>
          )}
        </LogoContainer>
      </Box>
      
      <List sx={{ flexGrow: 1 }}>
        {menuItems.map((item) => (
          <StyledListItemButton
            key={item.text}
            selected={location.pathname === item.path}
            onClick={() => !item.disabled && navigate(item.path)}
            disableRipple
            isCollapsed={isCollapsed}
            disabled={item.disabled}
            sx={{
              opacity: item.disabled ? 0.5 : 1,
              cursor: item.disabled ? 'default' : 'pointer',
            }}
          >
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText 
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {item.text}
                  {!isCollapsed && item.chip}
                </Box>
              } 
              sx={{ 
                opacity: isCollapsed ? 0 : 1,
                visibility: isCollapsed ? 'hidden' : 'visible',
                transition: 'opacity 0.3s ease, visibility 0.3s ease',
              }} 
            />
          </StyledListItemButton>
        ))}
      </List>

      <ToggleButton 
        onClick={toggleDrawer}
        className={isCollapsed ? 'rotated' : ''}
        disableRipple
      >
        <ChevronLeft />
      </ToggleButton>
    </StyledDrawer>
  );
};

export default Sidebar; 
import React, { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { StatBox } from '../../styled';
import { CallStatistics, ChecklistConfig } from '../../../../../../services/callAnalyticsService';
import { useTranslation } from 'react-i18next';

interface Props {
  statistics?: CallStatistics[];
  checklistConfig?: ChecklistConfig | null;
}

interface Block {
  id: string;
  name: string;
  order: number;
  items: Array<{
    id: string;
    name: string;
    order: number;
    weight: number;
  }>;
}

interface BlockWithScore {
  id: string;
  name: string;
  score: number;
}

// Цвета для блоков
const getBlockColor = (blockId: string, mode: 'light' | 'dark') => {
  if (mode === 'dark') {
    switch (blockId) {
      case '01': return '#DCFB01'; // фирменный неоново-желтый
      case '02': return '#36F1CD'; // бирюзовый неон
      case '03': return '#3DD5FF'; // электрический голубой
      case '04': return '#A2FC7C'; // весенний зеленый
      case '05': return '#FFD53D'; // золотистый
      case '06': return '#96F7B3'; // мятный
      case '07': return '#59FFC1'; // аквамариновый
      case '08': return '#00E2B0'; // изумрудный неон
      default: return '#DCFB01';
    }
  } else {
    switch (blockId) {
      case '01': return '#793EEE'; // фирменный фиолетовый
      case '02': return '#2A6B9F'; // глубокий синий
      case '03': return '#00A7B5'; // морской волны
      case '04': return '#348553'; // малахитовый
      case '05': return '#0B877D'; // изумрудный
      case '06': return '#B86E00'; // янтарный
      case '07': return '#236C70'; // бирюзовый
      case '08': return '#1E754C'; // нефритовый
      default: return '#793EEE';
    }
  }
};

const BlocksAverage: React.FC<Props> = ({ statistics, checklistConfig }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const blockAverages = useMemo(() => {
    if (!statistics?.length || !checklistConfig?.blocks) return [];

    const blocks = ((checklistConfig.blocks as any).blocks || []) as Block[];
    
    // Считаем средние значения по каждому блоку
    return blocks
      .sort((a, b) => a.order - b.order)
      .map((block) => {
        const scores = statistics
          .map(stat => stat.block_scores[`block${block.id.replace(/^0/, '')}`] || 0)
          .filter(score => score !== undefined);

        const average = scores.length > 0
          ? Math.round(scores.reduce((sum, score) => sum + score, 0) / scores.length)
          : 0;

        return { 
          id: block.id, 
          name: block.name, 
          score: average 
        };
      });
  }, [statistics, checklistConfig]);

  // Функция для создания SVG path для дуги
  const describeArc = (score: number, radius: number, strokeWidth: number) => {
    const angleOffset = 0; // Начинаем с верхней точки
    const angle = (score / 100) * 360; // Преобразуем процент в градусы

    // Координаты центра
    const center = radius;

    // Вычисляем координаты начальной и конечной точек дуги
    const startAngle = angleOffset * (Math.PI / 180);
    const endAngle = (angleOffset + angle) * (Math.PI / 180);

    // Вычисляем координаты точек
    const startX = center + (radius - strokeWidth / 2) * Math.cos(startAngle);
    const startY = center + (radius - strokeWidth / 2) * Math.sin(startAngle);
    const endX = center + (radius - strokeWidth / 2) * Math.cos(endAngle);
    const endY = center + (radius - strokeWidth / 2) * Math.sin(endAngle);

    // Флаг для определения большой или малой дуги
    const largeArcFlag = angle <= 180 ? '0' : '1';

    return `M ${startX},${startY} A ${radius - strokeWidth / 2},${radius - strokeWidth / 2} 0 ${largeArcFlag} 1 ${endX},${endY}`;
  };

  return (
    <StatBox sx={{ 
      height: '100%', 
      minHeight: '240px',
      display: 'flex', 
      flexDirection: 'column',
      pb: 2
    }}>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        {t('callAnalytics.blocksAverage.title')}
      </Typography>

      {blockAverages.length > 0 ? (
        <Box sx={{ 
          display: 'flex', 
          flex: 1, 
          position: 'relative',
          mt: -1
        }}>
          {/* Контейнер для колец */}
          <Box sx={{ 
            position: 'relative',
            width: '50%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Box sx={{ position: 'relative', width: 200, height: 200 }}>
              {blockAverages.map((block: BlockWithScore, index: number) => {
                const size = 200 - index * 30;
                const strokeWidth = 12;
                const radius = size / 2;

                return (
                  <Box
                    key={block.id}
                    sx={{
                      position: 'absolute',
                      top: (200 - size) / 2,
                      left: (200 - size) / 2,
                      width: size,
                      height: size,
                    }}
                  >
                    {/* Фоновый круг */}
                    <svg width={size} height={size}>
                      <circle
                        cx={radius}
                        cy={radius}
                        r={radius - strokeWidth / 2}
                        fill="none"
                        stroke={theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'}
                        strokeWidth={strokeWidth}
                      />
                    </svg>
                    {/* Прогресс */}
                    <svg 
                      width={size} 
                      height={size} 
                      style={{ 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        transform: 'rotate(-90deg)',
                      }}
                    >
                      <path
                        d={describeArc(block.score, radius, strokeWidth)}
                        fill="none"
                        stroke={getBlockColor(block.id, theme.palette.mode)}
                        strokeWidth={strokeWidth}
                        strokeLinecap="round"
                      />
                    </svg>
                  </Box>
                );
              })}
            </Box>
          </Box>

          {/* Легенда */}
          <Box sx={{ 
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 2,
            pl: 4
          }}>
            {blockAverages.map((block: BlockWithScore) => (
              <Box
                key={block.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    backgroundColor: getBlockColor(block.id, theme.palette.mode),
                  }}
                />
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    {block.name}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    color: theme => theme.palette.mode === 'dark' 
                      ? '#fff' 
                      : '#000'
                  }}
                >
                  {block.score}%
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box sx={{ 
          flex: 1, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center' 
        }}>
          <Typography color="text.secondary">
            {t('callAnalytics.blocksAverage.noData')}
          </Typography>
        </Box>
      )}
    </StatBox>
  );
};

export default BlocksAverage; 
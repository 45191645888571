import React, { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Area, AreaChart, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { StatBox } from '../../styled';
import { useFilters } from '../../../context/FiltersContext';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface BlockScore {
  [key: string]: number; // динамические ключи для блоков
}

interface Statistics {
  date: string;
  block_scores: BlockScore;
}

interface ChartData {
  date: string;
  value: number;
}

const AverageScore: React.FC<{ statistics?: Statistics[] }> = ({ statistics }) => {
  const theme = useTheme();
  const { period } = useFilters();
  const { t, i18n } = useTranslation();

  // Преобразуем данные для графика
  const chartData: ChartData[] = useMemo(() => {
    if (!statistics?.length) {
      return [];
    }

    return statistics.map(stat => {
      const blockScores = Object.values(stat.block_scores || {});
      const averageValue = blockScores.length > 0
        ? Math.round(blockScores.reduce((sum, score) => sum + (score || 0), 0) / blockScores.length)
        : 0;

      return {
        date: new Date(stat.date).toLocaleDateString(i18n.language, {
          day: '2-digit',
          month: 'short'
        }),
        value: averageValue
      };
    }).sort((a, b) => {
      const dateA = new Date(a.date.split(' ')[0]);
      const dateB = new Date(b.date.split(' ')[0]);
      return dateA.getTime() - dateB.getTime();
    });
  }, [statistics, i18n.language]);

  // Вычисляем общее среднее
  const overallAverage = useMemo(() => {
    if (!statistics?.length) {
      return 0;
    }

    const totalSum = statistics.reduce((sum, stat) => {
      const blockScores = Object.values(stat.block_scores || {});
      return blockScores.length > 0
        ? sum + (blockScores.reduce((acc, val) => acc + (val || 0), 0) / blockScores.length)
        : sum;
    }, 0);

    return Math.round(totalSum / statistics.length);
  }, [statistics]);

  // Добавляем вычисление изменения относительно предыдущего периода
  const previousPeriodChange = useMemo(() => {
    if (!statistics?.length) return 0;

    // Сортируем статистику по дате
    const sortedStats = [...statistics].sort((a, b) => 
      new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    // Находим середину периода
    const midIndex = Math.floor(sortedStats.length / 2);
    
    // Вычисляем средние значения для двух периодов
    const currentPeriodAvg = sortedStats.slice(midIndex).reduce((sum, stat) => {
      const blockScores = Object.values(stat.block_scores || {});
      return blockScores.length > 0
        ? sum + (blockScores.reduce((acc, val) => acc + (val || 0), 0) / blockScores.length)
        : sum;
    }, 0) / (sortedStats.length - midIndex);

    const previousPeriodAvg = sortedStats.slice(0, midIndex).reduce((sum, stat) => {
      const blockScores = Object.values(stat.block_scores || {});
      return blockScores.length > 0
        ? sum + (blockScores.reduce((acc, val) => acc + (val || 0), 0) / blockScores.length)
        : sum;
    }, 0) / midIndex;

    // Вычисляем процент изменения
    return previousPeriodAvg ? 
      ((currentPeriodAvg - previousPeriodAvg) / previousPeriodAvg) * 100 : 
      0;
  }, [statistics]);

  const getColorByStatus = (change: number, theme: Theme) => {
    if (change === 0) return theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';
    return change > 0 
      ? theme.palette.success?.main || '#4caf50'
      : theme.palette.error?.main || '#f44336';
  };

  const formatPercentage = (value: number): string => {
    if (value === 0) return '0';
    return Math.abs(value).toFixed(2);
  };

  const getPeriodText = () => {
    switch (period) {
      case 'today':
        return t('callAnalytics.averageScore.periods.yesterday');
      case 'week':
        return t('callAnalytics.averageScore.periods.lastWeek');
      case 'month':
        return t('callAnalytics.averageScore.periods.lastMonth');
      case 'quarter':
        return t('callAnalytics.averageScore.periods.lastQuarter');
      case 'year':
        return t('callAnalytics.averageScore.periods.lastYear');
      case 'custom':
        return t('callAnalytics.averageScore.periods.lastPeriod');
      default:
        return t('callAnalytics.averageScore.periods.lastPeriod');
    }
  };

  return (
    <StatBox sx={{ height: '100%', minHeight: '300px', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            {t('callAnalytics.averageScore.title')}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
            <Typography 
              variant="h2" 
              sx={{ 
                fontWeight: 700,
                lineHeight: 1,
                color: theme => theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
            >
              {overallAverage}
            </Typography>
            <Typography 
              variant="body1"
              sx={{ 
                color: (theme: Theme) => theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.7)' 
                  : 'rgba(0, 0, 0, 0.6)',
                fontWeight: 500,
                fontSize: '1.25rem'
              }}
            >
              %
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {previousPeriodChange > 0 ? (
              <TrendingUpIcon sx={{ color: (theme: Theme) => getColorByStatus(previousPeriodChange, theme) }} />
            ) : previousPeriodChange < 0 ? (
              <TrendingDownIcon sx={{ color: (theme: Theme) => getColorByStatus(previousPeriodChange, theme) }} />
            ) : null}
            <Typography
              variant="body2"
              component="span"
              sx={{
                color: (theme: Theme) => getColorByStatus(previousPeriodChange, theme),
                fontWeight: 500,
              }}
            >
              {previousPeriodChange === 0 
                ? t('callAnalytics.averageScore.noChange')
                : `${formatPercentage(previousPeriodChange)}%`
              }
            </Typography>
            {previousPeriodChange !== 0 && (
              <Typography
                variant="body2"
                component="span"
                sx={{ color: 'text.secondary' }}
              >
                {t('callAnalytics.averageScore.vs')} {getPeriodText()}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ flex: 1, minHeight: 0, mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {period === 'today' ? (
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ textAlign: 'center' }}
          >
            {t('callAnalytics.averageScore.noChart')}
          </Typography>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart 
              data={chartData}
              margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorCalls" x1="0" y1="0" x2="0" y2="1">
                  <stop 
                    offset="5%" 
                    stopColor={theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE'} 
                    stopOpacity={0.2}
                  />
                  <stop 
                    offset="95%" 
                    stopColor={theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE'} 
                    stopOpacity={0.05}
                  />
                </linearGradient>
              </defs>
              <CartesianGrid 
                strokeDasharray="3 3" 
                vertical={false}
                stroke={theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'}
              />
              <XAxis 
                dataKey="date" 
                axisLine={false}
                tickLine={false}
                tick={{ 
                  fill: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                  fontSize: 12 
                }}
                dy={10}
              />
              <YAxis 
                axisLine={false}
                tickLine={false}
                tick={{ 
                  fill: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                  fontSize: 12 
                }}
                width={45}
                dx={-10}
                tickFormatter={(value) => `${value}%`}
              />
              <Tooltip 
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <Box
                        sx={{
                          backgroundColor: (theme: Theme) => theme.palette.mode === 'dark' ? '#1E1E1E' : '#FFF',
                          border: (theme: Theme) => `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
                          p: 1.5,
                          borderRadius: '8px',
                          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        <Typography variant="body2" sx={{ mb: 0.5, fontWeight: 600 }}>
                          {payload[0].payload.date}
                        </Typography>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: (theme: Theme) => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                            fontWeight: 600,
                          }}
                        >
                          {payload[0].value}%
                        </Typography>
                      </Box>
                    );
                  }
                  return null;
                }}
              />
              <Area
                type="monotone"
                dataKey="value"
                stroke={theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE'}
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#colorCalls)"
                dot={false}
                activeDot={{
                  r: 4,
                  fill: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                  strokeWidth: 0
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </Box>
    </StatBox>
  );
};

export default AverageScore; 
import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../context/ThemeContext';
import LogoWhite from '../../assets/images/logo_white.png';
import LogoBlack from '../../assets/images/logo_black.png';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import { logoBackgroundStyles } from '../../styles/globalStyles';
import AnimatedEye from '../../components/AnimatedEye/AnimatedEye';
import axios from 'axios';
import { isValidEmail } from '../../utils/validation';
import { TranslationKey, LoginErrorKey } from '../../types/i18n';
import YandexCaptcha from '../../components/YandexCaptcha/YandexCaptcha';
import { TOptions } from 'i18next';

const LoginContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  margin: 0,
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
  ...logoBackgroundStyles(theme, theme.palette.mode === 'dark'),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  width: '100%',
  maxWidth: '400px',
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.85) 0%, rgba(18, 18, 18, 0.85) 100%)'
    : 'rgba(255, 255, 255, 0.85)',
  backdropFilter: 'blur(20px)',
  border: 'none',
  borderRadius: '24px',
  position: 'relative',
  zIndex: 1,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 24px rgba(0, 0, 0, 0.4)'
    : '0 4px 24px rgba(0, 0, 0, 0.05)',
  transform: 'none',
}));

const textFieldStyles = (theme: any) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    '& input': {
      color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#121212',
    },
    '& fieldset': {
      borderColor: `${theme.palette.primary.main}40`,
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main}80`,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: `${theme.palette.primary.main}B3`,
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
  },
});

const ThemeToggleButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
  zIndex: 2,
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
  },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(3),
  left: '50%',
  transform: 'translateX(-50%)',
  minWidth: '300px',
  maxWidth: '90%',
  zIndex: 1100,
  borderRadius: '12px',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 24px rgba(0, 0, 0, 0.4)'
    : '0 4px 24px rgba(0, 0, 0, 0.1)',
  animation: 'slideDown 0.3s ease-out',
  '@keyframes slideDown': {
    '0%': {
      transform: 'translate(-50%, -100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translate(-50%, 0)',
      opacity: 1,
    },
  },
}));

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { themeMode, toggleTheme } = useTheme();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState<LoginErrorKey | null>(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!formData.email || !formData.password) {
      setError('auth.enterCredentials');
      return;
    }

    if (!isValidEmail(formData.email)) {
      setError('auth.invalidEmail');
      return;
    }

    if (!captchaVerified || !captchaToken) {
      setShowCaptcha(true);
    } else {
      await performLogin(captchaToken);
    }
  };

  const performLogin = async (token: string) => {
    setLoading(true);
    try {
      await login(formData.email, formData.password, token);
      navigate('/dashboard');
    } catch (err) {
      console.error('Login error:', err);
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          setError('auth.invalidCredentials');
          setCaptchaVerified(false);
          setCaptchaToken(null);
        } else if (err.response?.status === 400) {
          if (err.response.data?.message?.includes('Captcha')) {
            setError('auth.captchaFailed');
            setCaptchaVerified(false);
            setCaptchaToken(null);
            setShowCaptcha(true);
          } else {
            setError('auth.loginError');
            setCaptchaVerified(false);
            setCaptchaToken(null);
          }
        } else {
          setError('auth.loginError');
          setCaptchaVerified(false);
          setCaptchaToken(null);
        }
      } else {
        setError('auth.loginError');
        setCaptchaVerified(false);
        setCaptchaToken(null);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCaptchaSuccess = async (token: string) => {
    setCaptchaToken(token);
    setCaptchaVerified(true);
    setShowCaptcha(false);
    await performLogin(token);
  };

  const handleCaptchaClose = () => {
    setShowCaptcha(false);
  };

  const handleChange = (field: keyof typeof formData) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, [field]: e.target.value });
    setError(null);
    setCaptchaVerified(false);
    setCaptchaToken(null);
  };

  return (
    <LoginContainer>
      <ThemeToggleButton onClick={toggleTheme}>
        {themeMode === 'dark' ? <Brightness7 /> : <Brightness4 />}
      </ThemeToggleButton>
      <LanguageSwitcher />

      <Box sx={{ position: 'relative', width: '100%', maxWidth: '400px' }}>
        {error && (
          <StyledAlert 
            severity="error" 
            onClose={() => setError(null)}
          >
            {t(error, {} as TOptions)}
          </StyledAlert>
        )}
        
        <StyledPaper>
          <Typography variant="h4" sx={{ mb: 4, textAlign: 'center', fontWeight: 700, color: 'primary.main' }}>
            {t('auth.loginTitle')}
          </Typography>

          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                fullWidth
                label={t('auth.email')}
                value={formData.email}
                onChange={handleChange('email')}
                disabled={loading}
                sx={textFieldStyles}
                autoComplete="email"
                name="email"
                type="email"
              />
              <TextField
                fullWidth
                label={t('auth.password')}
                type={showPassword ? "text" : "password"}
                value={formData.password}
                onChange={handleChange('password')}
                disabled={loading}
                sx={textFieldStyles}
                autoComplete="current-password"
                name="password"
                InputProps={{
                  endAdornment: (
                    <AnimatedEye 
                      isOpen={showPassword}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ),
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading}
                sx={{ 
                  mt: 2,
                  borderRadius: '12px',
                  padding: '12px',
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.mode === 'dark' ? '#121212' : '#FFFFFF',
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.primary.dark,
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: '#121212' }} />
                ) : (
                  t('auth.login')
                )}
              </Button>

              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                  {t('auth.noAccount')}
                </Typography>
                <Button
                  variant="text"
                  onClick={() => navigate('/register')}
                  sx={{
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {t('auth.register')}
                </Button>
              </Box>
            </Box>
          </form>
        </StyledPaper>

        <YandexCaptcha
          visible={showCaptcha}
          onSuccess={handleCaptchaSuccess}
          onClose={handleCaptchaClose}
        />
      </Box>
    </LoginContainer>
  );
};

export default Login; 
import React from 'react';
import { Grid } from '@mui/material';
import Statistics from './Statistics';

const MainTab: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Statistics />
      {/* Здесь будут другие секции главной вкладки */}
    </Grid>
  );
};

export default MainTab; 
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Avatar,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Alert,
  LinearProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  TablePagination,
  Skeleton,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { TOptions } from 'i18next';
import { Close as CloseIcon, CheckCircle, Cancel, Edit as EditIcon, Lock as LockIcon } from '@mui/icons-material';
import { authService } from '../../services/authService';
import AnimatedEye from '../../components/AnimatedEye/AnimatedEye';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  background: theme.palette.mode === 'dark' 
    ? 'rgba(30, 30, 30, 0.85)'
    : 'rgba(255, 255, 255, 0.85)',
  backdropFilter: 'blur(20px)',
  border: 'none',
  borderRadius: '24px',
  height: '100%',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 24px rgba(0, 0, 0, 0.4)'
    : '0 4px 24px rgba(0, 0, 0, 0.05)',
}));

const textFieldStyles = (theme: Theme) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    '& input': {
      color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#121212',
    },
    '& fieldset': {
      borderColor: `${theme.palette.primary.main}40`,
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main}80`,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: `${theme.palette.primary.main}B3`,
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
  },
});

// Определяем тип для ключей индустрий
type IndustryKey = 
  | 'industry.it'
  | 'industry.finance'
  | 'industry.healthcare'
  | 'industry.education'
  | 'industry.manufacturing'
  | 'industry.retail'
  | 'industry.construction'
  | 'industry.energy'
  | 'industry.transportation'
  | 'industry.telecom'
  | 'industry.agriculture'
  | 'industry.media'
  | 'industry.hospitality'
  | 'industry.consulting'
  | 'industry.realestate'
  | 'industry.legal'
  | 'industry.entertainment'
  | 'industry.research'
  | 'industry.aerospace'
  | 'industry.pharmaceutical'
  | 'industry.other';

// Массив всех возможных индустрий
const INDUSTRIES: IndustryKey[] = [
  'industry.it',
  'industry.finance',
  'industry.healthcare',
  'industry.education',
  'industry.manufacturing',
  'industry.retail',
  'industry.construction',
  'industry.energy',
  'industry.transportation',
  'industry.telecom',
  'industry.agriculture',
  'industry.media',
  'industry.hospitality',
  'industry.consulting',
  'industry.realestate',
  'industry.legal',
  'industry.entertainment',
  'industry.research',
  'industry.aerospace',
  'industry.pharmaceutical',
  'industry.other'
];

const InfoBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const InfoItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '12px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.03)'
    : 'rgba(121, 62, 238, 0.03)',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.05)'
      : 'rgba(121, 62, 238, 0.05)',
    transform: 'translateY(-2px)',
  },
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  marginBottom: theme.spacing(0.25),
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 500,
}));

// Обновляем стилизованный Dialog
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(4),
    background: theme.palette.mode === 'dark'
      ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)'
      : 'rgba(255, 255, 255, 0.85)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '24px',
    position: 'relative',
    zIndex: 1,
    boxShadow: theme.palette.mode === 'dark'
      ? '0 4px 24px rgba(0, 0, 0, 0.4)'
      : '0 4px 24px rgba(0, 0, 0, 0.05)',
    '& .MuiDialogTitle-root': {
      padding: 0,
      paddingBottom: theme.spacing(3),
      color: theme.palette.text.primary,
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      paddingTop: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: 0,
      paddingTop: theme.spacing(3),
    },
    transition: 'box-shadow 0.3s ease',
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(10px)',
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(0, 0, 0, 0.8)'
      : 'rgba(255, 255, 255, 0.8)',
  },
}));

// Обновляем стилизованный Alert с анимациями
const StyledAlert = styled(Alert, {
  shouldForwardProp: (prop) => prop !== 'isExiting'
})<{ isExiting?: boolean }>(({ theme, isExiting }) => ({
  position: 'fixed',
  top: '80px',
  right: theme.spacing(2),
  minWidth: '200px',
  maxWidth: '400px',
  borderRadius: '12px',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 24px rgba(0, 0, 0, 0.4)'
    : '0 4px 24px rgba(0, 0, 0, 0.1)',
  zIndex: theme.zIndex.snackbar,
  animation: isExiting ? 'slideOut 0.5s ease-in-out forwards' : 'slideIn 0.5s ease-in-out',
  '@keyframes slideIn': {
    '0%': {
      transform: 'translateX(100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateX(0)',
      opacity: 1,
    },
  },
  '@keyframes slideOut': {
    '0%': {
      transform: 'translateX(0)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateX(100%)',
      opacity: 0,
    },
  },
  '& .MuiAlert-message': {
    fontSize: '0.875rem',
  },
  '& .MuiAlert-icon': {
    fontSize: '1.25rem',
  },
}));

// Обновляем стили для индикатора пароля
const PasswordStrengthIndicator = styled(LinearProgress)(({ theme }) => ({
  marginTop: '8px',
  marginBottom: '16px',
  height: '4px',
  borderRadius: '2px',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.05)'
    : 'rgba(0, 0, 0, 0.05)',
  '& .MuiLinearProgress-bar': {
    borderRadius: '2px',
    transition: 'all 0.4s ease',
  },
  '& .MuiLinearProgress-barColorPrimary': {
    background: theme.palette.primary.main,
  },
  '& .MuiLinearProgress-barColorError': {
    background: theme.palette.error.main,
  },
  '& .MuiLinearProgress-barColorSuccess': {
    background: theme.palette.success.main,
  },
  '& .MuiLinearProgress-barColorWarning': {
    background: theme.palette.warning.main,
  },
}));

// Стили для контейнера требований к паролю
const PasswordRequirements = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.03)'
    : 'rgba(0, 0, 0, 0.02)',
  borderRadius: '12px',
  border: `1px solid ${theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.05)'
    : 'rgba(0, 0, 0, 0.05)'
  }`,
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.04)'
      : 'rgba(0, 0, 0, 0.03)',
  },
}));

const RequirementItem = styled(Box)<{ isMet: boolean }>(({ theme, isMet }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: isMet ? theme.palette.success.main : theme.palette.error.main,
  fontSize: '0.875rem',
  marginBottom: '8px',
  padding: '4px 0',
  transition: 'color 0.2s ease',
  '&:last-child': {
    marginBottom: 0,
  },
  '& .MuiSvgIcon-root': {
    fontSize: '18px',
    transition: 'transform 0.2s ease',
  },
  '&:hover .MuiSvgIcon-root': {
    transform: 'scale(1.1)',
  },
}));

// Добавляем новый интерфейс для логов активнси
interface ActivityLog {
  id: string;
  activityType: string;
  ipAddress: string;
  userAgent: string;
  deviceInfo: string;
  createdAt: string;
}

// Обновляем стили для таблицы активности
const ActivityTable = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
  },
  '& .MuiTableHead-root .MuiTableCell-root': {
    borderBottom: 'none',
    padding: theme.spacing(1.5),
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent',
  },
  '& .MuiTableBody-root .MuiTableCell-root': {
    borderBottom: 'none',
    padding: theme.spacing(1.5),
    fontSize: '0.875rem',
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.03)'
      : 'rgba(0, 0, 0, 0.02)',
    '&:first-of-type': {
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '12px',
      borderBottomRightRadius: '12px',
    },
  },
  '& .MuiTableRow-root': {
    transition: 'all 0.2s ease',
    '&:hover .MuiTableCell-root': {
      backgroundColor: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : 'rgba(0, 0, 0, 0.03)',
    },
  },
  // Стили для пагинации
  '& .MuiTablePagination-root': {
    borderTop: `1px solid ${theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.05)' 
      : 'rgba(0, 0, 0, 0.05)'}`,
    marginTop: theme.spacing(2),
  },
  '& .MuiTablePagination-toolbar': {
    padding: theme.spacing(2, 0),
  },
  '& .MuiTablePagination-displayedRows': {
    fontSize: '0.875rem',
  },
  '& .MuiTablePagination-actions': {
    marginLeft: theme.spacing(2),
  }
}));

interface UserActivity {
  lastLogin: string | null;
  accountCreated: string;
  status: 'active' | 'inactive';
}

// Добавим новый компонент для анимированного скелетона
const AnimatedSkeleton = styled(Skeleton)(({ theme }) => ({
  '@keyframes skeletonFadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(10px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  '@keyframes skeletonFadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateY(-10px)',
    },
  },
  animation: 'skeletonFadeIn 0.3s ease-out forwards',
  '&.MuiSkeleton-pulse': {
    animation: 'none',
  },
  '&.exiting': {
    animation: 'skeletonFadeOut 0.3s ease-in forwards',
  },
}));

// Обновим стили для ActivityItem
const ActivityItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '12px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.03)'
    : 'rgba(121, 62, 238, 0.03)',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.05)'
      : 'rgba(121, 62, 238, 0.05)',
    transform: 'translateY(-2px)',
  },
}));

const Profile: React.FC = () => {
  const { user, updateUser } = useAuth();
  const { t, i18n } = useTranslation();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [editData, setEditData] = useState({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [isAlertExiting, setIsAlertExiting] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [activityLogs, setActivityLogs] = useState<ActivityLog[]>([]);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [userActivity, setUserActivity] = useState<UserActivity | null>(null);
  const [loadingActivity, setLoadingActivity] = useState(false);

  // Функция для сброса состояния модального окна редактирования профия
  const resetEditDialog = () => {
    setEditData({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
    });
    setEditDialogOpen(false);
  };

  const handleEditProfile = async () => {
    try {
      setLoading(true);
      setError(null);
      const updatedUser = await authService.updateProfile(editData);
      updateUser(updatedUser);
      setSuccess(t('profile.updateSuccess'));
      resetEditDialog();
    } catch (err) {
      setError(t('profile.updateError'));
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    try {
      setLoading(true);
      setError(null);
      await authService.changePassword(passwordData);
      setSuccess(t('profile.passwordChangeSuccess'));
      resetPasswordDialog();
    } catch (err) {
      setError(t('profile.passwordChangeError'));
    } finally {
      setLoading(false);
    }
  };

  // Функция для получения ключа индустрии
  const getIndustryKey = (industry: string | undefined): IndustryKey => {
    const key = `industry.${industry?.toLowerCase()}` as IndustryKey;
    return INDUSTRIES.includes(key) ? key : 'industry.other';
  };

  const handleCloseAlert = () => {
    setIsAlertExiting(true);
    setTimeout(() => {
      setSuccess(null);
      setError(null);
      setIsAlertExiting(false);
    }, 500); // Время анимации
  };

  // Обновляем эффект для автоматического скрытия уведомления
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (success) {
      timer = setTimeout(() => {
        handleCloseAlert();
      }, 5000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [success]);

  // Тот же эффект для сообщений об ошибках
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (error) {
      timer = setTimeout(() => {
        handleCloseAlert();
      }, 5000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [error]);

  // Функция проверки силы пароля
  const checkPasswordStrength = (password: string) => {
    const hasMinLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    
    if (!password) return { strength: 0, color: 'error' };
    
    let strength = 0;
    if (hasMinLength) strength += 34;
    if (hasUpperCase) strength += 33;
    if (hasNumber) strength += 33;

    let color = 'error';
    if (strength > 90) color = 'success';
    else if (strength > 50) color = 'warning';

    return { strength, color };
  };

  // Функция для сброса состояния модального окна с паролем
  const resetPasswordDialog = () => {
    setPasswordData({
      currentPassword: '',
      newPassword: '',
    });
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setPasswordDialogOpen(false);
  };

  // Добавляем функцию загрузки логов
  const fetchActivityLogs = async () => {
    try {
      setLoadingLogs(true);
      const response = await authService.getActivityLogs();
      setActivityLogs(response.data);
    } catch (error) {
      console.error('Failed to fetch activity logs:', error);
    } finally {
      setLoadingLogs(false);
    }
  };

  // Добавляем useEffect для загрузки логов при монтировании
  useEffect(() => {
    fetchActivityLogs();
  }, []);

  // В компоненте Profile добавим функцию форматирования даты
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString(i18n.language, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  };

  // Добавляем обработчик изменения страницы
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  // Добавляем обработчик открытия диалога смены пароля
  const handleOpenPasswordDialog = () => {
    setPasswordDialogOpen(true);
  };

  // Перемещаем функци для полуения времени последнего изменения пароля
  const getLastPasswordChange = (logs: ActivityLog[]): string | null => {
    const passwordChangeLog = logs.find(log => log.activityType === 'password_change');
    return passwordChangeLog?.createdAt || null;
  };

  // Перемещаем функцию для форматирования времени "прошло с момента"
  const getTimeAgo = (dateString: string | null): string => {
    if (!dateString) return t('profile.security.password.never');
    
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60
    };

    for (const [unit, seconds] of Object.entries(intervals)) {
      const interval = Math.floor(diffInSeconds / seconds);
      if (interval >= 1) {
        return t(`common.timeAgo.${unit}`, { count: interval });
      }
    }
    
    return t('common.timeAgo.justNow');
  };

  // Добавляем функцию загрузки активности
  const fetchUserActivity = async () => {
    try {
      setLoadingActivity(true);
      const activity = await authService.getUserActivity();
      setUserActivity(activity);
    } catch (error) {
      console.error('Failed to fetch user activity:', error);
    } finally {
      setLoadingActivity(false);
    }
  };

  // Добавляем useEffect для загрузки активности
  useEffect(() => {
    fetchUserActivity();
  }, []);

  return (
    <Box sx={{ minHeight: '100vh', p: 3, bgcolor: 'transparent' }}>
      {(success || error) && (
        <StyledAlert 
          severity={success ? "success" : "error"}
          onClose={handleCloseAlert}
          isExiting={isAlertExiting}
        >
          {success || error}
        </StyledAlert>
      )}
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <StyledPaper sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              gap: 2,
              width: '100%' 
            }}>
              <Avatar
                sx={{
                  width: 120,
                  height: 120,
                  bgcolor: 'primary.main',
                  fontSize: '3rem',
                }}
              >
                {user?.firstName?.[0] || 'U'}
              </Avatar>
              <Typography variant="h6" sx={{ color: 'text.primary' }}>
                {user?.firstName} {user?.lastName}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {user?.email}
              </Typography>
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={8}>
          <StyledPaper>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              mb: 3 
            }}>
              <Typography variant="h6" sx={{ color: 'primary.main' }}>
                {t('profile.companyInfo.title')}
              </Typography>
              <Button 
                variant="contained" 
                color="primary" 
                size="small" 
                onClick={() => setEditDialogOpen(true)}
                startIcon={<EditIcon />}
                sx={{
                  borderRadius: '12px',
                  px: 2,
                  py: 1,
                  textTransform: 'none',
                  fontWeight: 500,
                }}
              >
                {t('profile.companyInfo.editButton')}
              </Button>
            </Box>

            <InfoBlock>
              <InfoItem>
                <InfoLabel>{t('profile.personalInfo.companyName')}</InfoLabel>
                <InfoValue>{user?.companyName}</InfoValue>
              </InfoItem>

              <InfoItem>
                <InfoLabel>{t('profile.personalInfo.companyIndustry')}</InfoLabel>
                <InfoValue>
                  {t(getIndustryKey(user?.companyIndustry))}
                </InfoValue>
              </InfoItem>
            </InfoBlock>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={4}>
          <StyledPaper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" sx={{ mb: 3, color: 'primary.main' }}>
              {t('profile.activity.title')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
              <ActivityItem>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ mb: 0.25 }}
                    >
                      {t('profile.activity.lastLogin')}
                    </Typography>
                    {loadingActivity ? (
                      <AnimatedSkeleton 
                        variant="text" 
                        width={120}
                        height={20}
                        sx={{ 
                          bgcolor: theme => theme.palette.mode === 'dark'
                            ? 'rgba(220, 251, 1, 0.1)'
                            : 'rgba(121, 62, 238, 0.1)',
                          borderRadius: '8px'
                        }} 
                      />
                    ) : (
                      <Typography variant="body2" fontWeight={500}>
                        {userActivity?.lastLogin 
                          ? formatDate(userActivity.lastLogin)
                          : t('profile.activity.noLoginYet')}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </ActivityItem>

              <ActivityItem>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ mb: 0.25 }}
                    >
                      {t('profile.activity.accountCreated')}
                    </Typography>
                    {loadingActivity ? (
                      <AnimatedSkeleton 
                        variant="text" 
                        width={120}
                        height={20}
                        sx={{ 
                          bgcolor: theme => theme.palette.mode === 'dark'
                            ? 'rgba(220, 251, 1, 0.1)'
                            : 'rgba(121, 62, 238, 0.1)',
                          borderRadius: '8px'
                        }} 
                      />
                    ) : (
                      <Typography variant="body2" fontWeight={500}>
                        {userActivity?.accountCreated && formatDate(userActivity.accountCreated)}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </ActivityItem>
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={8}>
          <StyledPaper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" sx={{ mb: 3, color: 'primary.main' }}>
              {t('profile.security.title')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.security.password.title')}
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleOpenPasswordDialog}
                    startIcon={<LockIcon />}
                  >
                    {t('profile.security.password.button')}
                  </Button>
                </Box>
                {loadingLogs ? (
                  <Skeleton 
                    variant="text" 
                    width="60%" 
                    height={24}
                    sx={{ 
                      bgcolor: theme => theme.palette.mode === 'dark'
                        ? 'rgba(220, 251, 1, 0.1)'
                        : 'rgba(121, 62, 238, 0.1)',
                      borderRadius: '12px'
                    }} 
                  />
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    {t('profile.security.password.lastChanged', {
                      time: getTimeAgo(getLastPasswordChange(activityLogs))
                    })}
                  </Typography>
                )}
              </Box>

              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                      <Typography variant="subtitle1" fontWeight={500}>
                        {t('profile.security.twoFactor.title')}
                      </Typography>
                      <Box
                        sx={{
                          px: 1,
                          py: 0.5,
                          bgcolor: theme => theme.palette.mode === 'dark'
                            ? 'rgba(220, 251, 1, 0.1)'
                            : 'rgba(121, 62, 238, 0.1)',
                          borderRadius: '8px',
                          fontSize: '0.75rem',
                          color: theme => theme.palette.mode === 'dark'
                            ? 'primary.main'
                            : '#793EEE',
                          fontWeight: 500,
                        }}
                      >
                        {t('profile.security.twoFactor.soon')}
                      </Box>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {t('profile.security.twoFactor.description')}
                    </Typography>
                  </Box>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    size="small"
                    disabled
                  >
                    {t('profile.security.twoFactor.button')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12}>
          <StyledPaper>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 3, 
                color: 'primary.main',
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Box 
                component="span" 
                sx={{ 
                  width: 4, 
                  height: 24, 
                  backgroundColor: 'primary.main',
                  borderRadius: '4px',
                  mr: 1
                }} 
              />
              {t('profile.activityHistory.title')}
            </Typography>
            <ActivityTable>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="25%">{t('profile.activityHistory.type')}</TableCell>
                    <TableCell width="30%">{t('profile.activityHistory.date')}</TableCell>
                    <TableCell width="20%">{t('profile.activityHistory.ip')}</TableCell>
                    <TableCell width="25%">{t('profile.activityHistory.device')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingLogs ? (
                    <>
                      {[...Array(5)].map((_, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Skeleton 
                              variant="text" 
                              height={24}
                              sx={{ 
                                bgcolor: theme => theme.palette.mode === 'dark'
                                  ? 'rgba(220, 251, 1, 0.1)'
                                  : 'rgba(121, 62, 238, 0.1)',
                                borderRadius: '12px'
                              }} 
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton 
                              variant="text" 
                              height={24}
                              sx={{ 
                                bgcolor: theme => theme.palette.mode === 'dark'
                                  ? 'rgba(220, 251, 1, 0.1)'
                                  : 'rgba(121, 62, 238, 0.1)',
                                borderRadius: '12px'
                              }} 
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton 
                              variant="text" 
                              height={24}
                              sx={{ 
                                bgcolor: theme => theme.palette.mode === 'dark'
                                  ? 'rgba(220, 251, 1, 0.1)'
                                  : 'rgba(121, 62, 238, 0.1)',
                                borderRadius: '12px'
                              }} 
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton 
                              variant="text" 
                              height={24}
                              sx={{ 
                                bgcolor: theme => theme.palette.mode === 'dark'
                                  ? 'rgba(220, 251, 1, 0.1)'
                                  : 'rgba(121, 62, 238, 0.1)',
                                borderRadius: '12px'
                              }} 
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : activityLogs.length === 0 ? (
                    <TableRow>
                      <TableCell 
                        colSpan={4} 
                        align="center" 
                        sx={{ 
                          py: 6,
                          color: 'text.secondary',
                          fontSize: '0.875rem'
                        }}
                      >
                        {t('profile.activityHistory.noActivity')}
                      </TableCell>
                    </TableRow>
                  ) : (
                    activityLogs
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((log) => (
                        <TableRow key={log.id}>
                          <TableCell>
                            <Typography 
                              component="span" 
                              sx={{ 
                                color: 'primary.main',
                                fontWeight: 500
                              }}
                            >
                              {t(`profile.activityHistory.types.${log.activityType}`)}
                            </Typography>
                          </TableCell>
                          <TableCell>{formatDate(log.createdAt)}</TableCell>
                          <TableCell>{log.ipAddress}</TableCell>
                          <TableCell>{log.deviceInfo}</TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
              {activityLogs.length > 0 && (
                <TablePagination
                  component="div"
                  count={activityLogs.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5]}
                  labelDisplayedRows={({ from, to, count }) => 
                    t('common.pagination', { from, to, count })}
                  sx={{
                    '.MuiTablePagination-select': {
                      display: 'none'
                    },
                    '.MuiTablePagination-selectLabel': {
                      display: 'none'
                    }
                  }}
                />
              )}
            </ActivityTable>
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Edit Profile Dialog */}
      <StyledDialog 
        open={editDialogOpen} 
        onClose={resetEditDialog}
        maxWidth="xs"
        fullWidth
        disableScrollLock
        PaperProps={{
          sx: {
            margin: '16px',
            width: 'calc(100% - 32px)',
            maxWidth: '400px',
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 3, position: 'relative' }}>
          {t('profile.editDialog.title')}
          <IconButton
            onClick={resetEditDialog}
            sx={{
              position: 'absolute',
              right: 16,
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'text.secondary',
              transition: 'all 0.2s ease',
              '&:hover': {
                color: 'text.primary',
                backgroundColor: 'transparent',
                transform: 'translateY(-50%) scale(1.1)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('profile.personalInfo.firstName')}
                value={editData.firstName}
                onChange={(e) => setEditData({ ...editData, firstName: e.target.value })}
                sx={textFieldStyles}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('profile.personalInfo.lastName')}
                value={editData.lastName}
                onChange={(e) => setEditData({ ...editData, lastName: e.target.value })}
                sx={textFieldStyles}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('profile.personalInfo.email')}
                value={editData.email}
                onChange={(e) => setEditData({ ...editData, email: e.target.value })}
                sx={textFieldStyles}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetEditDialog}>
            {t('common.cancel')}
          </Button>
          <Button 
            onClick={handleEditProfile} 
            variant="contained" 
            disabled={loading}
          >
            {t('common.save')}
          </Button>
        </DialogActions>
      </StyledDialog>

      {/* Change Password Dialog */}
      <StyledDialog 
        open={passwordDialogOpen} 
        onClose={resetPasswordDialog}
        maxWidth="xs"
        fullWidth
        disableScrollLock
        PaperProps={{
          sx: {
            margin: '16px',
            width: 'calc(100% - 32px)',
            maxWidth: '400px',
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 3, position: 'relative' }}>
          {t('profile.security.password.title')}
          <IconButton
            onClick={resetPasswordDialog}
            sx={{
              position: 'absolute',
              right: 16,
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'text.secondary',
              transition: 'all 0.2s ease',
              '&:hover': {
                color: 'text.primary',
                backgroundColor: 'transparent',
                transform: 'translateY(-50%) scale(1.1)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type={showCurrentPassword ? "text" : "password"}
                label={t('profile.security.password.current')}
                value={passwordData.currentPassword}
                onChange={(e) => setPasswordData({ ...passwordData, currentPassword: e.target.value })}
                sx={textFieldStyles}
                InputProps={{
                  endAdornment: (
                    <AnimatedEye 
                      isOpen={showCurrentPassword}
                      onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box>
                <TextField
                  fullWidth
                  type={showNewPassword ? "text" : "password"}
                  label={t('profile.security.password.new')}
                  value={passwordData.newPassword}
                  onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })}
                  sx={textFieldStyles}
                  error={passwordData.newPassword.length > 0 && checkPasswordStrength(passwordData.newPassword).strength < 100}
                  InputProps={{
                    endAdornment: (
                      <AnimatedEye 
                        isOpen={showNewPassword}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    ),
                  }}
                />
                <PasswordRequirements>
                  <PasswordStrengthIndicator
                    variant="determinate"
                    value={checkPasswordStrength(passwordData.newPassword).strength}
                    color={checkPasswordStrength(passwordData.newPassword).color as any}
                  />
                  <RequirementItem isMet={passwordData.newPassword.length >= 8}>
                    {passwordData.newPassword.length >= 8 ? (
                      <CheckCircle color="inherit" />
                    ) : (
                      <Cancel color="inherit" />
                    )}
                    {t('register.passwordMinLength')}
                  </RequirementItem>
                  <RequirementItem isMet={/[A-Z]/.test(passwordData.newPassword)}>
                    {/[A-Z]/.test(passwordData.newPassword) ? (
                      <CheckCircle color="inherit" />
                    ) : (
                      <Cancel color="inherit" />
                    )}
                    {t('register.passwordUpperCase')}
                  </RequirementItem>
                  <RequirementItem isMet={/\d/.test(passwordData.newPassword)}>
                    {/\d/.test(passwordData.newPassword) ? (
                      <CheckCircle color="inherit" />
                    ) : (
                      <Cancel color="inherit" />
                    )}
                    {t('register.passwordNumber')}
                  </RequirementItem>
                </PasswordRequirements>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetPasswordDialog}>
            {t('common.cancel')}
          </Button>
          <Button 
            onClick={handleChangePassword} 
            variant="contained" 
            disabled={loading}
          >
            {t('common.save')}
          </Button>
        </DialogActions>
      </StyledDialog>
    </Box>
  );
};

export default Profile; 
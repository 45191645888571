import React, { useCallback, useEffect, useState, useRef } from 'react';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface YandexCaptchaProps {
  visible: boolean;
  onSuccess: (token: string) => void;
  onClose: () => void;
}

type SupportedLanguages = 'ru' | 'en' | 'be' | 'kk' | 'tt' | 'uk' | 'uz' | 'tr';

const YandexCaptcha: React.FC<YandexCaptchaProps> = ({ visible, onSuccess, onClose }) => {
  const [resetKey, setResetKey] = useState(0);
  const captchaContainerRef = useRef<HTMLDivElement>(null);
  const [isScriptReady, setIsScriptReady] = useState(false);
  const { i18n } = useTranslation();

  // Получаем текущий язык и проверяем, поддерживается ли он капчей
  const getCurrentLanguage = (): SupportedLanguages => {
    const currentLang = i18n.language.split('-')[0]; // Берем основной язык без региона
    const supportedLangs: SupportedLanguages[] = ['ru', 'en', 'be', 'kk', 'tt', 'uk', 'uz', 'tr'];
    
    return supportedLangs.includes(currentLang as SupportedLanguages) 
      ? (currentLang as SupportedLanguages) 
      : 'en'; // Если язык не поддерживается, используем английский
  };

  const resetCaptcha = useCallback(() => {
    setResetKey(prev => prev + 1);
  }, []);

  useEffect(() => {
    const checkScriptReady = () => {
      if (window.smartCaptcha) {
        setIsScriptReady(true);
      } else {
        setTimeout(checkScriptReady, 100);
      }
    };

    checkScriptReady();
  }, []);

  useEffect(() => {
    if (visible) {
      resetCaptcha();
      console.log('Captcha reset due to visibility or language change');
    }
  }, [visible, i18n.language, resetCaptcha]);

  const handleSuccess = useCallback((token: string) => {
    console.log('Captcha success, generating new token');
    onSuccess(token);
  }, [onSuccess]);

  const handleChallengeHidden = useCallback(() => {
    console.log('Captcha challenge hidden');
    onClose();
  }, [onClose]);

  const handleJavascriptError = useCallback((error: { 
    message: string;
    filename: string;
    line: number;
    col: number;
  }) => {
    console.error('Captcha javascript error:', error);
    resetCaptcha();
  }, [resetCaptcha]);

  const handleNetworkError = useCallback(() => {
    console.error('Captcha network error occurred');
    resetCaptcha();
  }, [resetCaptcha]);

  if (!isScriptReady) {
    return null;
  }

  return (
    <Box 
      ref={captchaContainerRef}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
        pointerEvents: visible ? 'auto' : 'none',
        opacity: visible ? 1 : 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <InvisibleSmartCaptcha
        key={resetKey}
        sitekey={process.env.REACT_APP_YANDEX_CAPTCHA_KEY || ''}
        visible={visible}
        language={getCurrentLanguage()}
        onSuccess={handleSuccess}
        onChallengeHidden={handleChallengeHidden}
        onJavascriptError={handleJavascriptError}
        onNetworkError={handleNetworkError}
      />
    </Box>
  );
};

export default YandexCaptcha;

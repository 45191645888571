import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import { StatBox } from '../../../components/styled';

const ClientsTabSkeleton: React.FC = () => {
  return (
    <Grid container spacing={3}>
      {/* Заголовок */}
      <Grid item xs={12}>
        <Skeleton 
          variant="text" 
          width={220} 
          height={32}
          sx={{ mb: 1 }} 
        />
      </Grid>

      {/* Скелетон для BarChart */}
      <Grid item xs={12} md={6}>
        <StatBox sx={{ 
          p: 3, 
          height: '100%', 
          minHeight: '405px',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 3 }}>
            {/* Статистика */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box sx={{ p: 2, borderRadius: 2, bgcolor: 'rgba(255, 255, 255, 0.03)' }}>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                      <Skeleton variant="text" width={80} height={40} />
                      <Skeleton variant="text" width={120} height={24} />
                    </Box>
                    <Skeleton variant="text" width={60} height={20} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ p: 2, borderRadius: 2, bgcolor: 'rgba(255, 255, 255, 0.03)' }}>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                      <Skeleton variant="text" width={80} height={40} />
                      <Skeleton variant="text" width={120} height={24} />
                    </Box>
                    <Skeleton variant="text" width={60} height={20} />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* Список */}
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
              {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Skeleton variant="text" width={30} />
                  <Box sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Skeleton variant="text" width={200} />
                      <Skeleton variant="text" width={60} />
                    </Box>
                    <Skeleton variant="rectangular" height={8} sx={{ borderRadius: 1 }} />
                  </Box>
                </Box>
              ))}
            </Box>

            {/* Кнопка "Показать всех" - вынесена из списка */}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <Skeleton 
                variant="rectangular" 
                width={200} 
                height={36} 
                sx={{ borderRadius: 2 }} 
              />
            </Box>
          </Box>
        </StatBox>
      </Grid>

      {/* Скелетон для FlowChart */}
      <Grid item xs={12} md={6}>
        <StatBox sx={{ 
          p: 3, 
          height: '100%', 
          minHeight: '405px',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 3 }}>
            {/* Заголовок */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Box>
                <Skeleton variant="text" width={140} height={20} sx={{ mb: 1 }} />
                <Skeleton variant="text" width={180} height={28} />
              </Box>
              <Skeleton variant="rectangular" width={140} height={36} sx={{ borderRadius: 2 }} />
            </Box>

            {/* Статистика */}
            <Grid container spacing={2}>
              {[...Array(3)].map((_, index) => (
                <Grid item xs={4} key={index}>
                  <Box sx={{ p: 2, borderRadius: 2, bgcolor: 'rgba(255, 255, 255, 0.03)' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Skeleton variant="circular" width={28} height={28} />
                      <Box>
                        <Skeleton variant="text" width={60} height={32} />
                        <Skeleton variant="text" width={80} height={20} />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>

            {/* Список */}
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
              {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Skeleton variant="text" width={30} />
                  <Box sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Skeleton variant="text" width={200} />
                      <Skeleton variant="text" width={60} />
                    </Box>
                    <Skeleton variant="rectangular" height={8} sx={{ borderRadius: 1 }} />
                  </Box>
                </Box>
              ))}
            </Box>

            {/* Кнопка "Показать всех" - вынесена из списка */}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <Skeleton 
                variant="rectangular" 
                width={200} 
                height={36} 
                sx={{ borderRadius: 2 }} 
              />
            </Box>
          </Box>
        </StatBox>
      </Grid>

      {/* Скелетон для таблицы */}
      <Grid item xs={12}>
        <StatBox>
          {/* Заголовок и поиск */}
          <Box sx={{ mb: 3 }}>
            <Skeleton variant="text" width={160} height={24} sx={{ mb: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
              <Box sx={{ display: 'flex', gap: 2, flex: 1 }}>
                <Skeleton variant="rectangular" width={300} height={36} sx={{ borderRadius: 2 }} />
                <Skeleton variant="rectangular" width={200} height={36} sx={{ borderRadius: 2 }} />
              </Box>
              <Skeleton variant="rectangular" width={140} height={36} sx={{ borderRadius: 2 }} />
            </Box>
          </Box>

          {/* Таблица */}
          <Box>
            {/* Заголовки */}
            <Box sx={{ 
              display: 'flex', 
              p: 2, 
              borderRadius: '4px 4px 0 0',
              bgcolor: 'rgba(255, 255, 255, 0.03)',
              borderBottom: '2px solid rgba(255, 255, 255, 0.1)'
            }}>
              {[300, 100, 100, 100, 100].map((width, index) => (
                <Skeleton 
                  key={index}
                  variant="text" 
                  width={width} 
                  sx={{ flex: index === 0 ? 1 : 'none' }}
                />
              ))}
            </Box>

            {/* Строки */}
            {[...Array(10)].map((_, index) => (
              <Box key={index} sx={{ display: 'flex', p: 2, borderBottom: 1, borderColor: 'divider' }}>
                {[300, 100, 100, 100, 100].map((width, cellIndex) => (
                  <Skeleton 
                    key={cellIndex}
                    variant="text" 
                    width={width} 
                    sx={{ flex: cellIndex === 0 ? 1 : 'none' }}
                  />
                ))}
              </Box>
            ))}
          </Box>

          {/* Пагинация */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Skeleton variant="rectangular" width={300} height={32} sx={{ borderRadius: 2 }} />
          </Box>
        </StatBox>
      </Grid>
    </Grid>
  );
};

export default ClientsTabSkeleton; 
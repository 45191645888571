import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Alert,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Brightness4, Brightness7, Logout } from '@mui/icons-material';
import { useTheme } from '../../context/ThemeContext';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import LogoWhite from '../../assets/images/logo_white.png';
import LogoBlack from '../../assets/images/logo_black.png';
import { logoBackgroundStyles } from '../../styles/globalStyles';
import { accessRequestService } from '../../services/accessRequestService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const RequestContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  minWidth: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  margin: 0,
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
  ...logoBackgroundStyles(theme, theme.palette.mode === 'dark'),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '600px',
  width: '100%',
  background: theme.palette.mode === 'dark' 
    ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)'
    : theme.palette.background.paper,
  backdropFilter: 'blur(10px)',
  border: 'none',
  borderRadius: '24px',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 24px rgba(0, 0, 0, 0.4)'
    : '0 4px 24px rgba(0, 0, 0, 0.05)',
  position: 'relative',
}));

const ThemeToggleButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
  },
}));

const Logo = styled('img')({
  height: '32px',
  marginRight: '12px',
});

const LogoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '32px',
});

const BrandText = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  fontFamily: '"Inter Tight", sans-serif',
  fontWeight: 700,
  letterSpacing: '-0.02em',
  color: theme.palette.primary.main,
  '& span.plume, & span.eyes': {
    color: 'inherit',
    fontFamily: '"Inter Tight", sans-serif',
    fontWeight: 700,
  },
  '& span.eyes': {
    marginLeft: '8px',
  },
}));

const TopRightControls = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center'
}));

const LogoutButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
  },
  zIndex: 2,
}));

const RequestAccess: React.FC = () => {
  const { themeMode, toggleTheme } = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [requestStatus, setRequestStatus] = useState<string | null>(null);
  const navigate = useNavigate();
  const { setTeamId, logout } = useAuth();

  useEffect(() => {
    const checkExistingRequest = async () => {
      try {
        const response = await accessRequestService.getStatus();
        if (response.request) {
          setRequestStatus(response.request.status);
          setSuccess(true);
        }
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 404) {
          setError(t('requestAccess.error'));
        }
      }
    };

    checkExistingRequest();
  }, [t]);

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const response = await accessRequestService.checkAccessStatus();
        if (response.accessStatus.hasAccess) {
          setTeamId(response.accessStatus.teamId);
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Error checking access status:', error);
      }
    };

    checkAccess();

    const interval = setInterval(checkAccess, 60000);

    return () => clearInterval(interval);
  }, [navigate, setTeamId]);

  const handleRequestAccess = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await accessRequestService.createRequest();
      setSuccess(true);
      setRequestStatus(response.request.status);
    } catch (err) {
      console.error('Access request error:', err);
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 409) {
          setError(t('requestAccess.alreadyExists'));
        } else {
          setError(t('requestAccess.error'));
        }
      } else {
        setError(t('requestAccess.error'));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <RequestContainer>
      <TopRightControls>
        <LanguageSwitcher />
        <ThemeToggleButton onClick={toggleTheme}>
          {themeMode === 'dark' ? <Brightness7 /> : <Brightness4 />}
        </ThemeToggleButton>
      </TopRightControls>

      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            position: 'fixed', 
            top: 16, 
            left: '50%', 
            transform: 'translateX(-50%)',
            zIndex: 1000 
          }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}

      <StyledPaper>
        <LogoutButton 
          onClick={handleLogout}
          title={t('auth.logout' as const)}
        >
          <Logout />
        </LogoutButton>

        <LogoContainer>
          <BrandText>
            NeuroPlume Eyes
          </BrandText>
        </LogoContainer>

        <Typography 
          variant="body1" 
          sx={{ 
            mb: 4, 
            color: 'text.primary',
            textAlign: 'center',
            lineHeight: 1.6
          }}
        >
          {success 
            ? t('requestAccess.success')
            : t('requestAccess.description')}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            size="large"
            onClick={handleRequestAccess}
            disabled={loading || success}
            sx={{
              minWidth: 200,
              height: 48,
              borderRadius: '12px',
              backgroundColor: theme => theme.palette.primary.main,
              color: theme => theme.palette.mode === 'dark' ? '#121212' : '#FFFFFF',
              '&:hover': {
                backgroundColor: theme => theme.palette.primary.dark,
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: '#121212' }} />
            ) : success ? (
              t('requestAccess.button.sent')
            ) : (
              t('requestAccess.button.default')
            )}
          </Button>
        </Box>
      </StyledPaper>
    </RequestContainer>
  );
};

export default RequestAccess; 
import React, { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { StatBox } from '../../styled';
import { CallStatistics, CallEvaluation } from '../../../../../../services/callAnalyticsService';
import { useFilters } from '../../../context/FiltersContext';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { useTranslation } from 'react-i18next';

interface Props {
  statistics?: CallStatistics[];
  evaluations?: CallEvaluation[];
}

const CallsCount: React.FC<Props> = ({ statistics, evaluations }) => {
  const theme = useTheme();
  const { period } = useFilters();
  const { t, i18n } = useTranslation();

  // Подготавливаем данные для графика
  const chartData = useMemo(() => {
    if (!evaluations?.length) return [];

    const callsByDate = evaluations.reduce((acc, evaluation) => {
      const date = evaluation.evaluated_at.split('T')[0];
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    return Object.entries(callsByDate)
      .map(([date, count]) => ({
        date: new Date(date).toLocaleDateString(i18n.language, {
          day: '2-digit',
          month: 'short'
        }),
        calls: count,
        fullDate: new Date(date),
      }))
      .sort((a, b) => a.fullDate.getTime() - b.fullDate.getTime());
  }, [evaluations, i18n.language]);

  // Вычисляем общее количество звонков
  const totalCalls = useMemo(() => {
    return evaluations?.length || 0;
  }, [evaluations]);

  // Вычисляем изменение относительно предыдущего периода
  const previousPeriodChange = useMemo(() => {
    if (!evaluations?.length) return 0;

    // Сортируем оценки по дате
    const sortedEvaluations = [...evaluations].sort((a, b) => 
      new Date(a.evaluated_at).getTime() - new Date(b.evaluated_at).getTime()
    );

    // Находим самую раннюю и последнюю даты
    const firstDate = new Date(sortedEvaluations[0].evaluated_at);
    const lastDate = new Date(sortedEvaluations[sortedEvaluations.length - 1].evaluated_at);

    // Вычисляем середину периода
    const totalDays = (lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24);
    const midDate = new Date(firstDate.getTime() + (totalDays / 2) * (1000 * 60 * 60 * 24));

    // Разделяем звонки на два периода
    const currentPeriodCalls = sortedEvaluations.filter(evaluation => 
      new Date(evaluation.evaluated_at) > midDate
    ).length;

    const previousPeriodCalls = sortedEvaluations.filter(evaluation => 
      new Date(evaluation.evaluated_at) <= midDate
    ).length;

    // Вычисляем процент изменения
    return previousPeriodCalls ? 
      ((currentPeriodCalls - previousPeriodCalls) / previousPeriodCalls) * 100 : 
      0;
  }, [evaluations]);

  // Функция для получения текста периода
  const getPeriodText = () => {
    switch (period) {
      case 'today':
        return t('callAnalytics.callsCount.periods.yesterday');
      case 'week':
        return t('callAnalytics.callsCount.periods.lastWeek');
      case 'month':
        return t('callAnalytics.callsCount.periods.lastMonth');
      case 'quarter':
        return t('callAnalytics.callsCount.periods.lastQuarter');
      case 'year':
        return t('callAnalytics.callsCount.periods.lastYear');
      case 'custom':
        return t('callAnalytics.callsCount.periods.lastPeriod');
      default:
        return t('callAnalytics.callsCount.periods.lastPeriod');
    }
  };

  const getColorByStatus = (change: number, theme: Theme) => {
    if (change === 0) return theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';
    return change > 0 
      ? theme.palette.success?.main || '#4caf50'
      : theme.palette.error?.main || '#f44336';
  };

  const formatPercentage = (value: number): string => {
    if (value === 0) return '0';
    
    // Всегда показываем 2 знака после запятой
    return Math.abs(value).toFixed(2);
  };

  return (
    <StatBox sx={{ height: '100%', minHeight: '300px', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            {t('callAnalytics.callsCount.title')}
          </Typography>
          <Typography variant="h4" sx={{ fontWeight: 700 }}>
            {totalCalls}
          </Typography>
        </Box>
        <Box sx={{ 
          display: 'flex', 
          gap: 1, 
          alignItems: 'center',
        }}>
          {previousPeriodChange !== 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {previousPeriodChange > 0 ? (
                <TrendingUpIcon sx={{ color: theme => getColorByStatus(previousPeriodChange, theme) }} />
              ) : (
                <TrendingDownIcon sx={{ color: theme => getColorByStatus(previousPeriodChange, theme) }} />
              )}
              <Typography
                variant="body2"
                component="span"
                sx={{
                  color: theme => getColorByStatus(previousPeriodChange, theme),
                  fontWeight: 500,
                }}
              >
                {formatPercentage(previousPeriodChange)}%
              </Typography>
              <Typography
                variant="body2"
                component="span"
                sx={{ color: 'text.secondary' }}
              >
                {t('callAnalytics.callsCount.vs')} {getPeriodText()}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ flex: 1, minHeight: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {period === 'today' ? (
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ textAlign: 'center' }}
          >
            {t('callAnalytics.callsCount.noChart')}
          </Typography>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart 
              data={chartData}
              margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorCalls2" x1="0" y1="0" x2="0" y2="1">
                  <stop 
                    offset="5%" 
                    stopColor={theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE'} 
                    stopOpacity={0.2}
                  />
                  <stop 
                    offset="95%" 
                    stopColor={theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE'} 
                    stopOpacity={0.05}
                  />
                </linearGradient>
              </defs>
              <CartesianGrid 
                strokeDasharray="3 3" 
                vertical={false}
                stroke={theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'}
              />
              <XAxis 
                dataKey="date" 
                axisLine={false}
                tickLine={false}
                tick={{ 
                  fill: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                  fontSize: 12 
                }}
                dy={10}
              />
              <YAxis 
                axisLine={false}
                tickLine={false}
                tick={{ 
                  fill: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                  fontSize: 12 
                }}
                width={45}
                dx={-10}
              />
              <Tooltip 
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <Box
                        sx={{
                          backgroundColor: theme => theme.palette.mode === 'dark' ? '#1E1E1E' : '#FFF',
                          border: theme => `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
                          p: 1.5,
                          borderRadius: '8px',
                          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        <Typography variant="body2" sx={{ mb: 0.5, fontWeight: 600 }}>
                          {payload[0].payload.date}
                        </Typography>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                            fontWeight: 600,
                          }}
                        >
                          {payload[0].value} {t('callAnalytics.callsCount.calls')}
                        </Typography>
                      </Box>
                    );
                  }
                  return null;
                }}
              />
              <Area
                type="monotone"
                dataKey="calls"
                stroke={theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE'}
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#colorCalls2)"
                dot={false}
                activeDot={{
                  r: 4,
                  fill: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                  strokeWidth: 0
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </Box>
    </StatBox>
  );
};

export default CallsCount; 
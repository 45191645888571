import { Box, styled } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const StatBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '16px',
  background: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.03)'
    : 'rgba(0, 0, 0, 0.02)',
}));

export const StyledActionButton = muiStyled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: theme.spacing(1, 2),
  textTransform: 'none',
  fontWeight: 500,
  color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
  border: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    transform: 'translateY(-1px)',
  },
  transition: 'all 0.2s ease',
})); 
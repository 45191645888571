import React, { useMemo, useState } from 'react';
import { 
  Box, 
  Typography, 
  useTheme,
  TextField,
  InputAdornment,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { StatBox, StyledActionButton } from '../../../components/styled';
import { styled, Theme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import CloseIcon from '@mui/icons-material/Close';
import { CallEvaluation } from '../../../../../../services/callAnalyticsService';
import SwapVertIcon from '@mui/icons-material/SwapVert';

interface Props {
  evaluations?: CallEvaluation[];
  onClientSelect?: (clientId: string) => void;
  selectedClientId?: string;
}

interface ClientData {
  scores: number[];
  callsCount: number;
}

interface ProcessedClient {
  id: string;
  score: number;
  callsCount: number;
}

// Функция для склонения слова "звонок"
const getCallsText = (count: number): string => {
  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return 'звонков';
  }

  if (lastDigit === 1) {
    return 'звонок';
  }
  if (lastDigit >= 2 && lastDigit <= 4) {
    return 'звонка';
  }
  return 'звонков';
};

// Стилизованный блок статистики
const StatBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.03)' 
    : 'rgba(0, 0, 0, 0.02)',
  borderRadius: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  '& .icon': {
    color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
    fontSize: '28px',
  },
  '& .value': {
    fontSize: '1.5rem',
    fontWeight: 600,
    color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
  },
  '& .label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  }
}));

// Индикатор изменения
const ChangeIndicator = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  fontSize: '0.75rem',
  fontWeight: 500,
  '& .MuiSvgIcon-root': {
    fontSize: '1rem'
  }
}));

// Добавляем стили для модального окна
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(4),
    background: theme.palette.mode === 'dark'
      ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(18, 18, 18, 0.95) 100%)'
      : 'rgba(255, 255, 255, 0.85)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '24px',
    position: 'relative',
    zIndex: 1,
    boxShadow: theme.palette.mode === 'dark'
      ? '0 4px 24px rgba(0, 0, 0, 0.4)'
      : '0 4px 24px rgba(0, 0, 0, 0.05)',
    '& .MuiDialogTitle-root': {
      padding: 0,
      paddingBottom: theme.spacing(3),
      color: theme.palette.text.primary,
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      paddingTop: theme.spacing(2),
    },
    transition: 'box-shadow 0.3s ease',
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(10px)',
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(0, 0, 0, 0.8)'
      : 'rgba(255, 255, 255, 0.8)',
  },
}));

// Добавляем стили для скроллбара
const scrollbarStyles = (theme: Theme) => ({
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    '&:hover': {
      background: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.2)' 
        : 'rgba(0, 0, 0, 0.2)',
    },
  },
  '&::-webkit-scrollbar-corner': {
    background: 'transparent',
  },
});

// Обновляем стили для контента модального окна
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  height: '60vh',
  maxHeight: '600px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2, 0),
  '& .search-field': {
    padding: theme.spacing(0, 2),
  },
  '& .manager-list': {
    flex: 1,
    overflow: 'auto',
    paddingRight: theme.spacing(2),
    marginRight: -theme.spacing(2),
    paddingLeft: theme.spacing(2),
    ...scrollbarStyles(theme),
  },
  '& .empty-message': {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  }
}));

// Добавляем стиль для кнопки закрытия
const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: theme.spacing(1),
  color: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.7)' 
    : 'rgba(0, 0, 0, 0.7)',
  '&:hover': {
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    backgroundColor: 'transparent',
  },
}));

const ClientsBarChart: React.FC<Props> = ({ evaluations, onClientSelect, selectedClientId }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortAscending, setSortAscending] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const clients = useMemo(() => {
    if (!evaluations?.length) return [];

    // Группируем оценки по клиентам
    const clientsMap = evaluations.reduce<Record<string, ClientData>>((acc, evaluation) => {
      const clientId = evaluation.client_id;
      if (!acc[clientId]) {
        acc[clientId] = {
          scores: [],
          callsCount: 0
        };
      }

      // Собираем все оценки
      Object.values(evaluation.scores || {}).forEach(score => {
        acc[clientId].scores.push(score);
      });
      acc[clientId].callsCount += 1;

      return acc;
    }, {});

    // Вычисляем средние значения
    return Object.entries(clientsMap)
      .map(([id, data]): ProcessedClient => ({
        id,
        score: Math.round(
          data.scores.reduce((sum: number, score: number) => sum + score, 0) / 
          (data.scores.length || 1) * 100
        ),
        callsCount: data.callsCount
      }))
      .sort((a, b) => b.score - a.score);
  }, [evaluations]);

  // Обновляем логику сортировки
  const topClients = useMemo(() => {
    if (!clients) return [];
    return clients
      .filter(client => client.id.toLowerCase().includes(searchQuery.toLowerCase()))
      .sort((a, b) => sortAscending ? a.score - b.score : b.score - a.score)
      .slice(0, 5);
  }, [clients, searchQuery, sortAscending]);

  // Обновляем логику сортировки для модального окна
  const modalClients = useMemo(() => {
    if (!clients) return [];
    return clients
      .filter(client => client.id.toLowerCase().includes(searchQuery.toLowerCase()))
      .sort((a, b) => sortAscending ? a.score - b.score : b.score - a.score);
  }, [clients, searchQuery, sortAscending]);

  // Обновляем расчет статистики
  const stats = useMemo(() => {
    if (!evaluations?.length) return { average: 0, total: 0, change: 0 };

    // Сортируем оценки по дате
    const sortedEvaluations = [...evaluations].sort((a, b) => 
      new Date(a.evaluated_at).getTime() - new Date(b.evaluated_at).getTime()
    );

    // Группируем данные по дням для более точного расчета
    const dailyData = sortedEvaluations.reduce((acc, evaluation) => {
      const date = evaluation.evaluated_at.split('T')[0];
      if (!acc[date]) {
        acc[date] = {
          scores: {},
          count: 0
        };
      }

      // Собираем все оценки за день
      Object.entries(evaluation.scores || {}).forEach(([itemId, score]) => {
        const blockId = itemId.split('_')[0];
        if (!acc[date].scores[blockId]) {
          acc[date].scores[blockId] = [];
        }
        acc[date].scores[blockId].push(score);
      });
      acc[date].count++;

      return acc;
    }, {} as Record<string, { scores: Record<string, number[]>, count: number }>);

    // Вычисляем средние значения по дням
    const dailyAverages = Object.entries(dailyData).map(([date, data]) => {
      const blockAverages = Object.values(data.scores).map(scores => 
        scores.reduce((sum, score) => sum + score, 0) / scores.length
      );
      return {
        date: new Date(date),
        average: blockAverages.length 
          ? Math.round(blockAverages.reduce((sum, avg) => sum + avg, 0) / blockAverages.length * 100)
          : 0
      };
    }).sort((a, b) => a.date.getTime() - b.date.getTime());

    // Разделяем на два периода
    const midPoint = Math.floor(dailyAverages.length / 2);
    const currentPeriod = dailyAverages.slice(midPoint);
    const previousPeriod = dailyAverages.slice(0, midPoint);

    // Считаем средние значения для периодов
    const calculatePeriodAverage = (data: typeof dailyAverages) => {
      if (!data.length) return 0;
      return Math.round(
        data.reduce((sum, day) => sum + day.average, 0) / data.length
      );
    };

    const currentAverage = calculatePeriodAverage(currentPeriod);
    const previousAverage = calculatePeriodAverage(previousPeriod);

    // Считаем общую статистику
    const total = clients.length;

    return {
      average: currentAverage,
      total,
      change: previousAverage 
        ? Math.round(((currentAverage - previousAverage) / previousAverage) * 100)
        : 0
    };
  }, [evaluations, clients]);

  return (
    <>
      <StatBox sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 3 }}>
          {/* Блоки статистики */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StatBlock>
                <AssessmentIcon className="icon" />
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                    <Typography className="value">{stats.average}%</Typography>
                    <ChangeIndicator
                      sx={{ 
                        color: stats.change > 0 
                          ? 'success.main'
                          : stats.change < 0 
                            ? 'error.main'
                            : 'text.secondary',
                        mt: 0.5
                      }}
                    >
                      {stats.change !== 0 ? (
                        <>
                          {stats.change > 0 ? (
                            <TrendingUpIcon />
                          ) : (
                            <TrendingDownIcon />
                          )}
                          {stats.change > 0 ? '+' : ''}{Math.abs(stats.change)}% vs прошлый период
                        </>
                      ) : (
                        'без изменений'
                      )}
                    </ChangeIndicator>
                  </Box>
                  <Typography className="label">Среднее</Typography>
                </Box>
              </StatBlock>
            </Grid>
            <Grid item xs={6}>
              <StatBlock>
                <PeopleAltIcon className="icon" />
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                    <Typography className="value">{stats.total}</Typography>
                  </Box>
                  <Typography className="label">Клиентов</Typography>
                </Box>
              </StatBlock>
            </Grid>
          </Grid>

          {/* Обновляем заголовок списка */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 2 
          }}>
            <Typography variant="subtitle2" color="text.secondary">
              Топ 5 клиентов
            </Typography>
            <StyledActionButton
              onClick={() => setSortAscending(!sortAscending)}
              startIcon={<SwapVertIcon />}
              disableRipple
              size="small"
            >
              {sortAscending ? 'По убыванию' : 'По возрастанию'}
            </StyledActionButton>
          </Box>

          {/* Список клиентов в основном виде */}
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {topClients.map((client, index) => (
              <Box key={client.id}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography
                    sx={{
                      color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                      fontWeight: 600,
                      minWidth: '30px',
                      textAlign: 'center'
                    }}
                  >
                    #{index + 1}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{
                      minWidth: '300px',
                      maxWidth: '400px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {client.id}
                  </Typography>
                  <Box sx={{ flex: 1 }}>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      mb: 0.5 
                    }}>
                      <Typography color="text.secondary">
                        {client.callsCount} {getCallsText(client.callsCount)}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          minWidth: '60px',
                          textAlign: 'right'
                        }}
                      >
                        {client.score}%
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        height: 8,
                        bgcolor: theme => theme.palette.mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.1)' 
                          : 'rgba(0, 0, 0, 0.1)',
                        borderRadius: 4,
                        overflow: 'hidden',
                      }}
                    >
                      <Box
                        sx={{
                          width: `${client.score}%`,
                          height: '100%',
                          bgcolor: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                          borderRadius: 4,
                          transition: 'width 0.3s ease',
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          {clients && clients.length > 5 && (
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <StyledActionButton
                onClick={() => setIsModalOpen(true)}
                startIcon={<PeopleAltIcon />}
                disableRipple
              >
                Показать всех ({clients.length})
              </StyledActionButton>
            </Box>
          )}
        </Box>
      </StatBox>

      <StyledDialog
        fullScreen={fullScreen}
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSearchQuery('');
        }}
        maxWidth="md"
        fullWidth
        disableScrollLock
        PaperProps={{
          sx: {
            margin: '16px',
            width: 'calc(100% - 32px)',
            maxWidth: '900px',
          }
        }}
      >
        <CloseButton
          onClick={() => {
            setIsModalOpen(false);
            setSearchQuery('');
          }}
        >
          <CloseIcon />
        </CloseButton>

        <DialogTitle sx={{ 
          m: 0, 
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          {/* Блок с общей статистикой */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StatBlock>
                <AssessmentIcon className="icon" />
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                    <Typography className="value">{stats.average}%</Typography>
                    <ChangeIndicator
                      sx={{ 
                        color: stats.change > 0 
                          ? 'success.main'
                          : stats.change < 0 
                            ? 'error.main'
                            : 'text.secondary',
                        mt: 0.5
                      }}
                    >
                      {stats.change !== 0 ? (
                        <>
                          {stats.change > 0 ? (
                            <TrendingUpIcon />
                          ) : (
                            <TrendingDownIcon />
                          )}
                          {stats.change > 0 ? '+' : ''}{Math.abs(stats.change)}% vs прошлый период
                        </>
                      ) : (
                        'без изменений'
                      )}
                    </ChangeIndicator>
                  </Box>
                  <Typography className="label">Среднее</Typography>
                </Box>
              </StatBlock>
            </Grid>
            <Grid item xs={6}>
              <StatBlock>
                <PeopleAltIcon className="icon" />
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                    <Typography className="value">{stats.total}</Typography>
                  </Box>
                  <Typography className="label">Клиентов</Typography>
                </Box>
              </StatBlock>
            </Grid>
          </Grid>

          {/* Обновляем заголовок и добавляем кнопку сортировки */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2
          }}>
            <Typography variant="h6" sx={{ flex: 1 }}>
              Все клиенты ({modalClients.length})
            </Typography>
            <StyledActionButton
              onClick={() => setSortAscending(!sortAscending)}
              startIcon={<SwapVertIcon />}
              disableRipple
              size="small"
            >
              {sortAscending ? 'По убыванию' : 'По возрастанию'}
            </StyledActionButton>
          </Box>
          <TextField
            fullWidth
            placeholder="Поиск клиента..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'text.secondary' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: theme => theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.03)' 
                  : 'rgba(0, 0, 0, 0.02)',
              }
            }}
          />
        </DialogTitle>

        <StyledDialogContent>
          {modalClients.length === 0 ? (
            <Box className="empty-message">
              <Typography color="text.secondary">
                Клиенты не найдены
              </Typography>
            </Box>
          ) : (
            <Box className="manager-list">
              {/* Заголовки столбцов */}
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 2,
                  mb: 2,
                  px: 1
                }}
              >
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '0.75rem',
                    minWidth: '30px',
                    textAlign: 'center'
                  }}
                >
                  №
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  flex: 1,
                  minWidth: 0
                }}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: '0.75rem',
                      flex: 1,
                      minWidth: 0
                    }}
                  >
                    ID клиента
                  </Typography>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    minWidth: '200px',
                    justifyContent: 'flex-end'
                  }}>
                    <Typography
                      sx={{
                        color: 'text.secondary',
                        fontSize: '0.75rem',
                        flex: 1,
                        textAlign: 'right'
                      }}
                    >
                      Звонков
                    </Typography>
                    <Typography
                      sx={{
                        color: 'text.secondary',
                        fontSize: '0.75rem',
                        minWidth: '60px',
                        textAlign: 'right'
                      }}
                    >
                      Средняя
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Список всех клиентов в модальном окне */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {modalClients.map((client, index) => (
                  <Box key={client.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography
                        sx={{
                          color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                          fontWeight: 600,
                          minWidth: '30px',
                          textAlign: 'center'
                        }}
                      >
                        #{index + 1}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        sx={{
                          minWidth: '300px',
                          maxWidth: '400px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {client.id}
                      </Typography>
                      <Box sx={{ flex: 1 }}>
                        <Box sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between',
                          mb: 0.5 
                        }}>
                          <Typography color="text.secondary">
                            {client.callsCount} {getCallsText(client.callsCount)}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              minWidth: '60px',
                              textAlign: 'right'
                            }}
                          >
                            {client.score}%
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: '100%',
                            height: 8,
                            bgcolor: theme => theme.palette.mode === 'dark' 
                              ? 'rgba(255, 255, 255, 0.1)' 
                              : 'rgba(0, 0, 0, 0.1)',
                            borderRadius: 4,
                            overflow: 'hidden',
                          }}
                        >
                          <Box
                            sx={{
                              width: `${client.score}%`,
                              height: '100%',
                              bgcolor: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
                              borderRadius: 4,
                              transition: 'width 0.3s ease',
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </StyledDialogContent>
      </StyledDialog>
    </>
  );
};

export default ClientsBarChart; 
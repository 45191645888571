import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  MenuItem,
  LinearProgress,
  IconButton,
  Alert,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Brightness4, Brightness7, CheckCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import { Theme } from '@mui/material/styles';
import { useTheme } from '../../context/ThemeContext';
import LogoWhite from '../../assets/images/logo_white.png';
import LogoBlack from '../../assets/images/logo_black.png';
import { logoBackgroundStyles } from '../../styles/globalStyles';
import AnimatedEye from '../../components/AnimatedEye/AnimatedEye';
import { useAuth } from '../../context/AuthContext';
import { authService } from '../../services/authService';
import axios from 'axios';
import YandexCaptcha from '../../components/YandexCaptcha/YandexCaptcha';

const RegisterContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  minWidth: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  margin: 0,
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
  ...logoBackgroundStyles(theme, theme.palette.mode === 'dark'),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  width: '100%',
  maxWidth: '500px',
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.85) 0%, rgba(18, 18, 18, 0.85) 100%)'
    : 'rgba(255, 255, 255, 0.85)',
  backdropFilter: 'blur(20px)',
  border: 'none',
  borderRadius: '24px',
  position: 'relative',
  zIndex: 1,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 24px rgba(0, 0, 0, 0.4)'
    : '0 4px 24px rgba(0, 0, 0, 0.05)',
}));

const ProgressContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  overflow: 'hidden',
}));

const ProgressWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const StepText = styled(Typography)<{ animate?: boolean }>(({ theme, animate }) => ({
  color: theme.palette.text.secondary,
  textAlign: 'right',
  fontSize: '0.875rem',
  transition: animate ? 'opacity 0.3s ease' : 'none',
  opacity: animate ? 0 : 1,
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  '& .MuiLinearProgress-bar': {
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    transition: 'all 0.8s cubic-bezier(0.4, 0, 0.2, 1)',
  },
}));

const ThemeToggleButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
  },
}));

const textFieldStyles = (theme: Theme) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    '& input, & .MuiSelect-select': {
      color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#121212',
    },
    '& fieldset': {
      borderColor: `${theme.palette.primary.main}40`,
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main}80`,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: `${theme.palette.primary.main}B3`,
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiSelect-icon': {
    color: `${theme.palette.primary.main}B3`,
  },
  '& .MuiMenuItem-root': {
    color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#121212',
  },
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.MuiMenuItem-root': {
    borderRadius: '8px',
    margin: '2px 8px',
    minHeight: '32px',
    width: 'calc(100% - 16px)',
    '&:first-of-type': {
      marginTop: '8px',
    },
    '&:last-child': {
      marginBottom: '8px',
    },
    '&.Mui-selected': {
      backgroundColor: `${theme.palette.primary.main}20`,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}30`,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.08)'
        : 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

interface FormData {
  firstName: string;
  lastName: string;
  companyName: string;
  employeesCount: string;
  industry: string;
  phone: string;
  email: string;
  password: string;
}

// Определяем тип для ключей индустрий в соответствии с i18n.d.ts
type IndustryKey = 
  | 'industry.it'
  | 'industry.finance'
  | 'industry.healthcare'
  | 'industry.education'
  | 'industry.manufacturing'
  | 'industry.retail'
  | 'industry.construction'
  | 'industry.energy'
  | 'industry.transportation'
  | 'industry.telecom'
  | 'industry.agriculture'
  | 'industry.media'
  | 'industry.hospitality'
  | 'industry.consulting'
  | 'industry.realestate'
  | 'industry.legal'
  | 'industry.entertainment'
  | 'industry.research'
  | 'industry.aerospace'
  | 'industry.pharmaceutical'
  | 'industry.other';

const INDUSTRIES: IndustryKey[] = [
  'industry.it',
  'industry.finance',
  'industry.healthcare',
  'industry.education',
  'industry.manufacturing',
  'industry.retail',
  'industry.construction',
  'industry.energy',
  'industry.transportation',
  'industry.telecom',
  'industry.agriculture',
  'industry.media',
  'industry.hospitality',
  'industry.consulting',
  'industry.realestate',
  'industry.legal',
  'industry.entertainment',
  'industry.research',
  'industry.aerospace',
  'industry.pharmaceutical',
  'industry.other'
];

const EMPLOYEES_COUNT = [
  '1-10',
  '11-50',
  '51-100',
  '101-200',
  '201-500',
  '501-1000',
  '1001-5000',
  '5000+',
  '10000+'
];

const FormContainer = styled(Box)(({ theme }) => ({
  minHeight: '280px',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
}));

type ErrorKey = 
  | 'register.errorRequiredFields'
  | 'register.emailExists'
  | 'register.invalidData'
  | 'register.error'
  | 'auth.captchaFailed'
  | 'auth.captchaRequired'
  | 'auth.loginError';

const StyledAlert = styled(Alert)(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(3),
  left: '50%',
  transform: 'translateX(-50%)',
  minWidth: '300px',
  maxWidth: '90%',
  zIndex: 1100,
  borderRadius: '12px',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 24px rgba(0, 0, 0, 0.4)'
    : '0 4px 24px rgba(0, 0, 0, 0.1)',
  animation: 'slideDown 0.3s ease-out',
  '@keyframes slideDown': {
    '0%': {
      transform: 'translate(-50%, -100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translate(-50%, 0)',
      opacity: 1,
    },
  },
}));

const scrollbarStyles = (theme: Theme) => ({
  '&::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
    margin: '8px 0',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    border: '2px solid transparent',
    backgroundClip: 'padding-box',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
    },
  },
});

const PasswordStrengthIndicator = styled(LinearProgress)(({ theme }) => ({
  marginTop: '4px',
  height: '2px',
  borderRadius: '1px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  '& .MuiLinearProgress-bar': {
    transition: 'transform 0.3s ease, background-color 0.3s ease',
  },
}));

const checkPasswordStrength = (password: string) => {
  const hasMinLength = password.length >= 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  
  if (!password) return { strength: 0, color: 'error' };
  
  let strength = 0;
  if (hasMinLength) strength += 34;
  if (hasUpperCase) strength += 33;
  if (hasNumber) strength += 33;

  let color = 'error';
  if (strength > 90) color = 'success';
  else if (strength > 50) color = 'warning';

  return { strength, color };
};

const PasswordRequirements = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '-240px',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '220px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(18, 18, 18, 0.95)'
    : theme.palette.background.paper,
  backdropFilter: 'blur(10px)',
  borderRadius: '12px',
  padding: theme.spacing(2),
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 24px rgba(0, 0, 0, 0.4)'
    : '0 4px 24px rgba(0, 0, 0, 0.1)',
  border: `1px solid ${theme.palette.divider}`,
  zIndex: 2,
  '& .MuiLinearProgress-root': {
    marginBottom: theme.spacing(1.5),
  },
}));

const RequirementItem = styled(Box)<{ isMet: boolean }>(({ theme, isMet }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: isMet ? theme.palette.success.main : theme.palette.error.main,
  fontSize: '0.75rem',
  marginBottom: '4px',
  '&:last-child': {
    marginBottom: 0,
  },
}));

const EmailErrorTooltip = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '-240px',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '220px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(18, 18, 18, 0.95)'
    : theme.palette.background.paper,
  backdropFilter: 'blur(10px)',
  borderRadius: '12px',
  padding: theme.spacing(2),
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 24px rgba(0, 0, 0, 0.4)'
    : '0 4px 24px rgba(0, 0, 0, 0.1)',
  border: `1px solid ${theme.palette.divider}`,
  zIndex: 1,
  color: theme.palette.error.main,
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  flexDirection: 'row-reverse',
  justifyContent: 'flex-end',
}));

const PhoneErrorTooltip = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '-240px',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '220px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(18, 18, 18, 0.95)'
    : theme.palette.background.paper,
  backdropFilter: 'blur(10px)',
  borderRadius: '12px',
  padding: theme.spacing(2),
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 24px rgba(0, 0, 0, 0.4)'
    : '0 4px 24px rgba(0, 0, 0, 0.1)',
  border: `1px solid ${theme.palette.divider}`,
  zIndex: 1,
  color: theme.palette.error.main,
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

// Добавим функцию для валидации email
const isValidEmail = (email: string): boolean => {
  // Регулярное выражение для проверки email
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

const Register: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { themeMode, toggleTheme } = useTheme();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    companyName: '',
    employeesCount: '',
    industry: '',
    phone: '',
    email: '',
    password: '',
  });
  const [animatingStep, setAnimatingStep] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<ErrorKey | null>(null);
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [emailError, setEmailError] = useState(false);
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const handleChange = (field: keyof FormData) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setFormData({ ...formData, [field]: value });

    if (field === 'password') {
      const errors: string[] = [];
      if (value && value.length < 8) errors.push(t('register.passwordMinLength' as const));
      if (value && !/[A-Z]/.test(value)) errors.push(t('register.passwordUpperCase' as const));
      if (value && !/\d/.test(value)) errors.push(t('register.passwordNumber' as const));
      setPasswordErrors(errors);
    }

    if (field === 'email') {
      setEmailError(value.length > 0 && !isValidEmail(value));
    }
  };

  const validateCurrentStep = (): boolean => {
    setError(null);
    
    switch (currentStep) {
      case 1:
        if (!formData.firstName || !formData.lastName) {
          setError('register.errorRequiredFields');
          return false;
        }
        break;
      case 2:
        if (!formData.companyName || !formData.employeesCount || !formData.industry) {
          setError('register.errorRequiredFields');
          return false;
        }
        break;
      case 3:
        if (!formData.phone || !formData.email || !formData.password) {
          setError('register.errorRequiredFields');
          return false;
        }
        break;
    }
    return true;
  };

  const handleNext = () => {
    if (!validateCurrentStep()) return;

    setAnimatingStep(true);
    setTimeout(() => {
      setCurrentStep((prev) => Math.min(prev + 1, 3));
      setAnimatingStep(false);
    }, 300);
  };

  const handleBack = () => {
    setAnimatingStep(true);
    setTimeout(() => {
      setCurrentStep((prev) => Math.max(prev - 1, 1));
      setAnimatingStep(false);
    }, 300);
  };

  const handleSubmit = async () => {
    if (!isFormValid()) {
      setError('register.errorRequiredFields');
      return;
    }

    if (captchaVerified && captchaToken) {
      // Если капча уже проверена и токен есть, выполняем регистрацию
      await performRegistration(captchaToken);
    } else {
      // Иначе показываем капчу
      setShowCaptcha(true);
    }
  };

  const performRegistration = async (token: string) => {
    setLoading(true);
    try {
      console.log('Starting registration process...');

      const formDataToSubmit = {
        ...formData,
        industry: formData.industry.replace('industry.', '')
      };
      
      console.log('Submitting registration data:', { 
        ...formDataToSubmit,
        password: '***',
        captchaToken: token.substring(0, 20) + '...'
      });

      const response = await authService.register(formDataToSubmit, token);
      console.log('Registration successful, got response:', {
        hasTokens: !!response.tokens,
        hasUser: !!response.user,
        user: response.user ? {
          id: response.user.id,
          email: response.user.email
        } : null
      });
      
      if (response.tokens && response.user) {
        console.log('Saving data to localStorage...');
        localStorage.setItem('accessToken', response.tokens.accessToken);
        localStorage.setItem('refreshToken', response.tokens.refreshToken);
        localStorage.setItem('user', JSON.stringify(response.user));
        
        console.log('Data saved, navigating to request-access...');
        window.location.href = '/request-access';
      } else {
        console.error('Missing tokens or user data in response');
        setError('register.error');
      }
      
    } catch (err) {
      console.error('Registration error:', err);
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 409) {
          setError('register.emailExists');
        } else if (err.response?.status === 400) {
          if (err.response.data?.message?.includes('Captcha')) {
            setError('auth.captchaFailed');
            setCaptchaVerified(false);
            setShowCaptcha(true);
          } else {
            setError('register.invalidData');
          }
        } else {
          setError('register.error');
        }
      } else {
        setError('register.error');
      }
    } finally {
      setLoading(false);
      setShowCaptcha(false);
    }
  };

  const handleCaptchaSuccess = async (token: string) => {
    setCaptchaToken(token);
    setCaptchaVerified(true);
    setShowCaptcha(false);
    await performRegistration(token);
  };

  const handleCaptchaClose = () => {
    setShowCaptcha(false);
    setCaptchaVerified(false);
    setCaptchaToken(null);
  };

  const getStepText = (): string => {
    return t('register.step', {
      replace: {
        current: currentStep,
        total: 3
      }
    });
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <Typography variant="h5" sx={{ mb: 3, color: 'primary.main' }}>
              {t('register.personalInfo')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                fullWidth
                label={t('register.firstName')}
                value={formData.firstName}
                onChange={handleChange('firstName')}
                variant="outlined"
                sx={textFieldStyles}
                name="given-name"
                autoComplete="given-name"
                autoFocus
              />
              <TextField
                fullWidth
                label={t('register.lastName')}
                value={formData.lastName}
                onChange={handleChange('lastName')}
                variant="outlined"
                sx={textFieldStyles}
                name="family-name"
                autoComplete="family-name"
              />
            </Box>
          </>
        );

      case 2:
        return (
          <>
            <Typography variant="h5" sx={{ mb: 3, color: 'primary.main' }}>
              {t('register.companyInfo')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                fullWidth
                label={t('register.companyName')}
                value={formData.companyName}
                onChange={handleChange('companyName')}
                variant="outlined"
                sx={textFieldStyles}
                autoComplete="off"
                name="company-name-no-autofill"
                inputProps={{
                  autoComplete: 'new-company-name',
                  autoCorrect: 'off',
                  autoCapitalize: 'off',
                  spellCheck: 'false'
                }}
              />
              <TextField
                select
                fullWidth
                label={t('register.employeesCount')}
                value={formData.employeesCount}
                onChange={handleChange('employeesCount')}
                variant="outlined"
                sx={textFieldStyles}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        borderRadius: '12px',
                        mt: 1,
                        backgroundColor: theme => theme.palette.mode === 'dark' 
                          ? 'rgba(18, 18, 18, 0.95)'
                          : theme.palette.background.paper,
                        backdropFilter: 'blur(10px)',
                        boxShadow: theme => theme.palette.mode === 'dark'
                          ? '0 4px 24px rgba(0, 0, 0, 0.4)'
                          : '0 4px 24px rgba(0, 0, 0, 0.1)',
                      },
                    },
                  },
                }}
              >
                {EMPLOYEES_COUNT.map((option) => (
                  <StyledMenuItem key={option} value={option}>
                    {option}
                  </StyledMenuItem>
                ))}
              </TextField>
              <TextField
                select
                fullWidth
                label={t('register.industry')}
                value={formData.industry}
                onChange={handleChange('industry')}
                variant="outlined"
                sx={textFieldStyles}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: theme => ({ 
                        borderRadius: '12px',
                        mt: 1,
                        backgroundColor: theme.palette.mode === 'dark' 
                          ? 'rgba(18, 18, 18, 0.95)'
                          : theme.palette.background.paper,
                        backdropFilter: 'blur(10px)',
                        boxShadow: theme.palette.mode === 'dark'
                          ? '0 4px 24px rgba(0, 0, 0, 0.4)'
                          : '0 4px 24px rgba(0, 0, 0, 0.1)',
                        maxHeight: '400px',
                        overflow: 'hidden',
                        '& .MuiList-root': {
                          padding: '8px 0',
                          maxHeight: 'calc(400px - 16px)',
                          overflowY: 'auto',
                          ...scrollbarStyles(theme),
                        },
                      }),
                    },
                  },
                }}
              >
                {INDUSTRIES.map((option) => (
                  <StyledMenuItem key={option} value={option}>
                    {t(option)}
                  </StyledMenuItem>
                ))}
              </TextField>
            </Box>
          </>
        );

      case 3:
        return (
          <>
            <Typography variant="h5" sx={{ mb: 3, color: 'primary.main' }}>
              {t('register.contactInfo')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  label={t('register.phone')}
                  value={formData.phone}
                  onChange={handleChange('phone')}
                  variant="outlined"
                  type="tel"
                  sx={textFieldStyles}
                  name="phone"
                  autoComplete="tel"
                  inputProps={{
                    pattern: "[+]?[0-9]{1,4}[-. ]?[0-9]{3}[-. ]?[0-9]{3}[-. ]?[0-9]{2}[-. ]?[0-9]{2}",
                    inputMode: "tel",
                    placeholder: "+X XXX XXX XX XX",
                    maxLength: 18,
                  }}
                  error={formData.phone ? !formData.phone.match(/^[+]?[0-9\-\s.()]+$/) : false}
                />
                {formData.phone && !formData.phone.match(/^[+]?[0-9\-\s.()]+$/) && (
                  <PhoneErrorTooltip>
                    <CheckCircle sx={{ fontSize: 16, color: 'error.main' }} />
                    {t('register.phoneError' as const)}
                  </PhoneErrorTooltip>
                )}
              </Box>

              <Box sx={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  label={t('register.email')}
                  value={formData.email}
                  onChange={handleChange('email')}
                  variant="outlined"
                  type="email"
                  sx={textFieldStyles}
                  name="email"
                  autoComplete="email"
                  error={emailError}
                  inputProps={{
                    inputMode: "email",
                    pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
                    placeholder: "example@mail.com"
                  }}
                />
                {emailError && (
                  <EmailErrorTooltip>
                    <CheckCircle sx={{ fontSize: 16, color: 'error.main' }} />
                    {t('register.emailError' as const)}
                  </EmailErrorTooltip>
                )}
              </Box>

              <Box sx={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  label={t('register.password')}
                  value={formData.password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    if (value === '' || /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/.test(value)) {
                      handleChange('password')(e);
                    }
                  }}
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  sx={textFieldStyles}
                  name="new-password"
                  autoComplete="new-password"
                  error={formData.password.length > 0 && passwordErrors.length > 0}
                  InputProps={{
                    endAdornment: (
                      <AnimatedEye 
                        isOpen={showPassword}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ),
                  }}
                  inputProps={{
                    // Запрещаем вставку не-английских символов
                    onPaste: (e) => {
                      e.preventDefault();
                      const text = e.clipboardData.getData('text');
                      if (/^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/.test(text)) {
                        const target = e.target as HTMLInputElement;
                        const start = target.selectionStart || 0;
                        const end = target.selectionEnd || 0;
                        const newValue = target.value.slice(0, start) + text + target.value.slice(end);
                        if (newValue.length <= 50) { // Добавляем ограничение на длину
                          handleChange('password')({ target: { value: newValue } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }
                    },
                    // Запрещаем IME (метод ввода для других языков)
                    onCompositionStart: (e) => {
                      e.preventDefault();
                    },
                    maxLength: 50, // Ограничиваем максимальную длину
                    autoComplete: 'new-password',
                    spellCheck: false, // Отключаем проверку орфографии
                    autoCorrect: 'off',
                    autoCapitalize: 'off',
                    // Запрещаем контекстное меню для предотвращения вставки через него
                    onContextMenu: (e) => e.preventDefault(),
                    // Запрещаем drag and drop
                    onDrop: (e) => e.preventDefault(),
                  }}
                />
                {formData.password && (
                  <PasswordRequirements>
                    <PasswordStrengthIndicator
                      variant="determinate"
                      value={checkPasswordStrength(formData.password).strength}
                      color={checkPasswordStrength(formData.password).color as any}
                    />
                    <RequirementItem isMet={formData.password.length >= 8}>
                      <CheckCircle sx={{ fontSize: 16 }} />
                      {t('register.passwordMinLength')}
                    </RequirementItem>
                    <RequirementItem isMet={/[A-Z]/.test(formData.password)}>
                      <CheckCircle sx={{ fontSize: 16 }} />
                      {t('register.passwordUpperCase')}
                    </RequirementItem>
                    <RequirementItem isMet={/\d/.test(formData.password)}>
                      <CheckCircle sx={{ fontSize: 16 }} />
                      {t('register.passwordNumber')}
                    </RequirementItem>
                  </PasswordRequirements>
                )}
              </Box>
            </Box>
          </>
        );

      default:
        return null;
    }
  };

  // Перемещаем функцию внутрь компонента
  const isFormValid = (): boolean => {
    // Проверяем все обязательные поля
    const isAllFieldsFilled = Object.values(formData).every(value => value !== '');
    
    // Проверяем корректность email
    const isEmailValid = isValidEmail(formData.email);
    
    // Проверяем корректность телефона
    const isPhoneValid = Boolean(formData.phone.match(/^[+]?[0-9\-\s.()]+$/));
    
    // Проверяем требования к паролю
    const isPasswordValid = formData.password.length >= 8 
      && /[A-Z]/.test(formData.password) 
      && /\d/.test(formData.password);

    return isAllFieldsFilled && isEmailValid && isPhoneValid && isPasswordValid;
  };

  return (
    <RegisterContainer>
      <ThemeToggleButton onClick={toggleTheme}>
        {themeMode === 'dark' ? <Brightness7 /> : <Brightness4 />}
      </ThemeToggleButton>
      <LanguageSwitcher />
      
      {error && (
        <StyledAlert 
          severity="error" 
          onClose={() => setError(null)}
        >
          {t(error)}
        </StyledAlert>
      )}

      <StyledPaper>
        <Typography 
          variant="h4" 
          sx={{ 
            mb: 4, 
            textAlign: 'center', 
            fontWeight: 700, 
            color: 'primary.main',
            transition: 'opacity 0.3s ease',
            opacity: animatingStep ? 0.7 : 1,
          }}
        >
          {t('auth.loginTitle')}
        </Typography>

        <ProgressContainer>
          <ProgressWrapper>
            <StepText animate={animatingStep}>
              {getStepText()}
            </StepText>
            <StyledLinearProgress 
              variant="determinate" 
              value={(currentStep / 3) * 100}
            />
          </ProgressWrapper>
        </ProgressContainer>

        <FormContainer
          sx={{
            opacity: animatingStep ? 0 : 1,
            transform: animatingStep ? 'translateY(10px)' : 'translateY(0)',
          }}
        >
          {renderStep()}
        </FormContainer>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          {currentStep > 1 ? (
            <Button
              variant="outlined"
              onClick={handleBack}
              sx={{ 
                minWidth: 100,
                borderRadius: '12px',
                padding: '12px',
                borderColor: (theme) => theme.palette.primary.main,
                color: 'primary.main',
                '&:hover': {
                  borderColor: (theme) => theme.palette.primary.dark,
                  backgroundColor: 'transparent',
                },
              }}
            >
              {t('common.back')}
            </Button>
          ) : (
            <Box sx={{ width: 100 }} />
          )}
          
          <Button
            variant="contained"
            onClick={currentStep === 3 ? handleSubmit : handleNext}
            disabled={currentStep === 3 && !isFormValid()}
            sx={{ 
              minWidth: 100,
              borderRadius: '12px',
              padding: '12px',
              backgroundColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.mode === 'dark' ? '#121212' : '#FFFFFF',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.primary.dark,
              },
              '&.Mui-disabled': {
                backgroundColor: (theme) => theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.12)'
                  : 'rgba(0, 0, 0, 0.12)',
                color: (theme) => theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.3)'
                  : 'rgba(0, 0, 0, 0.26)',
              },
            }}
          >
            {loading && currentStep === 3 ? (
              <CircularProgress size={24} sx={{ color: '#121212' }} />
            ) : (
              currentStep === 3 ? t('register.submit') : t('common.next')
            )}
          </Button>
        </Box>

        <Box sx={{ mt: 3, textAlign: 'center' }}>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
            {t('auth.haveAccount')}
          </Typography>
          <Button
            variant="text"
            onClick={() => navigate('/login')}
            sx={{
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            }}
          >
            {t('auth.backToLogin')}
          </Button>
        </Box>
      </StyledPaper>

      <YandexCaptcha
        visible={showCaptcha}
        onSuccess={handleCaptchaSuccess}
        onClose={handleCaptchaClose}
      />
    </RegisterContainer>
  );
};

export default Register; 
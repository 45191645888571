import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import AverageScore from './AverageScore';
import CallsCount from './CallsCount';
import BlocksAverage from './BlocksAverage';
import StatisticsSkeleton from './StatisticsSkeleton';
import callAnalyticsService, { 
  CallStatistics, 
  CallEvaluation,
  ChecklistConfig 
} from '../../../../../../services/callAnalyticsService';
import { useFilters } from '../../../context/FiltersContext';
import ChecklistAverage, { ChecklistAverageProps } from './ChecklistAverage';
import BlocksScoresDynamics from './BlocksScoresDynamics';
import BlocksTabs from './BlocksTabs';
import { useTranslation } from 'react-i18next';

const Statistics: React.FC = () => {
  const [statistics, setStatistics] = useState<CallStatistics[]>([]);
  const [evaluations, setEvaluations] = useState<CallEvaluation[]>([]);
  const [checklistConfig, setChecklistConfig] = useState<ChecklistConfig | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { startDate, endDate, managers, departments } = useFilters();
  const { t } = useTranslation();

  useEffect(() => {
    // Добавляем логирование данных из localStorage
    console.group('🔐 User Session Data');
    console.log('👤 Team ID:', localStorage.getItem('teamId'));
    console.log('🔑 Access Token:', localStorage.getItem('accessToken')?.substring(0, 20) + '...');
    console.log('👥 User Role:', localStorage.getItem('userRole'));
    console.log('📝 User Settings:', localStorage.getItem('userSettings'));
    console.groupEnd();

    const fetchData = async () => {
      if (!startDate || !endDate) return;

      try {
        setLoading(true);
        setError(null);

        console.group('📊 Fetching Analytics Data');
        console.log('📅 Period:', {
          start: startDate.format('YYYY-MM-DD'),
          end: endDate.format('YYYY-MM-DD')
        });
        console.log('👥 Selected Managers:', managers);
        console.log('🏢 Selected Departments:', departments);
        console.groupEnd();

        const [statsData, evalsData, configData] = await Promise.all([
          callAnalyticsService.getCallStatistics(
            'day',
            startDate.format('YYYY-MM-DD'),
            endDate.format('YYYY-MM-DD')
          ),
          callAnalyticsService.getCallEvaluations(
            startDate.format('YYYY-MM-DD'),
            endDate.format('YYYY-MM-DD'),
            managers.includes('all') ? undefined : managers[0]
          ),
          callAnalyticsService.getChecklistConfig()
        ]);

        console.log('📈 Statistics Sample:', statsData.slice(0, 2));
        console.log('📋 Evaluations Sample:', evalsData.slice(0, 2));
        console.log('⚙️ Config Sample:', {
          name: configData.config_name,
          blocksCount: configData.blocks.length
        });
        console.groupEnd();
        
        setStatistics(statsData);
        setEvaluations(evalsData);
        setChecklistConfig(configData);
      } catch (err) {
        console.error('❌ Error fetching data:', err);
        setError(err instanceof Error ? err.message : 'Произошла ошибка при загрузке данных');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, managers, departments]);

  if (loading) {
    return <StatisticsSkeleton />;
  }

  if (error) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '300px',
        color: 'error.main'
      }}>
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography 
          variant="h5" 
          sx={{ 
            mb: 1,
            fontWeight: 600,
            color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
          }}
        >
          {t('callAnalytics.generalStatistics.title')}
        </Typography>
      </Grid>

      <Grid item xs={12} md={3}>
        <AverageScore statistics={statistics} />
      </Grid>
      
      <Grid item xs={12} md={4}>
        <CallsCount statistics={statistics} evaluations={evaluations} />
      </Grid>
      
      <Grid item xs={12} md={5}>
        <BlocksAverage 
          statistics={statistics}
          checklistConfig={checklistConfig}
        />
      </Grid>

      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12} md={6}>
          <ChecklistAverage 
            statistics={evaluations}
            checklistConfig={checklistConfig}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BlocksScoresDynamics 
            statistics={statistics}
            checklistConfig={checklistConfig}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mt: 4 }}>
        <Typography 
          variant="h5" 
          sx={{ 
            mb: 1,
            fontWeight: 600,
            color: theme => theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
          }}
        >
          {t('callAnalytics.blocks.title')}
        </Typography>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={12}>
          <BlocksTabs 
            evaluations={evaluations}
            checklistConfig={checklistConfig}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Statistics; 
import api from './api';

export interface ChecklistConfig {
  config_name: string;
  blocks: {
    id: string;
    name: string;
    order: number;
    items: {
      id: string;
      name: string;
      order: number;
      weight: number;
    }[];
  }[];
}

export interface CallEvaluation {
  id: string;
  call_id: string;
  manager_id: string;
  client_id: string;
  audio_file_url: string;
  scores: { [key: string]: number };
  total_score: number;
  evaluated_at: string;
}

export interface CallStatistics {
  date: string;
  block_scores: {
    [key: string]: number;
  };
  calls_count: number;
}

export interface CallData {
  date: string;
  manager_id: string;
  client_id: string;
  duration: number; // в секундах
  score: number;
  status: string;
}

const callAnalyticsService = {
  getChecklistConfig: async (): Promise<ChecklistConfig> => {
    console.log('🔄 Fetching checklist config...');
    try {
      const teamId = localStorage.getItem('teamId');
      const { data } = await api.get('/calls/checklist-config', {
        params: { teamId }
      });
      console.log('✅ Checklist config received:', data);
      return data;
    } catch (error) {
      console.error('❌ Failed to fetch checklist config:', error);
      throw error;
    }
  },

  getCallEvaluations: async (startDate: string, endDate: string, managerId?: string): Promise<CallEvaluation[]> => {
    console.log('🔄 Fetching call evaluations...', { startDate, endDate, managerId });
    
    const params = new URLSearchParams();
    params.append('startDate', startDate);
    params.append('endDate', endDate);
    if (managerId) params.append('managerId', managerId);
    
    // Добавляем team_id из localStorage
    const teamId = localStorage.getItem('teamId');
    if (teamId) params.append('teamId', teamId);

    try {
      const { data } = await api.get('/calls/evaluations', { params });
      console.log(`✅ Received ${data.length} call evaluations`);
      console.log('📊 First evaluation sample:', data[0]);
      return data;
    } catch (error) {
      console.error('❌ Failed to fetch call evaluations:', error);
      throw error;
    }
  },

  getCallStatistics: async (period: 'day' | 'week' | 'month', startDate?: string, endDate?: string): Promise<CallStatistics[]> => {
    console.log('🔄 Fetching call statistics...', { period, startDate, endDate });
    
    const params = new URLSearchParams();
    params.append('period', period);
    if (startDate) params.append('startDate', startDate);
    if (endDate) params.append('endDate', endDate);
    
    // Добавляем team_id из localStorage
    const teamId = localStorage.getItem('teamId');
    if (teamId) params.append('teamId', teamId);

    try {
      const { data } = await api.get('/calls/statistics', { params });
      console.log(`✅ Received statistics for ${data.length} periods`);
      console.log('📊 First period sample:', data[0]);
      return data;
    } catch (error) {
      console.error('❌ Failed to fetch call statistics:', error);
      console.error('Request details:', {
        url: '/calls/statistics',
        params: Object.fromEntries(params.entries())
      });
      throw error;
    }
  },

  getCalls: async (
    startDate: string,
    endDate: string,
    managerId?: string
  ): Promise<CallData[]> => {
    const response = await api.get('/calls', {
      params: {
        start_date: startDate,
        end_date: endDate,
        manager_id: managerId
      }
    });
    return response.data;
  }
};

export default callAnalyticsService;
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Update,
  EmojiEvents,
  Article,
  Campaign,
  Construction,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const NewsCard = styled(Card)(({ theme }) => ({
  height: '100%',
  background: theme.palette.mode === 'dark'
    ? 'rgba(30, 30, 30, 0.85)'
    : 'rgba(255, 255, 255, 0.85)',
  backdropFilter: 'blur(20px)',
  borderRadius: '16px',
  border: '1px solid rgba(255, 255, 255, 0.05)',
}));

const DevelopmentAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(220, 251, 1, 0.1)' 
    : 'rgba(121, 62, 238, 0.1)',
  color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
  borderRadius: '12px',
  border: 'none',
  '& .MuiAlert-icon': {
    color: theme.palette.mode === 'dark' ? '#DCFB01' : '#793EEE',
  },
}));

const SkeletonItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.05)'
    : 'rgba(121, 62, 238, 0.05)',
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const IconPlaceholder = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '8px',
  background: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.1)'
    : 'rgba(121, 62, 238, 0.1)',
  flexShrink: 0,
}));

const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  const getNewsIcon = (type: string) => {
    switch (type) {
      case 'update':
        return <Update color="primary" />;
      case 'event':
        return <EmojiEvents color="primary" />;
      default:
        return <Article color="primary" />;
    }
  };

  const NewsPlaceholder = () => (
    <List>
      {[1, 2, 3].map((item) => (
        <SkeletonItem key={item}>
          <IconPlaceholder />
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                height: 24,
                width: '60%',
                borderRadius: 1,
                bgcolor: theme => theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(121, 62, 238, 0.1)',
                mb: 1,
              }}
            />
            <Box
              sx={{
                height: 16,
                width: '90%',
                borderRadius: 1,
                bgcolor: theme => theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.07)'
                  : 'rgba(121, 62, 238, 0.07)',
              }}
            />
          </Box>
        </SkeletonItem>
      ))}
    </List>
  );

  const EventsPlaceholder = () => (
    <List>
      {[1, 2].map((item) => (
        <SkeletonItem key={item}>
          <IconPlaceholder />
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                height: 20,
                width: '70%',
                borderRadius: 1,
                bgcolor: theme => theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(121, 62, 238, 0.1)',
                mb: 1,
              }}
            />
            <Box
              sx={{
                height: 16,
                width: '40%',
                borderRadius: 1,
                bgcolor: theme => theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.07)'
                  : 'rgba(121, 62, 238, 0.07)',
              }}
            />
          </Box>
        </SkeletonItem>
      ))}
    </List>
  );

  return (
    <Box>
      <Grid container spacing={3} sx={{ p: 3 }}>
        {/* Новости и обновления */}
        <Grid item xs={12} md={8}>
          <Typography variant="h5" sx={{ mb: 3, fontWeight: 600 }}>
            {t('dashboard.news')}
          </Typography>
          <NewsCard>
            <CardContent>
              <DevelopmentAlert 
                icon={<Construction />} 
                severity="info"
                sx={{ mb: 2 }}
              >
                {t('common.inDevelopment')}
              </DevelopmentAlert>
              <NewsPlaceholder />
            </CardContent>
          </NewsCard>
        </Grid>

        {/* Боковая панель */}
        <Grid item xs={12} md={4}>
          <Typography variant="h5" sx={{ mb: 3, fontWeight: 600 }}>
            {t('dashboard.upcomingEvents')}
          </Typography>
          <NewsCard>
            <CardContent>
              <DevelopmentAlert 
                icon={<Construction />} 
                severity="info"
                sx={{ mb: 2 }}
              >
                {t('common.inDevelopment')}
              </DevelopmentAlert>
              <EventsPlaceholder />
            </CardContent>
          </NewsCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard; 